import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import SearchBar from "app/components/inputs/SearchBar";
import Button from "app/components/Button/ButtonVariant";
import CheckBox from "app/components/inputs/CheckBox";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { searchAll } from "app/actions/searchAll";
import { RootState } from "typedefs";
import { SearchCard } from "./components/SearchCard/SearchCard";
import { SearchAllModel } from "app/models/SearchAllModel";
import { useTranslation } from "react-i18next";

const Search = () => {
  const { t } = useTranslation();
  const [searchReplies, setSearchReplies] = useState(false);
  const history = useHistory();
  const [search, setSearch] = useState(history.location.search.split("=")[1]);
  const [searchField, setSearchField] = useState(
    history.location.search.split("=")[1],
  );
  const dispatch = useDispatch();
  const data = useSelector(
    (state: RootState) => state.searchAll.successSearch,
    shallowEqual,
  );
  const isLoading = useSelector(
    (state: RootState) => state.searchAll.pendingSearch,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(searchAll(search, searchReplies));
  }, [search]);

  const onSearchClicked = useCallback(() => {
    if (search.length || searchField.length) {
      history.push(`/search?q=${searchField}`);
    }
  }, [search, searchField, searchReplies]);

  const onSearchEnterPressed = useCallback(
    (e) => {
      if (
        e.key === "Enter" &&
        (Boolean(search.length) || Boolean(searchField.length))
      ) {
        history.push(`/search?q=${searchField}`);
        setSearch(searchField);
      }
    },
    [history, search, searchField, searchReplies],
  );

  useEffect(() => {
    window.addEventListener("keydown", onSearchEnterPressed);
    return () => window.removeEventListener("keydown", onSearchEnterPressed);
  }, [onSearchEnterPressed]);

  useEffect(() => {
    setSearch(history.location.search.split("=")[1]);
    setSearchField(history.location.search.split("=")[1]);
  }, [history.location.search]);

  return (
    <>
      <div className={styles.search_container}>
        <h1>{t("Search")}</h1>
        <div className={styles.search_wrapper}>
          <SearchBar
            className={styles.search_input}
            value={searchField}
            onChange={(val) => setSearchField(val)}
          />
          <Button
            title={t("Search")}
            buttonType="primary"
            onClickFunc={onSearchClicked}
          />
        </div>
        <CheckBox
          label={t("Search replies")}
          className={styles.checkbox_input}
          checked={searchReplies}
          onChange={() => setSearchReplies(!searchReplies)}
        />

        <div className={styles.search_results_list}>
          {data &&
            !isLoading &&
            data.map((searchVal: SearchAllModel) => {
              return <SearchCard searchValue={searchVal} key={searchVal.id} />;
            })}
        </div>
      </div>
      {!data.length && !isLoading && (
        <h2 className={styles.status}>Nothing found</h2>
      )}
      {isLoading && <h2 className={styles.status}>Loading...</h2>}
    </>
  );
};

export { Search };
