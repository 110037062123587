import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type filterName = "coach" | "player" | "viewer";

interface Props {
  onChange: (isChecked: { [key in filterName]: boolean }) => void;
  memberFilterParams: { coach: boolean; player: boolean; viewer: boolean };
}

const MembersRoleFilter: React.FC<Props> = ({
  onChange,
  memberFilterParams,
}) => {
  const { t } = useTranslation();

  const filterData: {
    label: string;
    name: filterName;
    isActive: boolean;
  }[] = [
    {
      label: t("Coaches/Admins"),
      name: "coach",
      isActive: memberFilterParams.coach,
    },
    {
      label: t("Members"),
      name: "player",
      isActive: memberFilterParams.player,
    },
    {
      label: t("Viewers"),
      name: "viewer",
      isActive: memberFilterParams.viewer,
    },
  ];

  const onChangeHandler = (name: filterName) => {
    onChange({ ...memberFilterParams, [name]: !memberFilterParams[name] });
  };

  return (
    <div className={styles.dropdown_container}>
      <label>{t("Filter by Role")}</label>
      <div className={styles.checkbox_container}>
        {filterData?.map((data, index) => (
          <div key={index}>
            <div className={styles.checkbox_wrapper} key={index}>
              <input
                id={data.name}
                onChange={() => onChangeHandler(data.name)}
                type="checkbox"
                checked={data.isActive}
              />
              <label htmlFor={data.name}>{data.label}</label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MembersRoleFilter;
