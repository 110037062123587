/**
 * @module Model.Event
 *
 */
import { Model } from "typedefs";
import { StudentProfileModel } from "./scheduling/StudentProfileModel";
import { LessonTypeModel } from "./scheduling/LessonTypeModel";
import { Interval, DateTime } from "luxon";
import I18n from "app/i18n";

export enum EventType {
  LESSON_SCHEDULE = "lesson_schedule",
  COACH_SCHEDULE_BLOCK = "coach_schedule_block",
  PROGRAM = "program",
}

export enum RepeatType {
  DAILY = "daily",
  WEEKLY = "weekly",
  BIWEEKLY = "biweekly",
  MONTHLY = "monthly",
}

export type RepeatOption = {
  label: string;
  value: RepeatType | null;
};

export const REPEAT_OPTIONS: RepeatOption[] = [
  { label: I18n.t("Does not repeat"), value: null },
  { label: I18n.t("Daily"), value: RepeatType.DAILY },
  { label: I18n.t("Weekly"), value: RepeatType.WEEKLY },
  { label: I18n.t("Bi-Weekly"), value: RepeatType.BIWEEKLY },
  { label: I18n.t("Monthly"), value: RepeatType.MONTHLY },
];

export interface EventResponse extends Model {
  id: number;
  start: string;
  end: string;
  backgroundColor: string;
  type: EventType;
  studentProfiles?: any[];
  coachProfile?: any;
  timeZone: string;
  lessonRateId?: number;
  repeat: string | null;
  untilDate: string | null;
  ntes: string;
  private: boolean;
  facilityId: string;
}

export class EventModel implements Model {
  id: number;
  start: string;
  end: string;
  date: Date;
  backgroundColor: string;
  timeZone: string;
  interval: Interval;
  title: string;
  subTitle: string;
  type: EventType;
  location: string;
  notes: string;
  repeat: string | null;
  repeatLabel: string | null;
  untilDate: Date;
  recurrenceGroupId: number | null;
  durationInMinutes: number;

  coachProfile?: any;
  studentProfiles?: StudentProfileModel[];
  studentProfileIds?: number[];
  lessonTypesIds?: number[];
  lessonRateId?: number;
  rate?: number;
  private?: boolean;

  startTimeString: string;
  endTimeString: string;
  intervalTimeString: string;
  dateString: string;
  timeZoneShortString: string;
  color: string;
  externalCalendarId: string;

  spotsBooked?: number;
  capacity?: number;
  registrationStatusCode?: string;
  registrationStatus?: string;

  constructor(event: any) {
    this.id = event.id;
    this.start = event.start;
    this.end = event.end;
    this.backgroundColor = event.backgroundColor;
    this.timeZone = event.timeZone;
    this.title = event.title;
    this.untilDate = new Date(event.untilDate);
    this.recurrenceGroupId = event.recurrenceGroupId;

    this.interval = Interval.fromDateTimes(
      DateTime.fromISO(this.start).setZone(this.timeZone),
      DateTime.fromISO(this.end).setZone(this.timeZone)
    );
    this.durationInMinutes = this.interval.toDuration("minutes").minutes;

    this.date = this.interval.start.toJSDate();

    this.startTimeString = this.interval.start.toLocaleString(
      DateTime.TIME_SIMPLE
    );
    this.endTimeString = this.interval.end.toLocaleString(DateTime.TIME_SIMPLE);
    this.timeZoneShortString = this.interval.start.toFormat("ZZZZ");
    this.dateString = this.interval.start.toLocaleString(DateTime.DATE_FULL);

    this.intervalTimeString = `${this.startTimeString} - ${this.endTimeString} ${this.timeZoneShortString}`;
    this.notes = event.notes;
    this.externalCalendarId = event.externalCalendarId;

    this.repeat = event.repeat;
    this.repeatLabel = null;
    if (this.repeat) {
      this.repeatLabel = REPEAT_OPTIONS.find(
        (ropt) => ropt.value === this.repeat
      )?.label;
    }
  }

  public static apiPayload(data: EventModel, operation: string) {
    let payload: any = {
      start: data.start,
      end: data.end,
      notes: data.notes,
      type: data.type,
    };

    if (data.type === "lesson_schedule") {
      payload = {
        ...payload,
        studentProfileIds: data.studentProfileIds,
        lessonTypesIds: data.lessonTypesIds,
        lessonRateId: data.lessonRateId,
        location: data.location,
        rate: data.rate,
      };
    } else {
      payload = {
        ...payload,
        title: data.title,
        private: data.private,
      };
    }

    if (operation === "create") {
      if (data.repeat) {
        const untilDate = DateTime.fromJSDate(data.untilDate).toISODate();
        payload = {
          ...payload,
          repeat: data.repeat,
          untilDate,
        };
      }
    } else {
      payload = {
        ...payload,
        id: data.id,
        recurrenceGroupId: data.recurrenceGroupId,
      };
    }

    return payload;
  }

  public static buildInstance(event: EventResponse) {
    switch (event.type) {
      case "lesson_schedule":
        return new LessonSchedule(event);
      case "coach_schedule_block":
        return new CoachScheduleBlock(event);
      case "program": {
        return new Program(event);
      }
      default:
        throw new Error("Invalid event type");
    }
  }
}

export class LessonSchedule extends EventModel {
  studentProfiles: StudentProfileModel[];
  coachProfile: any;
  lessonTypes: LessonTypeModel[];
  lessonTypesIds: number[];
  lessonRateId: number;
  location: string;
  rate: number;
  facilityId: string;

  constructor(event: any) {
    super(event);
    this.lessonRateId = event.lessonRateId;

    this.studentProfiles = event.studentProfiles.map(
      (studentProfile: any): StudentProfileModel => {
        return new StudentProfileModel(studentProfile);
      }
    );

    this.lessonTypes = event.lessonTypes;
    this.lessonTypesIds = event.lessonTypesIds;
    this.coachProfile = event.coachProfile;

    if (this.studentProfiles.length === 1) {
      this.title = this.studentProfiles[0].fullName;
    } else {
      this.title = `${this.studentProfiles.length} students`;
    }

    if (this.lessonTypes && this.lessonTypes.length > 1) {
      this.subTitle = this.lessonTypes
        .map((lessonType) => lessonType.name)
        .join(", ");
    } else {
      this.subTitle = "";
    }

    this.location = event.location;
    this.rate = event.rate;
    this.type = EventType.LESSON_SCHEDULE;
    this.backgroundColor = "#fff";
    this.facilityId = event.facilityId;
  }

  public get lessonTypesNames() {
    return this.lessonTypes.map((lessonType) => lessonType.name).join(", ");
  }
}

export class CoachScheduleBlock extends EventModel {
  private: boolean;
  constructor(event: any) {
    super(event);

    this.subTitle = "";
    this.type = EventType.COACH_SCHEDULE_BLOCK;
    this.backgroundColor = "#f4f4f4";
    this.title = event.title || "Unavailable";
    this.private = event.private;
  }
}

export class Program extends EventModel {
  private: boolean;
  constructor(event: any) {
    super(event);
    this.type = EventType.PROGRAM;
    this.backgroundColor = "#fff";
    this.title = event.title;
    this.subTitle = event.subtitle;
    this.spotsBooked = event.spotsBooked;
    this.capacity = event.capacity;
    this.registrationStatusCode = event.registrationStatusCode;
    this.private = event.private;
    this.registrationStatus = event.registrationStatus;
  }
}
