/** @module actions */
import { reject } from "rsvp";
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { fetchGet, fetchPost, request } from "app/utils/request";
import { InvitationModel } from "app/models";
import config from "config/environment";
import { RequestError } from "typedefs";

const pending = () => action("@invite.fetch.pending");
const error = (err: string) => action("@invite.fetch.error", err);
const success = (data: InvitationModel) =>
  action("@invite.fetch.success", data);

const outgoingInvitations = (data: number) =>
  action("@invite.fetch.outgoing", data);

const pendingJoin = () => action("@invite.join.pending");
const errorJoin = (err: string) => action("@invite.join.error", err);
const successJoin = () => action("@invite.join.success");

export const clearInvitationState = () => action("@invite.clear");

const thisActions = {
  pending,
  error,
  success,
  outgoingInvitations,
  pendingJoin,
  errorJoin,
  successJoin,
  clearInvitationState,
};

export type InvitationAction = ActionType<typeof thisActions>;

export function fetchInvitations(
  params?: { [key: string]: any },
  signal?: AbortSignal,
) {
  return () => {
    return fetchGet<InvitationModel>("invitations", params || {}, {}, signal)
      .then((res: InvitationModel[]) => {
        return res;
      })
      .catch((err) => {
        return reject(err);
      });
  };
}

export const fetchInvitationById = (id: string) => {
  return (dispatch: Dispatch<InvitationAction>) => {
    dispatch(pending());
    return fetchGet(`/invitations/${id}`)
      .then((data) => {
        dispatch(success(data));
        return data;
      })
      .catch((err) => {
        dispatch(error(err));
        return reject(err);
      });
  };
};

export function fetchOutgoingInvitationsCount(
  params?: { [key: string]: any },
  signal?: AbortSignal,
) {
  return (dispatch: Dispatch<InvitationAction>) => {
    return fetchGet<number>("invitations/count", params || {}, {}, signal)
      .then((res: number) => {
        dispatch(outgoingInvitations(res));
        return res;
      })
      .catch((err) => {
        return reject(err);
      });
  };
}

export const acceptInvitation = (id: string) => {
  return (dispatch: Dispatch<InvitationAction>) => {
    dispatch(pendingJoin());
    return fetchPost(`/invitations/${id}/accept`)
      .then(() => {
        dispatch(successJoin());
      })
      .catch((err) => {
        dispatch(errorJoin(err));
        return reject(err);
      });
  };
};

export function editInvitation(invitation?: { [key: string]: any }) {
  return (dispatch: Dispatch<InvitationAction>) => {
    dispatch(pendingJoin());
    const url = config.API_SERVER + `/api/v1/invitations/${invitation.id}`;
    const promise = request(url, "PUT", { invitation }, { version: 1 });
    promise.catch((err) => console.error(err));
    return promise;
  };
}

export function resendInvitation(invitation?: { [key: string]: any }) {
  return (dispatch: Dispatch<InvitationAction>) => {
    dispatch(pendingJoin());
    const url =
      config.API_SERVER + `/api/v2/invitations/${invitation.id}/resend`;
    const promise = request(url, "POST", invitation, { version: 1 });
    promise.catch((err: RequestError) => console.error(err));
    return promise;
  };
}

export function declineInvitation(inviteId: string) {
  return (dispatch: Dispatch<InvitationAction>) => {
    dispatch(pending());

    const url = config.API_SERVER + `/api/v2/invitations/${inviteId}/decline`;
    const promise = request(url, "POST", {}, { version: 1 });

    promise.catch((err: RequestError) => console.error(err));

    return promise;
  };
}

export function cancelSpaceInvitation(spaceId: string, inviteId: string) {
  return (dispatch: Dispatch<InvitationAction>) => {
    dispatch(pending());

    const url =
      config.API_SERVER + `/api/v2/spaces/${spaceId}/invitations/${inviteId}`;
    const promise = request(url, "DELETE", {}, { version: 1 });

    promise.catch((err: RequestError) => console.error(err));

    return promise;
  };
}

export function cancelTeamInvitation(teamId: string, inviteId: string) {
  return (dispatch: Dispatch<InvitationAction>) => {
    dispatch(pending());

    const url =
      config.API_SERVER + `/api/v2/teams/${teamId}/invitations/${inviteId}`;
    const promise = request(url, "DELETE", {}, { version: 1 });

    promise.catch((err: RequestError) => console.error(err));

    return promise;
  };
}

export const actions = {
  fetchInvitations,
  fetchInvitationById,
  acceptInvitation,
  editInvitation,
};
