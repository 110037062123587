/* eslint-disable no-undef */
/**
 * @module Components.Filter
 *
 */
import React, { useState } from "react";

import styles from "./styles.module.scss";
import { cls } from "app/utils";
import SpacesSelect from "./SpacesSelect";
import GroupsSelect from "./GroupsSelect";
import ListsSelect from "./ListsSelect";
import Search from "./Search";
import moment from "moment";
import { useTranslation } from "react-i18next";
import i18n from "app/i18n";

type PropsDef = {
  selectedCalendarDate: string;
  onSelectedCalendarDateRemove: () => void;
  onSearchSubmit: (value: string) => void;
  onReset: (value: any) => void;
  onSubmit: (value: any, objectTypes?: any) => void;
};

/**
 * @class ScheduleFilter
 *
 */
const ScheduleFilter = (props: PropsDef) => {
  const { t } = useTranslation();
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [clearSearchInput, setClearSearchInput] = useState(false);

  const onReset = (data: any) => {
    setSelectedTargets([]);
    setClearSearchInput(true);

    props.onReset(data);
  };

  const onSelectedCalendarDateRemove = () => {
    props.onSelectedCalendarDateRemove();
  };

  const onSearchSubmit = (value: string) => {
    setClearSearchInput(false);

    props.onSearchSubmit(value);
  };

  const onSelectedTargetRemove = (target: any) => {
    const remainedTargets = selectedTargets.filter(
      (_target: any) => _target.id !== target.id,
    );

    onSubmit(remainedTargets, null);
  };

  const onSubmit = (data: any, objectTypes?: any) => {
    // Refersh the list of targets for given objectTypes
    if (objectTypes) {
      // Remove all objectTypes items from already selected targets
      const restTargets = selectedTargets.filter(
        (_target: any) => !objectTypes.includes(_target.object),
      );
      // Add new list of targets to selected targets
      data = [...new Set([...restTargets, ...data])];
      // Update selectedTargets state
      setSelectedTargets(data);
    }

    const scheduleTargetIds = Array.from(data, (_target: any) => _target.id);

    setSelectedTargets(data);
    props.onSubmit({ scheduleTargetIds });
  };

  const formatSelecteDate = () => {
    if (!props.selectedCalendarDate) {
      // @ts-ignore
      return;
    }

    return moment
      .utc(props.selectedCalendarDate)
      .locale(i18n.language)
      .format("MMM DD, YYYY");
  };

  // @ts-ignore
  const showTargetAvatar = (target: any) => {
    if (!target) {
      // @ts-ignore
      return;
    }

    switch (target.object) {
      case "athlete_space":
        return <img src={target.avatar} className={styles.spaceAvatar} />;
      case "team":
        return (
          <span className={styles.iconWrapper}>
            <i className={cls("ico", "ico-team", styles.icon)}></i>
          </span>
        );
      case "team_group":
        return (
          <span className={styles.iconWrapper}>
            <i className={cls("ico", "ico-team", styles.icon)}></i>
          </span>
        );
      case "segment":
        return (
          <span className={styles.iconWrapper}>
            <i className={cls("ico", "ico-groups", styles.icon)}></i>
          </span>
        );
    }
  };

  // @ts-ignore
  const showTargetName = (target: any) => {
    if (!target) {
      // @ts-ignore
      return;
    }

    switch (target.object) {
      case "athlete_space":
        return <span>{target.athleteName}</span>;
      case "team":
        return (
          <span>
            {target.name} {t("General")}
          </span>
        );
      case "team_group":
        return (
          <span>
            {target.team.name} ({target.name})
          </span>
        );
      case "segment":
        return <span>{target.name}</span>;
    }
  };

  return (
    <div>
      <div className={styles.filterOptionsContainer}>
        <div className={styles.filter_buttons_wrapper}>
          <button className={cls(styles.filterButton)} onClick={onReset}>
            <i className={cls("ico", "ico-time", styles.icon)}></i>
            <span className={styles.buttonCaption}>{t("All")}</span>
          </button>

          <SpacesSelect selectedSpaces={selectedTargets} onSubmit={onSubmit} />
          <GroupsSelect selectedTeams={selectedTargets} onSubmit={onSubmit} />
          <ListsSelect selectedLists={selectedTargets} onSubmit={onSubmit} />
        </div>
        <Search
          clearSearchInput={clearSearchInput}
          onSearchSubmit={onSearchSubmit}
        />
      </div>

      <div className={styles.filterSelectedContainer}>
        <div
          className={
            props.selectedCalendarDate
              ? styles.filterSelectedItem
              : styles.hidden
          }
        >
          <span className={styles.iconTimeWrapper}>
            <i className={cls("ico", "ico-time", styles.icon)}></i>
          </span>
          <span className={cls(styles.timeCaption)}>{formatSelecteDate()}</span>
          <i
            className={cls("ico", "ico-x", styles.iconClose)}
            onClick={onSelectedCalendarDateRemove}
          ></i>
        </div>

        {selectedTargets.map((target) => (
          <div className={styles.filterSelectedItem} key={target.id}>
            {showTargetAvatar(target)}

            <span className={cls(styles.caption)}>
              {showTargetName(target)}
            </span>

            <i
              className={cls("ico", "ico-x", styles.iconClose)}
              onClick={() => onSelectedTargetRemove(target)}
            ></i>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduleFilter;
