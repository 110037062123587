import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import Label from "../../../components/Programs/Label";
import ToolTip from "../../../components/Programs/Tooltip";
import SaveBar from "../../Sessions/SaveBar";
import PhotoContainer from "../../../components/Programs/ImageContainers/PhotoContainer";
import UserItem from "../../../components/Programs/UserItem";
import TagsPicker from "../../../components/Programs/TagsPicker";
import {
  setDetailsSettings,
  setPage,
} from "../../../actions/scheduling/program";
import { ProgramState } from "../../../reducers/scheduling/program";
import { RootState } from "typedefs";
import { CoachProfileModel } from "../../../models/scheduling/CoachProfileModel";
import UserSelector from "../../../components/Programs/UserSelector";
import { schedulingFetchGet } from "../../../utils/request/scheduling/fetch";
import useProgramData from "../../Sessions/hooks/useProgramData";
import { useTranslation } from "react-i18next";
import DefaultImage from "app/components/Programs/DefaultImage";

const DetailsOptions = ({ setHeader, setThumbanil }) => {
  const { t } = useTranslation();
  const programState = useSelector(
    (state: RootState): ProgramState => state.program
  );

  const [tags, setTags] = useState(null);
  const [selectedTags, setSelectedTags] = useState(programState.tags);
  const [coaches, setCoaches] = useState<CoachProfileModel[]>([]);
  const [showDefaultImages, setShowDefaultImages] = useState(false);
  const [needsCrop, setNeedsCrop] = useState(false);
  const [_cropped, setCropped] = useState(true);
  const [newThumbanil, setNewThumbnail] = useState(null);
  const [newThumbanilURL, setNewThumbnailURL] = useState(null);

  const [shortDescription, setShortDescription] = useState(
    programState.shortDescription
  );

  const [charactersCount, setCharactersCount] = useState(
    shortDescription.length
  );
  const [thumbnailURL, setThumbnailURL] = useState(programState.thumbnailURL);

  const [headerURL, setHeaderURL] = useState(programState.headerURL);
  const [longDescription, setLongDescription] = useState(
    programState.longDescription
  );
  const [formErrors, setFormErrors] = useState({
    shortDescription: false,
    coaches: false,
  });
  const methods = useForm();
  const dispatch = useDispatch();

  const handleChange = (value) => {
    if (value.length <= 100) {
      setCharactersCount(value.length);
      setShortDescription(value);
    }
  };

  const handleURL = (image, type) => {
    if (type === "header") {
      setHeader(image);
      setHeaderURL(image ? URL.createObjectURL(image) : null);
    } else {
      setCropped(false);
      setNeedsCrop(true);
      setThumbanil(image);
      setThumbnailURL(image ? URL.createObjectURL(image) : null);
    }
  };

  const checkErrors = useCallback(() => {
    let hasErrors = false;
    const newErrors = { shortDescription: false, coaches: false };
    if (shortDescription === "") {
      newErrors.shortDescription = true;
      hasErrors = true;
    }
    if (isEmpty(coaches)) {
      newErrors.coaches = true;
      hasErrors = true;
    }

    setFormErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

    return hasErrors;
  }, [shortDescription, coaches]);

  const onSubmit = () => {
    const externalErrors = checkErrors();
    if (externalErrors) {
      return;
    }
    const detailSettings = {
      shortDescription,
      longDescription,
      coachIds: coaches.map((item) => item.id),
      coaches,
      tags: selectedTags,
      thumbnailURL,
      headerURL,
      page: 3,
    };
    dispatch(setDetailsSettings(detailSettings));
  };

  const fetcher = useCallback(() => {
    const coachPromise = schedulingFetchGet(
      `/facilities/${programState?.facilityId}/coach_profiles`
    );

    return Promise.all([coachPromise]);
  }, []);

  const [loading, coachData] = useProgramData(fetcher);

  useEffect(() => {
    if (!loading) {
      const selectedCoaches = coachData.coaches[
        `${programState?.facilityId}`
      ].filter((item) => programState.coachIds.includes(item.id));

      setCoaches(selectedCoaches);
    }
  }, [loading]);

  const handleCancel = () => {
    dispatch(setPage(1));
  };

  const handleSelectDefaultImages = (needsCrop) => {
    setShowDefaultImages(true);
    setNeedsCrop(needsCrop);
  };
  const onClose = (coach) => {
    setCoaches((old) => old.filter((item) => item.id !== coach.id));
  };

  const handleTagsChange = (value) => {
    setSelectedTags(value);
  };

  const onCropComplete = async (blobURL) => {
    if (needsCrop) {
      setThumbnailURL(blobURL);
      const response = await fetch(blobURL);
      const blob = await response.blob();
      const file = new File([blob], "uploaded_image.png", {
           type: "image/png",
         });
      setThumbanil(file);
    } else {
      setHeader(null);
      setHeaderURL(blobURL);
    }
    setCropped(true);
    setShowDefaultImages(false);
  };

  const handleModalClose = () => {
    setNewThumbnail(null);
    setNewThumbnailURL(null);
    setShowDefaultImages(false);
  };

  const UserItems = ({ userList }) => {
    return userList.map((item, index) => (
      <div key={item.id}>
        <UserItem
          key={item.id}
          showEmail
          {...item}
          closeStyle={styles.close_style}
          height={30}
          width={30}
          displayClose
          itemStyle={styles.item_style}
          onClose={() => onClose(item)}
        />
        {index + 1 < userList.length && (
          <div className={styles.line_separator} />
        )}
      </div>
    ));
  };

  return (
    <div
      className={`${styles.container} ${styles.coach_min_height} calendar-page-container`}
    >
      <FormProvider {...methods}>
        <form id="programForm">
          <div className={styles.details_container}>
            <div className={`${styles.column} ${styles.details_section}`}>
              <label className={`${styles.gray_text}`}>
                {t("Description & Photos")}
              </label>
              <div className={styles.horizontal_line} />
              <div className={styles.details_word_count}>
                <Label
                  text={t("Short Description")}
                  required={true}
                  error={
                    formErrors.shortDescription && t("This field is required")
                  }
                  item={(
                    <input
                      data-testid="short-description"
                      placeholder={t("Enter a short description")}
                      value={shortDescription}
                      className={styles.details_input}
                      onChange={(event) => handleChange(event.target.value)}
                    />
                  )}
                />
                <p className={styles.gray_text}>{charactersCount}/100</p>
              </div>
              <div className={styles.textarea_container}>
                <div className={styles.textarea_width}>
                  <Label
                    text={t("Long Description")}
                    item={(
                      <textarea
                        data-testid="long-description"
                        value={longDescription}
                        onChange={(event) =>
                          setLongDescription(event.target.value)
                        }
                        className={styles.details_textarea}
                        placeholder={t("Enter a long description")}
                      />
                    )}
                  />
                </div>
                <Label
                  text={t("Thumbnail Photo")}
                  tooltip={(
                    <ToolTip
                      text={t(
                        "Thumbnail should be 144x144 px for best quality."
                      )}
                    />
                  )}
                  item={(
                    <PhotoContainer
                      imageLink={thumbnailURL}
                      type="thumbnail"
                      allowsDefault
                      onSelectClick={() => handleSelectDefaultImages(true)}
                      handleCrop={() => setShowDefaultImages(true)}
                      customPlaceholderStyle={styles.height_280}
                      containerStyle={styles.details_photo_container}
                      setUrl={(image) => handleURL(image, "thumbnail")}
                      uploadedImageContainer={styles.uploaded_image_container}
                    />
                  )}
                  containerStyle={styles.small_label}
                />
              </div>
              <Label
                text={t("Header Photo")}
                tooltip={(
                  <ToolTip
                    text={t(
                      "Banner should be at least 1920 px wide and at most 300 px tall for best quality."
                    )}
                  />
                )}
                item={(
                  <PhotoContainer
                    imageLink={headerURL}
                    type="header"
                    allowsDefault
                    onSelectClick={() => handleSelectDefaultImages(false)}
                    customPlaceholderStyle={styles.details_header_photo}
                    containerStyle={styles.banner_container}
                    setUrl={(image) => handleURL(image, "header")}
                    uploadedImageContainer={styles.uploaded_image_container}
                  />
                )}
                containerStyle={styles.details_photo_container}
              />
            </div>
            <div
              className={
                headerURL ? styles.details_uploaded_line : styles.details_line
              }
            />
            <div className={`${styles.details_column}`}>
              <label className={styles.gray_text}>{t("Coaches & Tags")}</label>
              <div className={styles.horizontal_line} />
              <Label
                text={t("Coaches")}
                required={true}
                error={formErrors.coaches && t("This field is required")}
                item={(
                  <UserSelector
                    placeholder={t("Select Coach")}
                    values={coachData.coaches[`${programState?.facilityId}`]}
                    selectedUsers={coaches}
                    setValues={setCoaches}
                  />
                )}
              />
              <UserItems userList={coaches} />
              <Label
                text={t("Tags")}
                item={(
                  <TagsPicker
                    selectedTags={selectedTags}
                    placeholder={t("Choose or type a new one")}
                    tags={tags}
                    handleChange={handleTagsChange}
                    setTags={setTags}
                  />
                )}
              />
            </div>
          </div>
        </form>
      </FormProvider>
      {showDefaultImages && (
        <DefaultImage
          uploadedImageURL={needsCrop ? newThumbanilURL : null}
          onConfirm={onCropComplete}
          needsCrop={needsCrop}
          showModal={handleModalClose}
          uploadedImage={needsCrop ? newThumbanil : null}
        />
      )}
      <SaveBar
        showCancel
        cancelLabel={t("Back")}
        onCancel={handleCancel}
        onSubmit={methods.handleSubmit(onSubmit)}
      />
    </div>
  );
};

export default DetailsOptions;
