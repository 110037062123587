import {
  createSpace,
  getCreatedSpace,
  createSpaceStep,
  clearAppliedTemplates,
  uploadProgress,
} from "app/actions/spaceCreation";
import { useActions } from "app/utils/hooks";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "typedefs";

export const useCreateNewSpaceModel = () => {
  const history = useHistory();
  const location = useLocation();
  const [pending, setPending] = useState(false);

  const route = (path: string) => {
    history.push(path);
  };

  const createSpaceActions = useActions({
    createSpace,
    getCreatedSpace,
    clearAppliedTemplates,
  });
  const dispatch = useDispatch();
  const { step, spaces_import, error, data } = useSelector(
    (state: RootState) => state.createSpace,
    shallowEqual,
  );
  const stepForward = () => {
    if (step !== 3) {
      dispatch(createSpaceStep(step + 1));
    }
  };

  const stepBackward = () => {
    if (step !== 1) {
      dispatch(createSpaceStep(step - 1));
    }
  };

  const cancelHandler = () => {
    createSpaceActions.clearAppliedTemplates();
    route("/spaces");
  };

  const onCreateSpace = () => {
    const extractUserProps = (users: any) => {
      if (!users || !Array.isArray(users)) {
        return [];
      }
      return users.map(({ email, name, phone, phone_country, id }: any) => ({
        email: email !== null ? email : null,
        name: name !== null ? name : null,
        phone: phone !== null ? phone : null,
        phone_country: phone_country !== null ? phone_country : null,
        user_id: id !== null ? id : null,
      }));
    };

    const newBaseSpaceApplication = {
      athletes: extractUserProps(spaces_import.athletes),
      coaches: extractUserProps(spaces_import.coaches),
      contributors: extractUserProps(spaces_import.contributors),
      create_for_self: spaces_import.create_for_self,
      segment_ids:
        spaces_import.segment_ids !== undefined
          ? spaces_import.segment_ids
          : [],
      space_template_id: spaces_import?.space_template_id.id,
      sport: spaces_import?.sport !== undefined ? spaces_import?.sport : "",
      sport_other:
        spaces_import?.sport_other !== undefined
          ? spaces_import?.sport_other
          : "",
    };

    const isMultiple = location?.pathname?.includes("new/multiple");

    setPending(true);
    if (isMultiple) {
      createSpaceActions
        .createSpace({
          spaces_import: newBaseSpaceApplication,
        } as any)
        .then((res) => {
          const refreshProgress = async () => {
            try {
              console.log(res);
              if (res && (res?.spaceImportId || res?.spaceId)) {
                if (res?.spaceId) {
                  route(`/spaces/${res.spaceId}`);
                }
                const spaceData = await createSpaceActions.getCreatedSpace(
                  res?.spaceImportId || res?.spaceId,
                );
                if (spaceData.status && spaceData.status.progress) {
                  dispatch(uploadProgress(spaceData.status.progress));
                }
                if (parseInt(spaceData.status.progress, 10) === 100) {
                  createSpaceActions.clearAppliedTemplates();
                  route("/spaces");
                } else {
                  setTimeout(refreshProgress, 1000);
                }
              }
            } catch (error) {
              console.error("Error polling space status:", error);
            }
          };

          refreshProgress();
        });
    } else {
      createSpaceActions
        .createSpace({
          spaces_import: newBaseSpaceApplication,
        } as any)
        .then((res) => {
          // createSpaceActions.clearAppliedTemplates();
          route(`/spaces/${res.spaceId}`);
        })
        .catch((err) => {
          console.error("Error creating space:", err);
        })
        .finally(() => {
          createSpaceActions.clearAppliedTemplates();
          setPending(false);
        });
    }
  };

  return {
    stepForward,
    stepBackward,
    onCreateSpace,
    cancelHandler,
    data,
    pending,
    error,
  };
};
