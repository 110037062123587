import React, { memo } from "react";
import styles from "./styles.module.scss";
import { NotificationsModel } from "app/models";
import { cls } from "app/utils";
import { useNotificationsListModel } from "./indexModel";
import { Loading } from "app/components/Wrappers";
import SingleNotification from "./SingleNotification";
import { getInvitationGroupTitle } from "../helpers/getInvitationGroupTitle";
import { useTranslation } from "react-i18next";

interface Props {
  containerClassName?: string;
  notificationsContainer: any;
  data: any;
  loading: boolean;
}

const NotificationsList = ({
  containerClassName,
  notificationsContainer,
  data,
  loading,
}: Props) => {
  const { t } = useTranslation();
  const { onNotificationClick } = useNotificationsListModel();

  if (data?.length === 0 && !loading) {
    return (
      <div className={styles.empty_container}>
        <p className={styles.empty_text}>{t("No Notifications")}</p>
      </div>
    );
  }

  return (
    <div className={cls(styles.content_container, containerClassName)}>
      {data &&
        Object.entries(data).map(
          ([key, notifications]: any) =>
            notifications.length > 0 && (
              <div className={styles.notifications_list} key={key}>
                <label className={styles.group_label}>
                  {getInvitationGroupTitle(key, t)}
                </label>
                {notifications.map((notification: NotificationsModel) => {
                  return (
                    <div key={notification.id}>
                      <SingleNotification
                        notification={notification as any}
                        onNotificationClick={onNotificationClick}
                      />
                    </div>
                  );
                })}
              </div>
            ),
        )}
      {loading && (
        <div className={styles.loading_container}>
          <Loading loadType="spinner" isLoading={loading} />
        </div>
      )}
      <div className={styles.container_ref} ref={notificationsContainer} />
    </div>
  );
};

export default memo(NotificationsList);
