import successHandler from "./successHandler";
import errorHandler from "./errorHandler";

export interface ResponseType {
  status: number;
  statusText: string;
  success: boolean;
  xhr: Response;
  data?: any;
  errors?: string[];
  originalErrors?: string[];
}

/**
 * handles api request responses
 *
 * @param xhr the response object from the fetch API
 * @returns a promise resolving to a ResponseType object
 */
export default async function responseHandler(
  xhr: Response,
): Promise<ResponseType> {
  const handleResp = (resp: any): ResponseType => buildResponse(xhr, resp);

  try {
    if (xhr.status == null && xhr instanceof Error) {
      if (xhr.name === "AbortError") {
        // custom handling for abort errors
        return Promise.reject({
          status: 499, // use a custom code or 499 (common for client-side abort)
          statusText: "Request Canceled",
          success: false,
          errors: ["Request was aborted by the user"],
          originalErrors: ["signal is aborted without reason"],
          xhr,
        });
      }
      // handle other network errors
      return Promise.reject(
        handleResp({
          ok: false,
          status: 599,
          statusText: "Network connection timeout error",
          error: (xhr as any as Error).message,
        }),
      );
    } else if (xhr.status === 204 && xhr.statusText === "No Content") {
      // no content response
      return handleResp(null);
    } else if (xhr.status === 201 && xhr.statusText === "Created") {
      // sometimes has no content?
      try {
        const response = await xhr.json();
        return handleResp(response);
      } catch (e) {
        if (e instanceof SyntaxError) {
          // ignore the error and return success with empty content
          return handleResp(null);
        } else {
          return Promise.reject(e);
        }
      }
    }

    const contentType = xhr.headers.get("Content-Type");
    if (contentType && contentType.includes("application/json")) {
      const response = await xhr.json();
      return handleResp(response);
    } else if (contentType && contentType.includes("image/")) {
      const response = await xhr.blob();
      return handleResp(response);
    } else {
      const response = await xhr.text();
      return handleResp(response);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

function buildResponse(xhr: Response, response: any): ResponseType {
  const { ok, status, statusText } = xhr;
  let result = {};
  if (response != null) {
    result = ok ? successHandler(response) : errorHandler(response);
  }
  return { xhr, status, statusText, success: ok, ...result };
}
