/**
 * @module Components.Routes
 *
 */
import React, { useState, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // for selectable
import luxonPlugin from "@fullcalendar/luxon3";
import { capitalize } from "lodash";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useActions } from "app/utils/hooks";

import useInterval from "app/utils/hooks/useInterval";
import { Loading } from "app/components/Wrappers";
import { useEventsModel, useLessonTypesModel } from "./indexModel";
import useAvailablity from "./hooks/useAvailability";
import useConfirmation from "./hooks/useConfirmation";

import {
  setSelectedEvent,
  deleteEvent,
  initializeNewEvent,
  createEvent,
  editEvent,
  startEdit,
  deselectEvent,
  hideInfoPopup,
  fetchEvents,
} from "app/actions/events";
import { EventModel, EventType } from "app/models/EventModel";

import Event from "./Event";
import EventInfo from "./EventInfo";
import DayHeader from "./DayHeader";
import CalendarToolbar from "./CalendarToolbar";
import EventForm from "./EventForm";
import InfoModal from "./InfoModal";
import ConfirmationPrompt from "./ConfirmationPrompt";

import styles from "./styles.module.scss";
import DayHeaderMobile from "./DayHeaderMobile";

const mediaWatcher = window.matchMedia("(max-width: 820px)");

export default function Calendar() {
  const { t } = useTranslation();
  const calendarRef = useRef(null);
  const eventElRef = useRef(null);
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.user.self.id);
  const history = useHistory();

  const calendarView = mediaWatcher.matches ? "timeGridNarrow" : "timeGridWeek";
  const [calendarInterval, setCalendarInterval] = useState<{
    start: DateTime;
    end: DateTime;
  }>({ start: null, end: null });
  const [currentDay, setCurrentDay] = useState<DateTime>(null);
  const isoStartDate = useMemo(
    () => calendarInterval.start?.toISODate(),
    [calendarInterval.start?.toMillis()]
  );
  const isoEndDate = useMemo(
    () => calendarInterval.end?.toISODate(),
    [calendarInterval.end?.toMillis()]
  );
  const eventActions = useActions({
    fetchEvents,
  });

  const { lessonTypes } = useLessonTypesModel();

  const dateCalendar = currentDay || DateTime.now();

  const { events, coachProfile } = useEventsModel(
    userId,
    calendarInterval.start,
    calendarInterval.end
  );


  const { availability } = useAvailablity(
    coachProfile.data,
    calendarInterval.start,
    calendarInterval.end
  );

  const { PromptComponent, requestConfirmation } = useConfirmation(
    (props: any) => <ConfirmationPrompt {...props} />
  );

  const updateEvents = useCallback(() => {
    eventActions.fetchEvents({ start: isoStartDate, end: isoEndDate });
  }, [isoStartDate, isoEndDate]);

  useInterval(updateEvents, 60 * 1000);

  // @ts-ignore: next-line
  const selectedEvent: EventModel = useSelector(
    (state: RootState) => state.events.selected
  );
  const isEditingEvent: boolean = useSelector(
    (state: RootState) => state.events.editSelected
  );
  const newEvent = useSelector((state: RootState) => state.events.newEvent);
  const lessonTypeOptions = useSelector(
    (state: RootState) => state.lessonTypes.data
  );
  const showInfoPopup = useSelector(
    (state: RootState) => state.events.showInfoPopup
  );
  const infoPopupContent = useSelector(
    (state: RootState) => state.events.infoPopupContent
  );

  if (lessonTypes.pending) {
    return (
      <Loading loadType="spinner" isLoading className={styles.loadingSpinner} />
    );
  }

  if (coachProfile.pending) {
    return (
      <Loading loadType="spinner" isLoading className={styles.loadingSpinner} />
    );
  }

  const eventForForm = selectedEvent || newEvent;
  const showEventForm = (selectedEvent && isEditingEvent) || newEvent;
  const { timeZone } = coachProfile.data;

  const onNextCalendar = () => {
    const calendarApi = calendarRef.current.getApi();

    if (calendarView === "timeGridNarrow") {
      const nextWeekStart = calendarInterval.start.plus({ days: 7 });
      const nextWeekDay = currentDay.plus({ days: 7 });

      setCurrentDay(nextWeekDay);
      setCalendarInterval({
        start: nextWeekStart,
        end: nextWeekStart.plus({ days: 6 }),
      });
      calendarApi.gotoDate(nextWeekDay.toJSDate());
    } else {
      calendarApi.next();
      setCalendarInterval({
        start: DateTime.fromJSDate(calendarApi.view.activeStart).setZone(
          timeZone
        ),
        end: DateTime.fromJSDate(calendarApi.view.activeEnd).setZone(timeZone),
      });
    }
  };

  const onPrevCalendar = () => {
    const calendarApi = calendarRef.current.getApi();

    if (calendarView === "timeGridNarrow") {
      const prevWeekStart = calendarInterval.start.minus({ days: 7 });
      const prevWeekDay = currentDay.minus({ days: 7 });

      setCurrentDay(prevWeekDay);
      setCalendarInterval({
        start: prevWeekStart,
        end: prevWeekStart.plus({ days: 6 }),
      });
      calendarApi.gotoDate(prevWeekDay.toJSDate());
    } else {
      calendarApi.prev();
      setCalendarInterval({
        start: DateTime.fromJSDate(calendarApi.view.activeStart).setZone(
          timeZone
        ),
        end: DateTime.fromJSDate(calendarApi.view.activeEnd).setZone(timeZone),
      });
    }
  };

  const onDateChangeCalendarMobile = (date: DateTime) => {
    const calendarApi = calendarRef.current.getApi();

    if (calendarView === "timeGridNarrow") {
      setCurrentDay(date);
    } else {
      setCalendarInterval({
        start: DateTime.fromJSDate(calendarApi.view.activeStart).setZone(
          timeZone
        ),
        end: DateTime.fromJSDate(calendarApi.view.activeEnd).setZone(timeZone),
      });
    }

    calendarApi.gotoDate(date.toJSDate());
  };

  const onDateChangeCalendar = (date: DateTime) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(date.toJSDate());

    if (calendarView === "timeGridNarrow") {
      const startOfWeek = date.startOf("week").setZone(timeZone);
      const endOfWeek = date.endOf("week").setZone(timeZone);
      setCurrentDay(date);
      setCalendarInterval({
        start: startOfWeek,
        end: endOfWeek,
      });
    } else {
      setCurrentDay(date);
      setCalendarInterval({
        start: DateTime.fromJSDate(calendarApi.view.activeStart).setZone(
          timeZone
        ),
        end: DateTime.fromJSDate(calendarApi.view.activeEnd).setZone(timeZone),
      });
    }
  };

  const renderEvent = (info: any) => {
    return <Event {...info} />;
  };

  const renderDayHeader = (info) => {
    if (calendarView === "timeGridWeek") {
      return <DayHeader {...info} events={events.data} />;
    }

    let startOfWeek = DateTime.fromJSDate(info.view.currentStart).setZone(
      info.view.dateEnv.timeZone
    );

    if (startOfWeek.weekday !== 7) {
      startOfWeek = startOfWeek.minus({ days: startOfWeek.weekday });
    }

    return (
      <DayHeaderMobile
        startOfWeek={startOfWeek.toJSDate()}
        events={events.data}
        view={info.view}
        onDateChange={onDateChangeCalendarMobile}
        selectedDate={currentDay}
      />
    );
  };

  const onDeleteEvent = () => {
    let content =
      selectedEvent.type === "coach_schedule_block"
        ? t("Please confirm that you would like to cancel this event.")
        : t("Please confirm that you would like to delete this session.");

    if (selectedEvent.recurrenceGroupId) {
      content =
        selectedEvent.type === "coach_schedule_block"
          ? t(
              "Your event is set to {{repeat}}. You can choose whether you wish to apply these changes to the repeating dates as well.",
              { repeat: capitalize(selectedEvent.repeat) }
            )
          : t(
              "Your session is set to “Repeating - {{repeat}}”. You can choose whether you wish to apply these changes to the repeating dates as well.",
              { repeat: capitalize(selectedEvent.repeat) }
            );
    }

    const title =
      selectedEvent.type === "coach_schedule_block"
        ? t("Delete Unavailability")
        : t("Cancel Session");

    requestConfirmation({
      event: selectedEvent,
      content,
      title,
      operation: "delete",
      actions: {
        onConfirm: () => {
          dispatch(deleteEvent(selectedEvent));
        },
        onConfirmFollowing: () => {
          dispatch(deleteEvent(selectedEvent, "following"));
        },
      },
    });
  };

  const onSaveEventForm = (data) => {
    if (data.id) {
      const payload = EventModel.apiPayload(data, "update");
      let content = t(
        "Please confirm that you would like to edit this session."
      );
      if (data.recurrenceGroupId) {
        content = t(
          "Your session is set to “Repeating - {{repeat}}”. You can choose whether you wish to apply these changes to the repeating dates as well.",
          { repeat: capitalize(selectedEvent.repeat) }
        );
      }
      requestConfirmation({
        event: selectedEvent,
        content,
        title: t("Edit Event"),
        operation: "edit",
        actions: {
          onConfirm: () => {
            dispatch(editEvent(payload));
          },
          onConfirmFollowing: () => {
            dispatch(editEvent({ ...payload, applyToRecurrence: "following" }));
          },
        },
      });
    } else {
      const payload = EventModel.apiPayload(data, "create");
      dispatch(createEvent(payload));
    }
  };

  const onEditEvent = () => {
    dispatch(startEdit());
  };

  const onEditProgram = () => {
    window.location.replace(`/programs/${selectedEvent?.id}/view`);
  };

  const onCancelSelection = () => {
    calendarRef.current.getApi().unselect();
    dispatch(deselectEvent());
  };

  const initNewEvent = () => {
    const newEventDate =
      calendarView === "timeGridNarrow" && currentDay ? currentDay : null;

    dispatch(
      initializeNewEvent({
        start: null,
        end: null,
        date: newEventDate ? newEventDate.toJSDate() : null,
        type: EventType.LESSON_SCHEDULE,
        timeZone,
        backgroundColor: "#0279b3",
      })
    );
  };

  const handleCalendarSettings = () => {
    history.push("/calendar/pricing");
  };

  // @ts-ignore: next-line
  const eventSubmitErrors = events.error ? events.error.errors : {};

  return (
    <div className={styles.container}>
      {showEventForm && (
        <EventForm
          event={eventForForm}
          lessonRateOptions={coachProfile.data.lessonRates}
          lessonTypeOptions={lessonTypeOptions}
          onCancel={onCancelSelection}
          onSave={onSaveEventForm}
          submitting={events.pendingCreate || events.pendingEdit}
          errors={eventSubmitErrors}
          coachProfile={coachProfile.data}
        />
      )}

      <InfoModal
        isOpen={showInfoPopup}
        title={infoPopupContent.title}
        status={infoPopupContent.status}
        onDismiss={() => dispatch(hideInfoPopup())}
        payload={infoPopupContent.payload}
        action={infoPopupContent.action}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>{infoPopupContent.content}</div>
          <div>
            <b style={{ color: "#000" }}> {infoPopupContent.subcontent}</b>
          </div>
        </div>
      </InfoModal>

      <PromptComponent />

      <div
        id="calendar-element"
        className={`${styles.calendar_container} calendar-page-container`}
      >
        <CalendarToolbar
          date={dateCalendar}
          onNext={onNextCalendar}
          onPrev={onPrevCalendar}
          onDateChange={onDateChangeCalendar}
          handleCalendarSettings={handleCalendarSettings}
          initNewEvent={initNewEvent}
          calendarView={calendarView}
        />

        <EventInfo
          event={selectedEvent}
          referenceElement={eventElRef.current}
          onDelete={onDeleteEvent}
          onEdit={selectedEvent?.type === 'program' ? onEditProgram : onEditEvent}
        />
        <FullCalendar
          headerToolbar={false}
          ref={calendarRef}
          events={events.data as any[]}
          allDaySlot={false}
          selectable
          selectMirror
          unselectAuto
          expandRows
          unselectCancel=".event_form_container, .time-picker-panel, .confirm_cancel_conflict, .info-modal-content, .info-modal-header"
          plugins={[timeGridPlugin, luxonPlugin, interactionPlugin]}
          unselect={() => dispatch(deselectEvent())}
          viewDidMount={(viewApi) => {
            if (calendarView === "timeGridNarrow") {
              const dayBuilder = DateTime.fromJSDate(viewApi.view.currentStart)
                .setZone(timeZone)
                .startOf("week");
              const startOfWeek = dayBuilder.minus({ days: 1 });
              const endOfWeek = dayBuilder.endOf("week");

              setCalendarInterval({
                start: startOfWeek,
                end: endOfWeek,
              });
              setCurrentDay(DateTime.now().setZone(timeZone));
            } else {
              setCalendarInterval({
                start: DateTime.fromJSDate(viewApi.view.activeStart).setZone(
                  timeZone
                ),
                end: DateTime.fromJSDate(viewApi.view.activeEnd).setZone(
                  timeZone
                ),
              });
            }
          }}
          select={(info) => {
            // @ts-ignore: next-line
            const timeZone = info.view.dateEnv.timeZone;
            const date = DateTime.fromISO(info.startStr)
              .setZone(timeZone)
              .toISO();

            dispatch(
              initializeNewEvent({
                date: new Date(date),
                start: info.startStr,
                end: info.endStr,
                type: EventType.LESSON_SCHEDULE,
                backgroundColor: "#0279b3",
                timeZone,
              })
            );
          }}
          dayHeaderFormat={{ day: "numeric", weekday: "short" }}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: true,
            hour12: true,
          }}
          initialView={calendarView}
          views={{
            timeGridNarrow: {
              type: "timeGrid",
              duration: { days: 1 },
            },
          }}
          timeZone={timeZone}
          eventContent={renderEvent}
          dayHeaderContent={renderDayHeader}
          businessHours={availability}
          slotDuration="01:00:00"
          height="100%"
          eventBackgroundColor="#039BE5"
          snapDuration="00:30:00"
          eventClick={(info) => {
            eventElRef.current = info.el;
            dispatch(setSelectedEvent(parseInt(info.event.id, 10)));
          }}
          longPressDelay={600}
          scrollTime="05:45:00"
          nowIndicator={true}
          nowIndicatorClassNames="now-indicator-line now-indicator-container"
        />
      </div>
    </div>
  );
}
