import { isEmpty } from "lodash";
import UserItem from "../UserItem";
import styles from "./styles.module.scss";
import React from "react";

const UserItems = ({ userList, onClose, cantClose = false , showEmail = true, separatorStyle = null}) => {
  if (isEmpty(userList)) {
    return null;
  }

  return userList?.map((item, index) => (
    <div key={item.id}>
      <UserItem
        key={item.id}
        {...item}
        showEmail={showEmail}
        closeStyle={styles.close_style}
        height={30}
        width={30}
        cantClose={cantClose}
        displayClose
        itemStyle={styles.item_style}
        onClose={onClose}
      />
      {index + 1 < userList.length && <div className={separatorStyle || styles.line_separator} />}
    </div>
  ));
};

export default UserItems;
