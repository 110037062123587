import React, { memo } from "react";
import styles from "./styles.module.scss";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import closeIcon from "../../../../public/images/close-icon.svg";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  hideHeader?: boolean;
  hideCloseIcon?: boolean;
  headerTitle?: string;
  className?: string;
  children: React.ReactNode;
}

const ModalLayoutWithTitle: React.FC<Props> = ({
  isOpen,
  onClose,
  hideHeader,
  hideCloseIcon,
  headerTitle,
  className,
  children,
}: Props) => {
  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} classNames={className}>
      <div className={styles.content_wrapper}>
        {!hideHeader && (
          <div className={styles.modal_header}>
            {headerTitle && <h1>{headerTitle}</h1>}
            {!hideCloseIcon && (
              <img
                src={closeIcon}
                alt="close"
                className={styles.close_icon}
                onClick={onClose}
              />
            )}
          </div>
        )}
        {children}
      </div>
    </ModalLayout>
  );
};

export default memo(ModalLayoutWithTitle);
