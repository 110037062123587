import styles from "./styles.module.scss";
import stylesOBG from "../styles.module.scss";
import React from "react";

const RoundButton = ({ children, onClick, customStyle }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${stylesOBG.button} ${styles.button} ${customStyle}`}
    >
      {children}
    </button>
  );
};

export default RoundButton;
