import React from "react";
import styles from "./styles.module.scss";
import { SpaceSelectProps } from "./index.types";
// import no_space_png from "app/images/null-training-space.png";
import UserAvatar from "app/components/UserAvatar";
import { cls } from "app/utils";
import useSpaceSelectModal from "./indexModel";
import SpaceModel from "app/models/SpaceModel";
import ApplyTemplateListsModal from "../ApplyTemplateListsModal";
import { useTranslation } from "react-i18next";
import { Loading } from "app/components/Wrappers";

const SpaceSelectModal = ({
  onClose,
  isOpen,
  templateId,
}: SpaceSelectProps) => {
  const { t } = useTranslation();
  const {
    containerRef,
    spaceSelectHandler,
    cancelHandler,
    applyHandler,
    onSearchSpace,
    searchTerm,
    selectedSpacesId,
    spacesData,
    pending,
  } = useSpaceSelectModal({ onClose, templateId });

  if (!isOpen) {
    return null;
  }

  return (
    <ApplyTemplateListsModal
      // pending={pending}
      applyHandler={applyHandler}
      cancelHandler={cancelHandler}
      hasNoResult={
        searchTerm?.length === 0 && spacesData?.length === 0 && !pending
      }
      // noResultImage={no_space_png}
      noResultTitle={t("No Spaces")}
      noResultSubText={t("You don't have created a space yet")}
      isOpen={isOpen}
      onClose={onClose}
      searchPlaceholder={t("Search space...")}
      onSearchChange={onSearchSpace}
      modalSubTitle={t(
        "You can apply this Template to the Space and posts will be added in the set interval and order",
      )}
    >
      <div className={styles.space_list_container}>
        {spacesData?.map((data: { space: SpaceModel }) => {
          return (
            <div
              key={data?.space?.id}
              className={cls(styles.space_container, {
                [styles.disabled]:
                  !data?.space?.planPermissions?.includes("post"),
              })}
              onClick={() =>
                data?.space?.planPermissions?.includes("post")
                  ? spaceSelectHandler(data?.space?.id as string, data?.space)
                  : null
              }
            >
              <div className={styles.avatar}>
                <UserAvatar
                  sizeWidth={44}
                  sizeHeight={44}
                  className="avatar"
                  userName={data?.space?.ownerName}
                  srcName={data?.space?.avatar as string}
                  altName={data?.space?.name}
                />
              </div>
              <div className={styles.space}>
                <div className={cls(styles.space_title, styles.elipsis)}>
                  {data?.space.athleteName}
                </div>
                <div className={cls(styles.space_subtitle, styles.elipsis)}>
                  {data?.space.sport}
                </div>
              </div>
              <span className={styles.checkbox}>
                {selectedSpacesId.includes(data?.space.id) ? (
                  <i className={cls("fas fa-check-circle", styles.checked)} />
                ) : (
                  <i className={cls("far fa-circle", styles.unchecked)} />
                )}
              </span>
            </div>
          );
        })}
        {pending && (
          <Loading
            isLoading={pending}
            loadType="spinner"
            className={styles.loading}
          />
        )}
        <div ref={containerRef} />
      </div>
    </ApplyTemplateListsModal>
  );
};

export default SpaceSelectModal;
