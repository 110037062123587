import React from "react";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import ButtonViewProgram from "../ButtonViewProgram";
import { RootState } from "typedefs";
import StudentViewButtons from "../StudentViewButtons";
import CoachViewButtons from "../CoachViewButtons";

const ButtonsViewProgram = ({
  program,
  onRegisterClick,
  onPublicRegisterClick,
  participantStatus,
  noRegisteredParticipants,
  onMessageClick,
}) => {
  const userType = useSelector(
    (state: RootState): string => state.user?.data?.kind
  );

  if (userType === "athlete") {
    return (
      <StudentViewButtons
        program={program}
        onRegisterClick={onRegisterClick}
        participantStatus={participantStatus}
      />
    );
  } else if (!userType) {
    return (
      <div className={styles.buttonsContainer}>
        <ButtonViewProgram
          colorStyle={styles.save_btn}
          text={"Register"}
          handleClick={onPublicRegisterClick}
        />
      </div>
    );
  } else {
    return (
      <CoachViewButtons
        onMessageClick={onMessageClick}
        noRegisteredParticipants={noRegisteredParticipants}
      />
    );
  }
};

export default ButtonsViewProgram;
