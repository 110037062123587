import styles from "../styles.module.scss";
import React, { useState } from "react";
import PhotoPreview from "../PhotoPreview";
import FailContainer from "../FailContainer";
import PhotoUploader from "../PhotoUploader";

const PhotoContainer = ({
  imageLink,
  title = null,
  containerStyle,
  description = null,
  customPlaceholderStyle,
  type = null,
  setUrl = (_value: string | null) => null,
  uploadedImageContainer,
  allowsDefault = false,
  onSelectClick = () => null,
  handleCrop = () => null,
}) => {
  const [result, setResult] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [errorMessage, _setErrorMessage] = useState("");
  const [uploadProgress, _setUploadProgress] = useState({});

  const handleURL = (url) => {
    setUrl(url);
    if (handleCrop) {
      handleCrop();
    }
  };

  const uploadPhoto = (_type, imageUrl) => {
    handleURL(imageUrl);
    setImageLoader(false);
  };

  const deletePhoto = () => {
      setUrl(null);
  };

  const [typeTemp, setTypeTemp] = useState(type);
  let component;
  if (imageLink) {
    component = (
      <PhotoPreview
        type={typeTemp}
        imageLink={imageLink}
        uploadPhoto={uploadPhoto}
        setType={setTypeTemp}
        result={result}
        errorMessage={errorMessage}
        setDisplayModal={deletePhoto}
        imageLoader={imageLoader}
        uploadProgress={uploadProgress}
        customContainerStyle={uploadedImageContainer}
      />
    );
  } else if (result === "fail") {
    component = (
      <FailContainer
        type={typeTemp}
        uploadPhoto={uploadPhoto}
        setType={setTypeTemp}
        setResult={setResult}
        errorMessage={errorMessage}
        imageLoader={imageLoader}
        uploadProgress={uploadProgress}
        customPlaceholderStyle={customPlaceholderStyle}
      />
    );
  } else {
    component = (
      <PhotoUploader
        allowsDefault={allowsDefault}
        onSelectClick={onSelectClick}
        type={typeTemp}
        uploadPhoto={uploadPhoto}
        imageLoader={imageLoader}
        uploadProgress={uploadProgress}
        customPlaceholderStyle={customPlaceholderStyle}
      />
    );
  }

  return (
    <div className={containerStyle} data-testid="loading-placeholder">
      {title && <h3>{title}</h3>}
      {component}
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default PhotoContainer;
