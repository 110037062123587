import styles from "../styles.module.scss";
import React from "react";

export default function FailPlaceholder({
  type,
  errorMessage,
  customPlaceholderStyle = "",
}) {
  return (
    <div className={`${styles.photo_uploader_container}`}>
      <div
        className={`
          ${styles.placeholder}
          ${styles.faild_placeholder}
          ${
            type === "logo"
              ? styles.logo_placeholder
              : styles.banner_placeholder
          }
          ${customPlaceholderStyle}
        `}
      >
        <div className={styles.inside_box}>
          <p className={styles.faild_color_text}>Upload Failed</p>
          <p className={styles.supports}>
            {errorMessage || "Please try again."}
          </p>
        </div>
      </div>
    </div>
  );
}
