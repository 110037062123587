/**
 * @module Reducers.SpaceCreation
 *
 */
import { RequestError } from "typedefs";
import { SpaceCreationActions } from "app/actions/spaceCreation";
import { SpaceApplicationModel } from "app/models";

export type spacecCreationState = {
  spaces_import: SpaceApplicationModel | null;
  add_by_phone: boolean;
  step: number;
  isMultiple: boolean;
  uploadProgress: number;
  pending: boolean;
  data: {};
  error: RequestError;
};

export const baseSpaceApplication: SpaceApplicationModel = {
  athletes: [],
  coaches: [],
  contributors: [],
  create_for_self: true,
  segment_ids: [],
  space_template_id: { label: "", id: "" },
  sport: "Golf",
  sport_other: "Golf",
};

const initialState: spacecCreationState = {
  spaces_import: baseSpaceApplication,
  add_by_phone: false,
  step: 1,
  isMultiple: false,
  uploadProgress: 0,
  data: null,
  error: null,
  pending: false,
};

export default function spaceReducer(
  state = initialState,
  action: SpaceCreationActions,
): spacecCreationState {
  switch (action.type) {
    case "@spaceCreation.fetch.newSpaceValues":
      return { ...state, spaces_import: action.payload };
    case "@spaceCreation.fetch.addByPhone":
      return { ...state, add_by_phone: action.payload };
    case "@spaceCreation.fetch.step":
      return { ...state, step: action.payload };
    case "@spaceCreation.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@spaceCreation.fetch.success":
      return { ...state, data: action.payload, pending: true };
    case "@spaceCreation.fetch.isMultiple":
      return { ...state, isMultiple: action.payload };
    case "@spaceCreation.fetch.uploadProgress":
      return { ...state, uploadProgress: action.payload };
    case "@spaceCreation.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@spaceCreation.clear":
      return {
        ...state,
        spaces_import: baseSpaceApplication,
        add_by_phone: false,
        step: 1,
        isMultiple: false,
        uploadProgress: 0,
        data: null,
        error: null,
        pending: false,
      };

    default:
      return state;
  }
}
