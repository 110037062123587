import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Loading } from "app/components/Wrappers";
import PagePanel from "app/components/PagePanel";
import SessionForm from "../SessionForm";
import useBookSessionData from "../hooks/useBookSessionData";

import { selectionForLesson } from "../helpers";
import styles from "../NewSession/styles.module.scss";

import { setBookSession } from "app/actions/scheduling/bookSession";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";

import { useTimeZonesModel } from "../indexModel";

const EditLesson = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userID = useSelector((state: RootState) => state.user.self.id);
  const bookLessonState = useSelector((state: RootState) => state.bookSession);
  const { t } = useTranslation();

  if (!bookLessonState.selectedSession) {
    history.push("/sessions");
    return null;
  }

  const fetcher = useCallback(() => {
    if (!userID) {
      return Promise.resolve([]);
    }
    const facilitiesPromise = schedulingFetchGet("/facilities");
    const coachPromise = schedulingFetchGet("/coach_profiles");
    const lessonTypesPromise = schedulingFetchGet("/lesson_types");

    return Promise.all([facilitiesPromise, coachPromise, lessonTypesPromise]);
  }, [userID]);

  const [loadingBookLessonData, bookLessonData] = useBookSessionData(fetcher);

  useEffect(() => {
    if (loadingBookLessonData) {
      return;
    }

    const initialData = selectionForLesson(bookLessonData, bookLessonState);
    dispatch(
      setBookSession({
        ...initialData,
        date: initialData.date.toISODate(),
      })
    );
    // Following eslint disable is intentional.
    // I do not want this useEffect to rerun if other things change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingBookLessonData, dispatch]);

  const { pending: loadingTimeZones } = useTimeZonesModel();

  if (loadingBookLessonData || !bookLessonState.facility || loadingTimeZones) {
    return <Loading isLoading loadType="spinner" />;
  }

  return (
    <div className={`${styles.container} sessions-page-container`}>
      <PagePanel
        title={t("Edit Session")}
        buttonType="outline-secondary"
        buttonTitle={<i className="ico ico-back" style={{ color: "#000" }} />}
        url="/sessions"
      />
      <SessionForm bookLessonData={bookLessonData} />
    </div>
  );
};

export default EditLesson;
