import React, { useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import SpaceMemberModel from "app/models/SpaceMemberModel";
import { useTranslation } from "react-i18next";

interface Props {
  member?: SpaceMemberModel;
}

const MemberRoleBadge: React.FC<Props> = ({ member }: Props) => {
  const { t } = useTranslation();
  const [role, setRole] = useState(member.spaceRole || member.role);

  if (role === "contributor") {
    setRole("Member");
  }

  return (
    <div>
      <span
        className={cls(styles.role_badge, {
          [styles.coach]: role === "coach",
          [styles.athlete]: role === "athlete",
          [styles.admin]: role === "admin",
          [styles.recruiter]: role === "recruiter",
          [styles.contributor]: role === "Member",
        })}
      >
        {role}
      </span>{" "}
      {member?.status === "declined" && (
        <span className={cls(styles.role_badge, styles.declined)}>
          {t("Declined")}
        </span>
      )}
    </div>
  );
};

export default MemberRoleBadge;
