import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import Button from "app/components/Button/ButtonVariant";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
// import UserSelect from "app/components/Filter/UserSelect";
import UserModel from "app/models/UserModel";
import SelectBox, { UserSelect } from "app/components/inputs/SelectBox";
import UserAvatar from "app/components/UserAvatar";
import { cls } from "app/utils";
import { useActions } from "app/utils/hooks";
import { addSegmentMember } from "../../../actions/segments";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface RouteParams {
  segmentId: string;
}

const InviteToSegment = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { segmentId } = useParams<RouteParams>();
  const cancelUrl = segmentId ? `/lists/${segmentId}/spaces` : "/lists/";
  const [error, setError] = useState(false);
  const [selectedRole, setSelectedRole] = useState<{
    value: string;
    label: string;
  }>({ value: "coach", label: t("Coach") });
  const [selectedUser, setSelectedUser] = useState<{ model: UserModel } | null>(
    null,
  );

  const segmentActions = useActions({ addSegmentMember });

  const onUserSelect = useCallback(
    (selectedData: { model: UserModel }) => {
      if (selectedData) {
        setSelectedUser(selectedData);
        setError(false);
      }
    },
    [selectedUser],
  );

  const updateHandler = () => {
    if (!selectedUser) {
      setError(true);
    } else {
      setError(false);
      segmentActions
        .addSegmentMember(segmentId, {
          email: selectedUser?.model?.email,
          name: selectedUser?.model?.displayName,
          role: selectedRole?.value,
        })
        .then(() => {
          // window.location.pathname = cancelUrl;
          history.push(cancelUrl);
        });
    }
  };

  const onRoleSelect = (e: any) => {
    setSelectedRole(e);
  };

  const onRemoveUserClick = () => {
    setSelectedUser(null);
  };

  const SelectedUser = useCallback(() => {
    return (
      <div className={styles.user_container}>
        <div className={styles.user_info_container}>
          <UserAvatar
            className={styles.avatar}
            sizeWidth={44}
            sizeHeight={44}
            altName="author"
            userName={selectedUser.model.name}
            srcName={selectedUser.model.avatar as string}
          />
          <div className={styles.user_info}>
            <h4>{selectedUser.model.displayName}</h4>
            <p>{selectedUser.model.email}</p>
          </div>
        </div>
        <Button
          title={<i className={cls("ico ico-x text-danger")} />}
          onClickFunc={onRemoveUserClick}
          buttonType="secondary-outline"
        />
      </div>
    );
  }, [selectedUser]);

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <PagePanel title={t("Invite member to all Spaces in the List")} />
        <div className={styles.content_wrapper}>
          <span className={styles.input_wrapper}>
            <label htmlFor="segment_name">{t("User")}</label>
            {!selectedUser && (
              <UserSelect
                isCreatable
                no-options-text={t("Add new user")}
                onChange={(e: any) => onUserSelect(e)}
                hideSelected
                isMulti={false}
                className={styles.user_list_input}
                value={selectedUser}
              />
            )}
            {selectedUser && selectedUser.model && <SelectedUser />}
          </span>
          <span className={styles.input_wrapper}>
            <label htmlFor="segment_name">{t("Role")}</label>
            <SelectBox
              onChange={onRoleSelect}
              className={styles.user_list_input}
              options={[
                { value: "coach", label: t("Coach") },
                { value: "athlete", label: t("Athlete") },
                { value: "member", label: t("Member") },
              ]}
              value={selectedRole}
            />
          </span>
          <span className={styles.btn_wrapper}>
            <Button
              title={t("Add")}
              buttonType="primary"
              disabled={error || !selectedUser}
              onClickFunc={updateHandler}
            />
            <a href={cancelUrl}>
              <Button title={t("Cancel")} buttonType="secondary-outline" />
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default InviteToSegment;
