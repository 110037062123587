import styles from "../styles.module.scss";
import React from "react";
// import ImageIco from '!@svgr/webpack!../../public/svgs/image_icon.svg';

export default function LoadingPlaceholder({ uploadProgress }) {
  const { percentage = 0, secondsLeft = 0 } = uploadProgress || {};
  return (
    <div data-testid="loading-placeholder">
      {/* <ImageIco className={styles.image_ico} /> */}
      <p className={styles.uploading_text}>Uploading...</p>
      <p className={styles.supports}>
        {percentage}% • {secondsLeft} sec left
      </p>
      <div className={styles.progress_bar_container}>
        <div
          className={styles.progress_bar}
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  );
}
