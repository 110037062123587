import { useHistory, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import style from "./styles.module.scss";
import { clearPost, fetchPostById, reportPost } from "app/actions/posts";
import { editPost, deletePost } from "app/actions/postingForm";
import { RootState } from "typedefs";
import { PostModel } from "app/models";
import { Loading } from "app/components/Wrappers";
import PagePanel from "app/components/PagePanel";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { useActions } from "app/utils/hooks";
import { useTranslation } from "react-i18next";
import Post from "../../components/Post";
import PostReportModal from "app/components/Post/InnerPost/PostReportModal";

const PostPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [updateError, setUpdateError] = useState<boolean>(false);

  const postsActions = useActions({
    editPost,
    deletePost,
    reportPost,
  });

  const { posts, user } = useSelector(
    (state: RootState) => state,
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      fetchPostById(id, {
        "options[include]": ["replies", "spaces"],
      }),
    );

    return () => {
      dispatch(clearPost());
    };
  }, [id]);

  const onPostEdit = useCallback(
    async (editedPost: PostModel) => {
      try {
        await postsActions.editPost({
          ...editedPost,
        });
      } catch (e) {
        setUpdateError(true);
      }
    },
    [posts],
  );

  const onPostDelete = useCallback((posts: PostModel) => {
    postsActions.deletePost(posts?.id);
  }, []);

  const onPostReportClick = useCallback(() => {
    setReportModalOpen(true);
  }, []);

  const onPostReportClose = useCallback(() => {
    setReportModalOpen(false);
  }, []);

  const onReport = useCallback(
    async (message?: string) => {
      const data = await postsActions.reportPost(posts?.data?.id, message);
      if (data.id) {
        setReportModalOpen(false);
        history.goBack();
      }
    },
    [posts?.data],
  );

  if (!posts?.data?.id && posts?.pending) {
    return <Loading loadType="spinner" isLoading={posts.pending} />;
  }

  // const detectLocation = (posts: PostModel) => {
  //   if (posts) {
  //     if (posts?.teamGroup?.id) {
  //       setPostLocationData({
  //         location: posts?.teamGroup?.name,
  //       });
  //       if (posts?.teamGroup?.team?.id) {
  //         setPostLocationData({
  //           location: posts?.teamGroup?.team?.name,
  //           subLocation: posts?.teamGroup?.name,
  //         });
  //       }
  //     } else if (posts?.space?.id) {
  //       setPostLocationData({
  //         location:
  //       })
  //     }
  //   }
  // };

  function formatPostLocation(posts: PostModel) {
    if (posts?.teamGroup != null) {
      return {
        location: posts?.teamGroup?.team?.name,
        subLocation: posts?.teamGroup?.name,
        avatar: posts?.teamGroup?.team?.logo,
        goToUrl: `/groups/${posts?.teamGroup?.team?.id}/channels/${posts?.teamGroup?.id}`,
      };
    } else if (posts?.team != null) {
      return {
        location: posts?.team?.name,
        subLocation: undefined,
        avatar: posts?.team?.logo,
        goToUrl: `/groups/${posts?.team?.id}`,
      };
    } else if (posts?.space != null) {
      return {
        location: posts?.space?.athleteName,
        subLocation: posts?.space?.sport,
        avatar: posts?.space?.avatar,
        goToUrl: `/spaces/${posts?.space?.id}`,
      };
    }

    return {
      location: user?.self?.displayName,
      subLocation: undefined as any,
      avatar: user?.self?.avatar,
      goToUrl: "/",
    };
  }

  return (
    <div className={style.container}>
      <GettingStartedProgress />
      <PagePanel
        headerWrapperClassName={style.panel_container}
        title={formatPostLocation(posts?.data)?.location}
        subTitle={formatPostLocation(posts?.data)?.subLocation}
        avatar={formatPostLocation(posts?.data)?.avatar}
        onButtonClick={() =>
          history?.push(formatPostLocation?.(posts?.data)?.goToUrl)
        }
        buttonTitle={t("Go To Timeline")}
        buttonType="outline-secondary"
        buttonStyles={style.go_to_button}
      />
      <Post
        key={posts?.data?.id}
        post={posts?.data}
        onRemove={onPostDelete}
        onReport={onPostReportClick}
        onEdit={onPostEdit}
        updateError={!!updateError}
        user={self}
        hidePinBadge
        canPinPost={false}
      />
      {isReportModalOpen && (
        <PostReportModal
          isModalOpen={isReportModalOpen}
          modalHandler={onPostReportClose}
          reportHandler={onReport}
        />
      )}
    </div>
  );
};
export default PostPage;
