import React, { ReactElement, useState } from "react";
import { RootState } from "typedefs";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { EventResponse } from "app/models/EventModel";
import { schedulingFetchPost } from "app/utils/request/scheduling/fetch";
import SaveBar from "app/routes/Sessions/SaveBar";
import PagePanel from "app/components/PagePanel";
import InfoModal from "app/routes/Calendar/InfoModal";
import { resetBookSession } from "app/actions/scheduling/bookSession";

import styles from "./styles.module.scss";

import { BookSessionState } from "app/reducers/scheduling/bookSession";

type SummaryItemProps = {
  label: string | ReactElement;
  value: string | ReactElement;
};

const SummaryItem = (props: SummaryItemProps) => {
  const { label, value } = props;
  return (
    <div className={styles.summary_item}>
      <div className={styles.summary_attribute}>{label}</div>
      <div className={styles.summary_value}>{value}</div>
    </div>
  );
};

type PropsDef = {
  isPublic?: boolean;
};

export default function SessionConfirmation(props: PropsDef) {
  const { isPublic } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const bookSession = useSelector(
    (state: RootState): BookSessionState => state.bookSession
  );
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [sessionNotes, setSessionNotes] = useState("");
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation();

  if(!bookSession.coach) {
    return null;
  }

  const createSession = () => {
    let payload = {};
    setLoading(true);
    if (isPublic) {
      payload = {
        start: DateTime.fromMillis(bookSession.time.startHour).setZone(bookSession.coach.timeZone).toISO(),
        end: DateTime.fromMillis(bookSession.time.endHour).setZone(bookSession.coach.timeZone).toISO(),
        lessonTypesIds: bookSession.lessonTypes.map(lt => lt.id),
        coachProfileId: bookSession.coach.calendarUuid,
        lessonRateId: bookSession.pricingValue.id,
        notes: sessionNotes,
        isPublic,
        source: 'public_calendar'
      };
    } else {
      payload = {
        start: DateTime.fromMillis(bookSession.time.startHour).setZone(bookSession.coach.timeZone).toISO(),
        end: DateTime.fromMillis(bookSession.time.endHour).setZone(bookSession.coach.timeZone).toISO(),
        lessonTypesIds: bookSession.lessonTypes.map(lt => lt.id),
        coachProfileId: bookSession.coach.id,
        lessonRateId: bookSession.pricingValue.id,
        notes: sessionNotes,
        isPublic,
      };
   }
    schedulingFetchPost<EventResponse>('/bookings', payload)
    .then(() => {
      setShowPopup(true);
      setLoading(false);
    }).catch((errs) => {
      setLoading(false);
      setErrors(errs.errors);
    });
  };

  const dateStr = DateTime.fromISO(bookSession.date).toLocaleString({
    year: "numeric",
    day: "numeric",
    month: "long",
  });

  const startTimeString = DateTime.fromMillis(bookSession.time.startHour)
    .setZone(bookSession.time.timeZone)
    .toLocaleString(DateTime.TIME_SIMPLE);

  const endTimeString = DateTime.fromMillis(bookSession.time.endHour)
    .setZone(bookSession.time.timeZone)
    .toLocaleString(DateTime.TIME_SIMPLE);

  const timeZoneShortString = DateTime.fromMillis(bookSession.time.endHour)
    .setZone(bookSession.time.timeZone)
    .toFormat("ZZZZ");

  const intervalTimeString = `${startTimeString} - ${endTimeString} ${timeZoneShortString}`;

  return (
    <div
      className={`${styles.confirmation_page_container} session-confirmation-page-container`}
    >
      <PagePanel
        title={t("Book a Session")}
        buttonType="outline-secondary"
        buttonTitle={isPublic ? null : <i className="ico ico-back" style={{ color: '#000' }} />}
        url="/sessions/new"
      />

      <div className={styles.summary_container}>
        <div className={styles.summary_box}>
          <h2>Session Summary</h2>
          <hr />
          <SummaryItem
            label={t("Session")}
            value={bookSession.pricingValue.title}
          />
          <SummaryItem
            label={t("Coach")}
            value={(
              <>
                <img
                  className={styles.avatar}
                  src={bookSession.coach.profilePicture}
                />
                {bookSession.coach.fullName}
              </>
            )}
          />
          <SummaryItem
            label={t("Facility")}
            value={bookSession.facility.name}
          />
          <hr />
          <SummaryItem
            label={(
              <>
                <span className={styles.summary_icon}>
                  <i className="ico ico-calendar" />
                </span>
                {t("Date")}
              </>
            )}
            value={dateStr}
          />
          <SummaryItem
            label={(
              <>
                <span className={styles.summary_icon}>
                  <i className="ico ico-clock" />
                </span>
                {t("Time")}
              </>
            )}
            value={intervalTimeString}
          />
          {bookSession.pricingValue.location && (
            <SummaryItem
              label={(
                <>
                  <span className={styles.summary_icon}>
                    <i className="ico ico-map-marker" />
                  </span>
                  {t("Location")}
                </>
              )}
              value={bookSession.pricingValue.location}
            />
          )}
          <hr />
          <SummaryItem
            label={t("Session Rate")}
            value={`${bookSession.coach.currencySymbol}${bookSession.pricingValue.rate}`}
          />
        </div>

        <div className={styles.notes_container}>
          <h2> Notes </h2>
          <textarea
            className={styles.notes_input}
            placeholder={t("Add note here")}
            onChange={(ev) => {
              setSessionNotes(ev.target.value);
            }}
            value={sessionNotes || ""}
          />
        </div>
      </div>
      <SaveBar
        submitLabel={loading ? t("Saving...") : t("Confirm")}
        cancelLabel={isPublic? t("Cancel") : t("Back")}
        style={styles.save_bar}
        onCancel={isPublic? () => history.push('/') : () => history.push('/sessions/new')}
        onSubmit={createSession}
        submitDisabled={loading}
        showCancel={true}
      />

      <InfoModal
        isOpen={showPopup}
        title={t("Session Booked")}
        status="success"
        onDismiss={() => {
          dispatch(resetBookSession());
          history.push("/sessions");
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>{t("You have booked a new session for")}</div>
          <div>
            <b>
              {" "}
              {DateTime.fromMillis(bookSession.time.startHour)
                .setZone(bookSession.coach.timeZone)
                .toLocaleString(DateTime.DATE_HUGE)}{" "}
            </b>
            at
            <b>
              {" "}
              {DateTime.fromMillis(bookSession.time.startHour)
                .setZone(bookSession.coach.timeZone)
                .toLocaleString({
                  ...DateTime.TIME_WITH_SHORT_OFFSET,
                  second: undefined,
                })}
            </b>
          </div>
        </div>
      </InfoModal>

      <InfoModal
        isOpen={errors.length > 0}
        title={errors[0]?.details[0]?.message}
        status="error"
        onDismiss={() => {
          history.goBack();
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>{errors[0]?.details[0]?.detail}</div>
        </div>
      </InfoModal>
    </div>
  );
}
