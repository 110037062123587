import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import AccountWrap from "..";
import { cls } from "app/utils";
import Button from "app/components/Button/ButtonVariant";
import { useActions } from "app/utils/hooks";
import {
  fetchSubscriptions,
  clearSubscription,
} from "app/actions/subscription";
import SubscriptionModel from "app/models/SubscriptionModel";
import moment from "moment";
import SubscriptionCancelModal from "app/components/subscription/Modals/SubscriptionCancelModal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Subscriptions = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: subscriptions, pending } = useSelector(
    (state: RootState) => state.subscription,
    shallowEqual,
  );

  const route = (path: string) => {
    history.push(path);
  };

  const subscriptionAction = useActions({
    fetchSubscriptions,
    clearSubscription,
  });

  const [cancelModal, setCancelModal] = useState(false);

  const cancelModalHandler = () => {
    setCancelModal(!cancelModal);
  };

  useEffect(() => {
    subscriptionAction.fetchSubscriptions();

    return () => {
      subscriptionAction.clearSubscription();
    };
  }, []);

  const formatDate = (date: string) => {
    return moment(date).format("MMM DD, YYYY");
  };

  const handleUpgrade = useCallback((subscription) => {
    if (subscription.term === "Trial") {
      return route("/upgrade");
    }
    return route(`/upgrade?plan=${encodeURIComponent(subscription.planCode)}`);
  }, []);

  const expired = (subscription: SubscriptionModel) => {
    return (
      subscription.expiresAt !== null &&
      subscription.expiresAt < new Date().toISOString()
    );
  };

  const canCancel = (subscription: SubscriptionModel) => {
    return subscription.term !== "Trial" && !expired(subscription);
  };

  const canReactivate = (subscription: SubscriptionModel) => {
    return subscription.term === "Trial" && expired(subscription);
  };

  const isTrial = (subscription: SubscriptionModel) => {
    return subscription.term === "Trial";
  };

  const handleStartTrial = () => {};

  const renderSubscriptionInfo = (subscription: SubscriptionModel) => {
    if (subscription.isAutoRenewing && subscription.expiresAt !== null) {
      return (
        <p className={cls(styles.plan_info)}>
          {t("Renews on")}: {formatDate(subscription.expiresAt)}
        </p>
      );
    } else if (
      !subscription.isAutoRenewing &&
      !expired(subscription) &&
      subscription.expiresAt !== null
    ) {
      return (
        <p className={cls(styles.plan_info, "text-danger")}>
          {t("Expires on")}: {formatDate(subscription.expiresAt)}
        </p>
      );
    } else if (
      !subscription.isAutoRenewing &&
      expired(subscription) &&
      subscription.expiresAt !== null
    ) {
      return (
        <p className={cls(styles.plan_info, "text-danger")}>
          {t("Expired on")}: {formatDate(subscription.expiresAt)}
        </p>
      );
    } else if (!isTrial(subscription) && subscriptions?.length === 0) {
      return (
        <p className={cls(styles.plan_info, "text-danger")}>
          {t("base_name")}: {formatDate(subscription.expiresAt)}
        </p>
      );
    } else {
      return null;
    }
  };

  const renderButton = (subscription: SubscriptionModel) => {
    if (canCancel(subscription)) {
      return (
        <Button
          title={t("Cancel")}
          buttonType="danger"
          onClickFunc={cancelModalHandler}
        />
      );
    } else if (isTrial(subscription)) {
      return (
        <Button
          title={t("Upgrade")}
          buttonType="primary"
          onClickFunc={() => handleUpgrade(subscription)}
        />
      );
    } else if (canReactivate(subscription)) {
      return (
        <Button
          title={t("Reactivate")}
          buttonType="primary"
          onClickFunc={() => handleUpgrade(subscription)}
        />
      );
    } else {
      return null;
    }
  };

  // const renderSubscribeButton = () => {
  //   return (
  //     <Button title="Subscribe" buttonType="primary" onClickFunc={() => {}} />
  //   );
  // };

  return (
    <>
      <AccountWrap>
        <div className={cls(styles.container, "panel")}>
          <h1 className={styles.title}>{t("Subscriptions")}</h1>
          {!subscriptions && pending && (
            <div className={styles.loading_wrapper}>
              <b>{t("Please wait...")}</b>
            </div>
          )}
          <div className={styles.subscriptions_list_container}>
            {!pending &&
              subscriptions?.map((subscription: SubscriptionModel) => (
                <div key={subscription._id} className={styles.plan_container}>
                  <div className={styles.plan_wrapper}>
                    <h4 className={styles.plan_title}>
                      {subscription.planName}
                    </h4>
                    {renderSubscriptionInfo(subscription)}
                  </div>
                  {renderButton(subscription)}
                  {cancelModal && (
                    <SubscriptionCancelModal
                      subscription={subscription}
                      isOpen={cancelModal}
                      onClose={cancelModalHandler}
                    />
                  )}
                </div>
              ))}
            {!pending && subscriptions?.length === 0 && (
              <div className={styles.plan_container}>
                <div className={styles.plan_wrapper}>
                  <h4 className={styles.plan_title}>{t("Coachnow+")}</h4>
                </div>
                <div className={styles.start_trial_btns}>
                  <Button
                    title={t("Start Free Trial")}
                    buttonType="success"
                    onClickFunc={handleStartTrial}
                  />
                  <Button
                    title={t("Upgrade")}
                    buttonType="primary"
                    onClickFunc={() => {}}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </AccountWrap>
    </>
  );
};

export default Subscriptions;
