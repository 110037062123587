import {
  clearMediaState,
  createMedia,
  fetchMediaById,
} from "app/actions/media-library-data";
import { MediaTypes } from "app/routes/Resources/ResourceCard/index.types";
import { useActions } from "app/utils/hooks";
import upload from "app/utils/uploader";
import { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";

interface Props {
  onClose: () => void;
  onSelectMedia: (media: MediaTypes) => void;
}

const useFileUpload = ({ onClose, onSelectMedia }: Props) => {
  const mediaActions = useActions({
    createMedia,
    clearMediaState,
    fetchMediaById,
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [selectedTab, setSelectedTab] = useState<tabName>("upload");
  const [isMediaSelected, setIsMediaSelected] = useState(false);
  type tabName = "upload" | "library";

  const uploadRef = useRef(null);

  const modalHandler = () => {
    onClose();
    setIsLoading(false);
    setProgress(0);
    setSelectedTab("upload");
    if (uploadRef.current) {
      uploadRef.current.abort();
      uploadRef.current = null;
    }
    setUploadError(null);
  };

  const mediaSelectHandler = (data: MediaTypes) => {
    modalHandler();
    mediaActions
      .fetchMediaById(data?.id)
      .then((res) => {
        if (res && onSelectMedia) {
          onSelectMedia(res);
          setIsMediaSelected(true);
        }
      })
      .catch(() => {
        setIsMediaSelected(false);
      });
  };

  const onTabSelect = (tabName: tabName) => {
    setSelectedTab(tabName);
  };

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      try {
        setIsLoading(true);
        dispatch(clearMediaState());
        const abortableUpload = upload(file, file.name, file.type, undefined);
        uploadRef.current = abortableUpload;
        const response = await abortableUpload.promise;

        abortableUpload.subscribe(
          (progressData) => {
            const percentage = Math.floor(
              (progressData.loaded / progressData.total) * 100,
            );
            setProgress(percentage);
          },
          (error) => {
            setUploadError(error);
            setProgress(0);
          },
          () => {
            const { id, filename } = response as {
              id: string;
              filename: string;
            };
            mediaActions
              .createMedia({ id, filename })
              .then((res) => {
                if (res && onSelectMedia) {
                  const parsedRes = JSON.parse(res);
                  onSelectMedia(parsedRes);
                }
                setProgress(100);
                modalHandler();
              })
              .catch((error) => {
                setUploadError(error);
                return;
              });
          },
        );
      } catch (error) {
        console.warn("Error during file upload:", error);
        setUploadError(error);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      // audio
      "audio/*": [".m4a", ".mp3", ".wav", ".wma", ".aif", ".ogg"],
      "document/doc": [
        ".doc",
        ".docx",
        ".pdf",
        ".xls",
        ".xlsx",
        ".ppt",
        ".pptx",
      ],
      "image/*": [".gif", ".jpeg", ".jpg", ".png", ".webp", ".heic"],
      "video/*": [
        ".mov",
        ".avi",
        ".mp4",
        ".m4v",
        ".mkv",
        ".wmv",
        ".mpg",
        ".mpeg",
        ".webm",
        ".ogv",
        ".3gp",
        ".3gp2",
        ".3g2",
        ".3gpp",
        ".3gpp2",
      ],
    },
  });

  return {
    isLoading,
    uploadError,
    progress,
    selectedTab,
    onTabSelect,
    getRootProps,
    getInputProps,
    isDragActive,
    modalHandler,
    mediaSelectHandler,
    isMediaSelected,
  };
};

export default useFileUpload;
