import React, { useCallback } from "react";
import styles from "../styles.module.scss";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import LoadingContainer from "../LoadingContainer";
import FailPlaceholder from "../FailPlaceholder";
import EditIcon from "../../../../../public/images/edit-icon.svg";
import Trashcan from "../../../../../public/images/trash.svg";

export default function PhotoPreview({
  type,
  uploadPhoto,
  setType,
  result,
  errorMessage,
  setDisplayModal,
  imageLoader,
  uploadProgress,
  imageLink = null,
  customContainerStyle,
}) {
  const { t } = useTranslation();

  const getImageWidth = () => {
    switch (type) {
      case "header":
        return "1250";
      case "thumbnail":
        return "280";
      default:
        return "300";
    }
  };

  const getImageHeight = () => {
    switch (type) {
      case "header":
        return "300";
      case "thumbnail":
        return "280";
      default:
        return "300";
    }
  };

  const imageWidth = getImageWidth();
  const imageHeight = getImageHeight();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) {
        return;
      }
      try {
        await uploadPhoto(type, file);
      } catch (error) {
        console.log(error);
      }
    },
    [type, uploadPhoto, t]
  );

  const getObjectFit = () => {
    if (type === "thumbnail") {
      return undefined;
    }
    if (type === "header") {
      return "cover";
    }
    return "contain";
  };

  const getContainer = () => {
    if (type === "thumbnail") {
      return `${styles.program_border}`;
    }
    if (type === "header") {
      return `${styles.program_header_border}`;
    }
    return `${styles.border_image}`;
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "image/jpg": [],
      "image/jpeg": [],
      "image/png": [],
    },
    multiple: false,
    noKeyboard: true,
    noClick: true,
  });

  let imageComponent;
  if (imageLoader) {
    imageComponent = (
      <LoadingContainer type={type} uploadProgress={uploadProgress} />
    );
  } else if (result !== "fail") {
    imageComponent = (
      <div className={`${getContainer()}`}>
        <img
          src={imageLink}
          alt={type}
          data-testid={type}
          width={imageWidth}
          height={imageHeight}
          style={{ objectFit: getObjectFit() }}
        />
        <div className={styles.line_container}>
          <div
            {...getRootProps()}
            className={styles.edit_wrapper}
            onClick={() => {
              open();
              setType(type);
            }}
          >
            <input {...getInputProps()} />
            <img src={EditIcon} />
          </div>
          <div
            className={styles.trashcan_wrapper}
            onClick={() => {
              setDisplayModal();
              setType(type);
            }}
          >
            <img src={Trashcan} />
          </div>
        </div>
      </div>
    );
  } else {
    imageComponent = (
      <FailPlaceholder type={type} errorMessage={errorMessage} />
    );
  }

  return (
    <div
      className={`${styles.image_wrapper} ${customContainerStyle}`}
      data-testid="photo-preview"
    >
      {imageComponent}
    </div>
  );
}
