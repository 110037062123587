import InfoIcon from "../../../../public/images/circle-info.svg";
import styles from "./styles.module.scss";
import React from "react";

const ToolTip = ({
  text,
  orientation = "left",
  selected = false,
  isError = false,
  children = null,
  showIcon = true,
}) => {
  let orientationStyle;

  if (orientation === "left") {
    orientationStyle = styles.tooltiptext_left;
  } else if (orientation === "right") {
    orientationStyle = styles.tooltiptext_right;
  } else if (orientation === "center") {
    orientationStyle = styles.tooltiptext_center;
  }

  return (
    <div className={styles.tooltip}>
      {showIcon ? (
        isError ? (
          <img
            className={selected ? styles.selected_tooltip : ""}
            style={{
              width: "20px",
              height: "20px",
            }}
            src={InfoIcon}
            alt="info"
          />
        ) : (
          <img
            className={selected ? styles.selected_tooltip : ""}
            style={{
              width: "20px",
              height: "20px",
            }}
            src={InfoIcon}
            alt="info"
          />
        )
      ) : null}
      {children}
      <div
        className={`${styles.tooltiptext} ${orientationStyle} ${
          typeof text === "string" && text.length > 40 ? styles.multiline : ""
        }`}
      >
        {text}
      </div>
    </div>
  );
};

export default ToolTip;
