import Tippy from "@tippyjs/react/headless";
import { Placement } from "@popperjs/core";
import styles from "./styles.module.scss";
import React, { JSXElementConstructor, ReactElement } from "react";

interface Props {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  placement: Placement;
  field_name: string;
  errors: object;
  zIndex?: number;
  low?: boolean;
}

export default function ErrorTippy({
  children,
  placement = "top-end",
  field_name,
  errors,
  zIndex = 9999,
  low = false,
}: Props) {
  return (
    <Tippy
      disabled={!(errors && errors[field_name])}
      visible={errors && errors[field_name]}
      placement={placement}
      zIndex={zIndex}
      render={(attrs) => (
        <div
          data-testid="errorTippy"
          className={low ? styles.container_low : styles.container}
          tabIndex={1}
          {...attrs}
        >
          {errors && errors[field_name] ? errors[field_name].message : ""}
        </div>
      )}
    >
      {children}
    </Tippy>
  );
}
