
import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'app/components/Loading';
import { RootState } from "typedefs";
import Styles from './styles.module.scss';
import edufii_logo_dark from 'app/images/Logo_dark.svg';
import { shallowEqual, useSelector } from "react-redux";

type PropsDef = PropsWithChildren<{
  isLoading?: boolean;
}>;

export default function PublicWrapper(props: PropsDef) {
  let content = props.children;
  if (props.isLoading) {
    content = (<Spinner />);
  }
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  );

  return (
    <div className={Styles.publicWrapper}>
      <div className={Styles.navbar}>
        <div className={Styles.navbarContent}>
          <span className={Styles.logo}>
            <Link to="/">
              <img src={edufii_logo_dark} alt="CoachNow" />
            </Link>
          </span>
          {user != null ?
            (
            <div className={Styles.user_container}>
              <div className={Styles.user_name}>{user.name}</div>
              <img className={Styles.avatar} src={user.avatar} />
            </div>
            )
            :
          null }
        </div>
      </div>

      <div className={Styles.content}>
        <div>{content}</div>
      </div>
    </div>
  );
}
