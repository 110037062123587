import React from "react";
import styles from "./styles.module.scss";
import ButtonViewProgram from "../ButtonViewProgram";

const StudentViewButtons = ({
  program,
  onRegisterClick,
  participantStatus,
}) => {
  const status = program?.registrationStatus;
  const buttonText =
    participantStatus === "" ? "Register" : "Edit Registration";

  if (status === "Registration Closed") {
    return (
      <div className={styles.buttonsContainer}>
        <ButtonViewProgram colorStyle={styles.close_btn} text={"Register"} />
      </div>
    );
  } else if (status?.includes("Registration Opens")) {
    return (
      <div className={styles.buttonsContainer}>
        <ButtonViewProgram colorStyle={styles.close_btn} text={"Register"} />
      </div>
    );
  } else {
    return (
      <div className={styles.buttonsContainer}>
        <ButtonViewProgram
          handleClick={onRegisterClick}
          colorStyle={styles.save_btn}
          text={buttonText}
        />
      </div>
    );
  }
};
export default StudentViewButtons;
