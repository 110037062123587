import { useActions, useSelector } from "app/utils/hooks";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { fetchDemoSpaceAction } from "app/actions/DemoSpace";
import { RootState } from "typedefs";
import { shallowEqual } from "react-redux";

export const DemoSpacesPageLayoutModel = () => {
  const { id: spaceId } = useParams<{ id: string }>();
  const spaceActions = useActions({
    fetchDemoSpaceAction,
  });
  const { selectedDemoSpace, pending } = useSelector(
    (state: RootState) => state.demoSpace,
    shallowEqual,
  );
  const [isScheduledModalOpen, setIsScheduledModalOpen] = useState(false);

  useEffect(() => {
    if (spaceId) {
      spaceActions.fetchDemoSpaceAction(spaceId);
    }
  }, [spaceId, spaceActions]);

  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const onTimelineClick = useCallback(() => {
    route(`/spaces/demo/${spaceId}`);
  }, []);

  const onScheduleClick = useCallback(() => {
    setIsScheduledModalOpen(!isScheduledModalOpen);
  }, [isScheduledModalOpen]);

  const onMembersClick = useCallback(() => {
    route(`/spaces/demo/${spaceId}/members`);
  }, []);

  return {
    selectedDemoSpace,
    pending,
    onTimelineClick,
    onScheduleClick,
    onMembersClick,
    isScheduledModalOpen,
  };
};
