import React from "react";
import CreateReply from "./CreateReply";
import PostModel from "app/models/PostModel";
import styles from "./styles.module.scss";
import PostMediaModal from "../PostMedia/PostMediaView/PostMediaModal";
import { RepliesModel } from "./indexModel";
import Button from "app/components/Button/ButtonVariant";
import PostReportModal from "../PostReportModal";
import Reply from "./Reply";
import { useTranslation } from "react-i18next";
import DemoSpaceFeedPostingForm from "app/components/DemoSpaceFeedPostingForm";

export interface PostRepliesProps {
  post: PostModel;
  onSubmitReply?: (data: PostModel) => void;
  isDemo?: boolean;
  canCreateReply?: boolean;
}

const Replies = ({ post, isDemo, canCreateReply = true }: PostRepliesProps) => {
  const { t } = useTranslation();
  const {
    replies,
    isModalOpen,
    modalHandler,
    selectedMedia,
    fetchMoreReplies,
    replyPending,
    hasMoreReplies,
    onSubmitReply,
    onReport,
    reportModal,
    reportModalClose,
    onDeleteReply,
    onReplyReportClick,
  } = RepliesModel({ post });

  return (
    <div className={styles.container}>
      {replies && replies.length > 0 && (
        <>
          <hr />
          <div className={styles.replies_wrapper}>
            {!isDemo && post.repliesCount > 3 && hasMoreReplies && (
              <Button
                buttonStyles={styles.load_more_button}
                buttonType="secondary-outline"
                title={
                  !replyPending ? (
                    t("Show previous replies")
                  ) : (
                    <i className="dots" />
                  )
                }
                onClickFunc={fetchMoreReplies}
                disabled={replyPending}
              />
            )}
            {replies &&
              replies.length > 0 &&
              replies.map((reply: PostModel) => {
                return (
                  <Reply
                    key={reply.id}
                    reply={reply}
                    post={post}
                    onReplyDelete={onDeleteReply}
                    onReplyReport={onReplyReportClick}
                    isDemo={isDemo}
                  />
                );
              })}
          </div>
        </>
      )}
      {!isDemo && canCreateReply && (
        <CreateReply post={post} onSubmitReply={onSubmitReply} />
      )}
      {isDemo && <DemoSpaceFeedPostingForm label="Say something..." />}
      {isModalOpen && selectedMedia && (
        <PostMediaModal
          onClose={modalHandler}
          isOpen={isModalOpen}
          mediaData={selectedMedia}
        />
      )}
      {reportModal.isOpen && (
        <PostReportModal
          isModalOpen={reportModal.isOpen}
          modalHandler={reportModalClose}
          reportHandler={onReport}
        />
      )}
    </div>
  );
};

export default Replies;
