import Modal from "../Modal";
import styles from "./style.module.scss";
import React from "react";

const TextAreaModal = ({
  title,
  subTitle,
  width,
  textAreaStyle = "",
  placeholder,
  value = null,
  setValue = (_param) => null,
  confirmText,
  cancelText,
  setShowModal,
  handleAction,
  showCloseIcon = false,
  topImage,
  stackedButtons = false,
  confirmFirst = false,
  customConfirmStyle = "",
  customCancelStyle = "",
  hideTextArea = false,
  confirmDisabled = false,
}) => {
  const confirmButton = (
    <button
      disabled={confirmDisabled}
      data-testid="save-edit"
      form="form-details"
      className={customConfirmStyle || styles.confirm_button}
      type="submit"
      onClick={handleAction}
    >
      {confirmText}
    </button>
  );

  const cancelButton = (
    <button
      data-testid="cancel-edit"
      className={customCancelStyle || styles.cancel_button}
      onClick={() => setShowModal(false)}
    >
      {cancelText}
    </button>
  );
  return (
    <Modal
      topImage={topImage}
      showModal={setShowModal}
      title={title}
      showCloseIcon={showCloseIcon}
      width={width}
      cancelButtonProp={cancelButton}
      confirmButton={confirmButton}
      stackedButtons={stackedButtons}
      confirmFirst={confirmFirst}
    >
      {hideTextArea ? (
        <div className={styles.white_container} />
      ) : (
        <div className={styles.white_container}>
          <p>{subTitle}</p>
          <div>
            <textarea
              placeholder={placeholder}
              data-testid="long-description"
              value={value}
              onChange={(event) => setValue(event.target.value)}
              className={`${styles.textarea} ${textAreaStyle}`}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default TextAreaModal;
