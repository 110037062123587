import React, { useState } from "react";
import styles from "./styles.module.scss";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import TeamsSettingsModal from "./TeamsSettingsModal";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import customPrompt from "app/components/inputs/SelectBox/helpers/customPrompt";
import { useActions } from "app/utils/hooks";
import { leaveTeamGroup, getMeForTeams } from "app/actions/team-group";
import { useHistory, useParams } from "react-router-dom";

const TeamsSettings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const leaveAction = useActions({ leaveTeamGroup, getMeForTeams });

  const route = (path: string) => {
    history.push(path);
  };

  const [isTeamsSettingsModalOpen, setIsTeamsSettingsModalOpen] =
    useState(false);

  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );

  const { selectedTeam } = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual,
  );

  const settingsModalHandler = () => {
    setIsTeamsSettingsModalOpen((prev) => !prev);
  };

  const onLeave = () => {
    customPrompt({
      message: t(`Are you sure you would like to leave {{channel}}?.`, {
        channel: selectedTeam?.name,
      }),
      onYes: async () => {
        try {
          const me = await leaveAction.getMeForTeams(selectedTeam?.id);
          if (me?.id) {
            await leaveAction.leaveTeamGroup(selectedTeam?.id, me?.id);
            route(`/groups/${id}`);
          }
        } catch (err) {
          console.error(err);
        }
      },
      onCancel: () => {
        return;
      },
    });
  };

  return (
    <>
      <ul className={styles.dropdown_list}>
        {selectedGroup.permissions.includes("edit") &&
          selectedGroup.planPermissions.includes("edit") && (
            <li onClick={settingsModalHandler}>{t("Settings")}</li>
          )}
        {selectedGroup.permissions.includes("leave") &&
          selectedGroup.planPermissions.includes("leave") && (
            <li className={cls("text-danger")} onClick={onLeave}>
              {t("Leave Channel")}
            </li>
          )}
      </ul>
      {isTeamsSettingsModalOpen && (
        <TeamsSettingsModal
          isOpen={isTeamsSettingsModalOpen}
          onClose={settingsModalHandler}
        />
      )}
    </>
  );
};

export default TeamsSettings;
