import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import SelectedUsers from "app/components/SelectedUser";
import SegmentsSelect from "./SegmentsSelect";
import Button from "app/components/Button/ButtonVariant";
import { useCreateNewSpaceModel } from "../../index.model";
import { useTranslation } from "react-i18next";
import { Explicit } from "app/components/paywalls";
import { fetchLists } from "app/actions/list";
import { useStoreActions } from "app/utils/hooks";

const SelectSpaceList = () => {
  const { t } = useTranslation();

  const { spaces_import, uploadProgress } = useSelector(
    (state: RootState) => state.createSpace,
    shallowEqual,
  );
  const { list } = useStoreActions({ list: () => fetchLists({}) });

  const [showPaywallModal, setShowPaywallModal] = useState(false);

  const onUpgrade = () => {
    setShowPaywallModal(true);
  };

  const closePaywall = useCallback(() => {
    setShowPaywallModal(false);
  }, []);

  const { onCreateSpace, stepBackward, pending, error } =
    useCreateNewSpaceModel();

  useEffect(() => {
    if (
      error &&
      error.statusText &&
      typeof error.statusText === "string" &&
      error.statusText.includes("Payment")
    ) {
      onUpgrade();
    }
  }, [error, onUpgrade]);

  return (
    <div className={styles.container}>
      <div className={styles.panel_title_container}>
        <h4 className={styles.panel_title}>{t("You're almost finished!")}</h4>
        <p>
          {t("You are about to create a new {{sport}} Space for:", {
            sport: spaces_import?.sport_other || "Baseball",
          })}
        </p>
        <SelectedUsers checkIfItsMe users={spaces_import.athletes} />

        {uploadProgress > 0 && (
          <div className="progress">
            <div className="bar" style={{ width: `${uploadProgress}%` }}></div>
            <span className="text">{uploadProgress}%</span>
          </div>
        )}
        {!spaces_import?.create_for_self && list?.data?.length > 0 && (
          <div className={styles.select_template}>
            <p>
              {t(
                "Add this Space to one or more of your lists for improved communication.",
              )}
            </p>
            <SegmentsSelect listData={list?.data ?? []} />
          </div>
        )}
      </div>
      <div className={styles.button_container}>
        <Button
          title={t("Back")}
          buttonType="secondary-outline"
          onClickFunc={stepBackward}
          disabled={pending}
        />
        <Button
          title={t("Create Space")}
          buttonType="primary"
          onClickFunc={onCreateSpace}
          disabled={pending}
        />
      </div>
      <Explicit show={showPaywallModal} onClose={closePaywall} />
    </div>
  );
};

export default SelectSpaceList;
