import styles from "./styles.module.scss";
import React, { useState } from "react";
import LeftArrow from "../../../public/images/back-icon.svg";
import { useHistory } from "react-router-dom";
import PagePanel from "app/components/PagePanel";
import ProgramOptions from "./ProgramOptions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { ProgramState } from "../../reducers/scheduling/program";
import GeneralProgramOptions from "./GeneralSettings";
import Navbar from "../../components/Programs/NavBar";
import PricingOptions from "./Pricing";
import DetailsOptions from "./Details";
import { resetProgramSettings } from "../../actions/scheduling/program";

const BuildProgram = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const programState = useSelector(
    (state: RootState): ProgramState => state.program
  );

  const [header, setHeader] = useState(null);
  const [thumbnail, setThumbanil] = useState(null);
  const [headerChanged, setHeaderChanged] = useState(false);
  const [thumbnailChanged, setThumbnailChanged] = useState(false);

  const handleHeader = (value) => {
    setHeader(value);
    setHeaderChanged(true);
  };

  const handleThumbnail = (value) => {
    setThumbanil(value);
    setThumbnailChanged(true);
  };

  const navigateBack = () => {
    history.goBack();
    dispatch(resetProgramSettings());
  };
  const backButton = () => {
    return (
      <div className={styles.gray_button} onClick={() => navigateBack()}>
        <img
          className={styles.icon}
          style={{
            width: "20px",
            height: "20px",
          }}
          src={LeftArrow}
          alt="info"
        />
      </div>
    );
  };

  const options = [
    { title: "General", isActive: programState.page === 1, code: "general" },
    { title: "Details", isActive: programState.page === 2, code: "details" },
    { title: "Pricing", isActive: programState.page === 3, code: "pricing" },
  ];

  const renderPage = () => {
    switch (programState.page) {
      case 0:
        return <ProgramOptions />;
      case 1:
        return <GeneralProgramOptions />;
      case 2:
        return (
          <DetailsOptions
            setHeader={handleHeader}
            setThumbanil={handleThumbnail}
          />
        );
      case 3:
        return (
          <PricingOptions
            thumbnailChanged={thumbnailChanged}
            headerChanged={headerChanged}
            header={header}
            thumbnail={thumbnail}
          />
        );
    }
    return <GeneralProgramOptions />;
  };
  return (
    <div>
      <PagePanel
        title={`${programState.edit ? "Edit" : "Build a"} ${
          programState.type || "Program"
        }`}
        leftButton={backButton()}
      />
      <div className={styles.empty_container}>
        {programState.page !== 0 && (
          <div className={styles.navbar}>
            <Navbar handleClick={() => null} options={options} isDisabled />
          </div>
        )}
        {renderPage()}
      </div>
    </div>
  );
};

export default BuildProgram;
