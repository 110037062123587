import React, { memo, ReactElement } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import UserAvatar from "../UserAvatar";
import { Link } from "react-router-dom";

interface PagePanelProps {
  panelClassName?: string;
  headerWrapperClassName?: string;
  title?: string;
  subTitle?: string | React.ReactNode;
  avatar?: string;
  titleStyles?: string;
  buttonType?: "primary" | "secondary" | "outline-secondary" | "upgrade";
  buttonTitle?: string | ReactElement;
  buttonStyles?: string;
  onButtonClick?: () => void;
  url?: string;
  headerChildren?: React.ReactNode;
  children?: React.ReactNode;
  leftButton?: React.ReactNode;
}

const PagePanel = ({
  panelClassName,
  title,
  subTitle,
  avatar,
  titleStyles,
  buttonType,
  buttonTitle,
  buttonStyles,
  onButtonClick,
  url,
  headerChildren,
  children,
  leftButton,
}: PagePanelProps) => {
  return (
    <div className={cls(styles.container, panelClassName)}>
      {(title || buttonTitle || leftButton) && (
        <div className={styles.header_container}>
          {(title || subTitle || avatar) && (
            <div className={styles.header_title_container}>
              {leftButton}
              {avatar && (
                <UserAvatar
                  sizeHeight={64}
                  sizeWidth={64}
                  altName="avatar"
                  className={styles.header_logo}
                  srcName={avatar}
                  userName={title}
                />
              )}
              <div className={styles.header_title_wrapper}>
                {title && (
                  <h2 className={cls(styles.title, titleStyles)}>{title}</h2>
                )}
                {subTitle && <p className={styles.sub_title}>{subTitle}</p>}
              </div>
            </div>
          )}
          {buttonTitle && (
            <Link to={url ? url : "#"}>
              <button
                className={cls("btn", styles.button, buttonStyles, {
                  ["btn-primary"]: buttonType === "primary",
                  ["btn-secondary"]: buttonType === "secondary",
                  ["btn-upgrade"]: buttonType === "upgrade",
                  ["btn-outline-secondary"]: buttonType === "outline-secondary",
                })}
                onClick={onButtonClick}
              >
                {buttonTitle}
              </button>
            </Link>
          )}
          {headerChildren}
        </div>
      )}
      {children}
    </div>
  );
};

export default memo(PagePanel);
