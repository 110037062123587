import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { Link, useHistory, useParams } from "react-router-dom";
import { useActions } from "app/utils/hooks";
import {
  editSpaceTemplate,
  fetchSpaceTemplateById,
} from "app/actions/spaceTemplate";
import { fetchConnections } from "app/actions/connection";
import SpaceTemplateUsers from "./SpaceTemplateUsers";
import { useTranslation } from "react-i18next";

const SpaceTemplateMembers = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const spaceTemplateActions = useActions({
    fetchSpaceTemplateById,
    fetchConnections,
    editSpaceTemplate,
  });
  const params = useParams<{ id: string }>();
  const [templateData, setTemplateData] = useState<{
    id?: string;
    name?: string;
    members?: [];
  }>({});

  useEffect(() => {
    spaceTemplateActions.fetchSpaceTemplateById(params?.id).then((res: any) => {
      if (res) {
        setTemplateData(res);
      }
    });

    return () => {
      setTemplateData({});
    };
  }, [params?.id]);

  const onMemberChange = async (e: any) => {
    try {
      const res = await spaceTemplateActions.editSpaceTemplate({
        ...templateData,
        members: e,
        memberIds: (e?.length > 0 && e.map((m: any) => m.id)) || [null],
      });
      if (res) {
        setTemplateData(res);
      }
    } catch (err) {
      history.push("/505");
    }
  };

  const subTitle = (
    <>
      <Link to="/space_templates">{t("Templates")} </Link>/ {templateData?.name}
    </>
  );

  return (
    <div className={styles.container}>
      <PagePanel title={t("Template Members")} subTitle={subTitle} />
      <PagePanel>
        <div className={styles.content_container}>
          <p>{t("Add new member:")}</p>
          <SpaceTemplateUsers
            onChange={onMemberChange}
            selectedUsersList={templateData?.members}
            isCreatable={false}
          />
        </div>
      </PagePanel>
    </div>
  );
};

export default SpaceTemplateMembers;
