import PagePanel from "app/components/PagePanel";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import ReturnBar from "./Navbar/ReturnBar";

import AddPricing from "./Pricing/Modals/AddPricing";
import EditPricingGroup from "./Pricing/Modals/EditPricingGroup";
import PricingView from "./Pricing/PricingView";
import styles from "./styles.module.scss";
import { schedulingFetchGet, schedulingFetchPatch } from "app/utils/request/scheduling/fetch";
import InfoModal from "../Calendar/InfoModal";
import { RootState } from 'app/reducers';
import { useTranslation } from 'react-i18next';
import ConflictModal from "./Pricing/Modals/ConflictModal";

interface LessonType {
  id: number;
  name: string;
}

interface LessonRate {
  id: string | number;
  rate: number | string;
  defaultRate: boolean;
  lessonTypes: LessonType[];
  defaultLessonTypeId: number | null;
  location: string;
  duration: string;
  title: string;
  _destroy?: boolean;
  active: boolean;
}

interface PricingGroups {
  title: string;
  lessonRates: LessonRate[];
  id: string | number;
}

interface InfoPopupContent {
  title: string;
  status: "error" | "success";
  content: string;
  subcontent?: string;
}

export default function Pricing() {
  const { t } = useTranslation();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [pricingGroups, setPricingGroups] = useState<PricingGroups>({
    title: "",
    lessonRates: [],
    id: "",
  });
  const [lessonTypes, setLessonTypes] = useState([]);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [infoPopupContent, setInfoPopupContent] = useState<InfoPopupContent>({
    title: "",
    status: "success",
    content: "",
  });
  const userId = useSelector((state: RootState) => state.user.self.id);
  const [coachProfile, setCoachProfile] = useState(null);

  const [savePricings, setSavePricings] = useState(false);
  const closeEditModal = () => setEditModalOpen(false);
  const openAddModal = () => setAddModalOpen(true);
  const closeAddModal = () => setAddModalOpen(false);
  const [isDefaultOptionDeactivatedModalOopen, setDefaultOptionDeactivatedModalOopen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [conflictModalText, setConflictModalText] = useState(
    (
      <div>
        {t('You are deactivating the default booking option.')}
        <br/>
        {t('Please select an active booking option as your default before saving changes.')}
      </div>
    )
  );

  useEffect(() => {
    const fetchCoachProfile = async () => {
      try {
        const profileData = await schedulingFetchGet(`coach_profiles/${userId}`);
        setCoachProfile(profileData);
      } catch (error) {
        console.error("Error fetching coach profile:", error);
      }
    };
    fetchCoachProfile();
  }, [userId]);


  useEffect(() => {
    setPricingGroups({ title: "", lessonRates: [], id: "" });
    setLessonTypes([]);
    schedulingFetchGet(`coach_profiles/${userId}/pricing_groups`)
      .then((data) => {
        setPricingGroups(data[0] || { title: "", lessonRates: [] });
      })
      .catch((error) => {
        console.error(error);
      });

    schedulingFetchGet('lesson_types')
      .then((data) => {
        const formattedData = data.map((item: any) => ({
          id: item.id,
          name: item.name,
        }));
        setLessonTypes(formattedData);
      }).catch((error) => {
      console.error("Error fetching lesson types:", error);
    });
  }, [savePricings, userId]);

  const handleAddPricing = (newPricing: LessonRate) => {
    const fakeId = `temp-id-${Date.now()}`;
    newPricing.id = fakeId;
    newPricing.active = true;
    if (!checkDefaultRate(pricingGroups.lessonRates)) {
      newPricing.defaultRate = true;
    }
    setPricingGroups((prevPricingGroups) => {
      const updatedLessonRates = Array.isArray(prevPricingGroups.lessonRates)
        ? prevPricingGroups.lessonRates.map(rate => ({
            ...rate,
            defaultRate: newPricing.defaultRate ? false : rate.defaultRate
          }))
        : [];

      updatedLessonRates.push(newPricing);

      return { ...prevPricingGroups, lessonRates: updatedLessonRates };
    });
  };

  const checkDefaultRate = (lessonRates: LessonRate[]) => {
    if (lessonRates === undefined || lessonRates === null) {
      return false;
    }
    if (lessonRates.length === 0) {
      return false;
    }
    const count = lessonRates.filter(rate => rate.defaultRate === true).length;
    if (count === 0) {
      return false;
    }
    return true;
  };

  const handleEditPricing = (updatedItem: LessonRate) => {
    setPricingGroups((prevPricingGroups) => {
      const updatedLessonRates = prevPricingGroups.lessonRates.map((item) =>
        item.id === updatedItem.id
          ? updatedItem
          : { ...item, defaultRate: updatedItem.defaultRate ? false : item.defaultRate }
      );
      if (!checkDefaultRate(updatedLessonRates)) {
        const index = updatedLessonRates.findIndex(obj => obj._destroy !== true);
        if (index >= 0) {
          updatedLessonRates[index].defaultRate = true;
        }
      }
      return { ...prevPricingGroups, lessonRates: updatedLessonRates };
    });
  };

  const handleRemovePricing = (removeItem: LessonRate) => {
    setPricingGroups((prevPricingGroups) => {
      const isFakeId = typeof removeItem.id === 'string' && removeItem.id.startsWith('temp-id-');

      const updatedLessonRates = isFakeId
        ? prevPricingGroups.lessonRates.filter(item => item.id !== removeItem.id)
        : prevPricingGroups.lessonRates.map(item =>
            item.id === removeItem.id ? { ...item, defaultRate: false, _destroy: true } : item
          );
      // Make a new rate the default rate if the deleted rate was default
      if (removeItem.defaultRate === true && prevPricingGroups.lessonRates.length > 1) {
        const index = updatedLessonRates.findIndex(obj => obj.defaultRate === false && obj.id !== removeItem.id);
        updatedLessonRates[index].defaultRate = true;
      }
      return { ...prevPricingGroups, lessonRates: updatedLessonRates };
    });
  };

  const handleEditPricingGroup = (newTitle: string) => {
    setPricingGroups((prevPricingGroups) => ({
      ...prevPricingGroups,
      title: newTitle,
    }));
  };

  const handleSaveChanges = async () => {
    let transformedRates = [];
    if (Array.isArray(pricingGroups.lessonRates) && pricingGroups.lessonRates.length > 0) {
      transformedRates = pricingGroups.lessonRates.map((rate) => {
        const transformedRate = {
          id: typeof rate.id === 'string' && rate.id.startsWith('temp-id-') ? undefined : rate.id,
          rate: rate.rate,
          default_rate: rate.defaultRate,
          lesson_type_ids: rate.lessonTypes.map((type) => type.id),
          default_lesson_type_id: rate.defaultLessonTypeId || null,
          location: rate.location,
          duration: rate.duration,
          title: rate.title,
          active: rate.active,
        };

        if (rate.hasOwnProperty("_destroy")) {
          transformedRate['_destroy'] = rate._destroy;
        }

        return transformedRate;
      });
    }

    const { lessonRates, ...otherProps } = pricingGroups;

    const transformedData = {
      ...otherProps,
      lesson_rates: transformedRates
    };

    schedulingFetchPatch(`coach_profiles/${userId}/pricing_groups/${pricingGroups.id}`, transformedData)
      .then(() => {
        setSavePricings(!savePricings);
        setInfoModalOpen(true);
        setInfoPopupContent({
          title: t("Changes saved"),
          status: "success",
          content: t("Booking Options successfully saved."),
        });
      })
      .catch((error) => {
        console.error("Error updating pricing groups:", error);
      });
  };

  const onDismiss = () => {
    setDefaultOptionDeactivatedModalOopen(false);
  };

  const onConfirm = () => {
    if (currentItem !== null) {
      currentItem.active = !currentItem.active;
      setCurrentItem(null);
    }
    setDefaultOptionDeactivatedModalOopen(false);
  };

  const handleChangeStatus = (updatedItem: LessonRate) => {
    if (updatedItem !== null) {
      setCurrentItem(updatedItem);
      setConflictModalText((
        <div>
          {t('You deactivated all booking options.')}
          <br/>
          {t('To enable athlete bookings, you must have at least one active booking option.')}
        </div>
      ));
    } else {
      setConflictModalText((
        <div>
          {t('You are deactivating the default booking option.')}
          <br/>
          {t('Please select an active booking option as your default before saving changes.')}
        </div>
      ));
    }
    setDefaultOptionDeactivatedModalOopen(true);
  };

  return (
    <>
      <ReturnBar />
      <div>
        <Navbar location={"pricing"} />
        <PagePanel
          title={t("Booking Options")}
          headerChildren={(
            <div className={styles.page_panel}>
              <button
                type="button"
                className={styles.button2}
                onClick={openAddModal}
              >
                <span className={styles.fullText}>+ {t("Add Booking")}</span>
                <span className={styles.shortText}>+ {t("Add")}</span>
              </button>
            </div>
          )}
        >
          <p className={styles.subtitle}>{t('Booking Options represent your coach offerings that can be booked by your athletes.')}</p>
          <p className={styles.subtitle2}>{t('One default booking option is required.')}*</p>
          {Array.isArray(pricingGroups.lessonRates) && pricingGroups.lessonRates.length > 0 ? (
            <PricingView
              list={pricingGroups.lessonRates}
              lessonTypes={lessonTypes}
              onEdit={handleEditPricing}
              onRemove={handleRemovePricing}
              titles={pricingGroups.lessonRates.map(pricingGroup => pricingGroup.title)}
              coachCurrencySymbol={coachProfile?.currencySymbol}
              onChangeStatus={handleChangeStatus}
            />
          ) : (
            <div className={styles.no_bookings}>
              <p>{t("You have not created any booking options yet.")}</p>
              <p>{t("You must create booking options to enable athlete booking.")}</p>
            </div>
          )}

          <button
            type="submit"
            className={styles.submitButton}
            onClick={handleSaveChanges}
          >
            {t("Save changes")}
          </button>
        </PagePanel>
        <EditPricingGroup
          isOpen={isEditModalOpen}
          title={pricingGroups.title}
          onClose={closeEditModal}
          onSave={handleEditPricingGroup}
        />
        <AddPricing
          isOpen={isAddModalOpen}
          onClose={closeAddModal}
          onAddPricing={handleAddPricing}
          lessonTypes={lessonTypes}
          pricingGroups={Array.isArray(pricingGroups.lessonRates) && pricingGroups.lessonRates.map(pricingGroup => pricingGroup.title)}
          coachCurrencySymbol={coachProfile?.currencySymbol}
        />
        <InfoModal isOpen={isInfoModalOpen} title={infoPopupContent.title} status={infoPopupContent.status} onDismiss={() => setInfoModalOpen(false)}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div>
              {infoPopupContent.content}
            </div>
            <div>
              <b> {infoPopupContent.subcontent}</b>
            </div>
          </div>
        </InfoModal>
        <ConflictModal isOpen={isDefaultOptionDeactivatedModalOopen} title={'Deactivate Booking'} onConfirm={onConfirm} onDismiss={onDismiss}>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            {conflictModalText}
          </div>
        </ConflictModal>
      </div>
    </>
  );
}
