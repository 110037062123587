import styles from "./styles.module.scss";
import UserAvatar from "../UserAvatar";
import MoreIcon from "app/images/more.svg";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import SquaredColorOption from "../SquaredColorOption";
import {
  constructCloneProps,
  getColorCode,
  parseDate,
} from "../../../routes/BuildProgram/Pricing/utils";
import ProgramActionMenu from "../ProgramActionMenu";
import { useHistory } from "react-router-dom";
import { setCloneData, setDefaultImages, setId } from "../../../actions/scheduling/program";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { DateTime } from "luxon";

const ProgramItem = (props) => {
  const attributes = props;

  const startTime = DateTime.fromISO(attributes.start, {
    zone: props?.timeZone,
  }).toFormat("MMM d, yyyy");
  const endTime = DateTime.fromISO(attributes.endTime, {
    zone: props?.timeZone,
  }).toFormat("MMM d, yyyy");
  const startDay = DateTime.fromISO(attributes.start, {
    zone: props?.timeZone,
  }).day;
  const dropdownRef = useRef(null);
  const history = useHistory();
  const [displayActions, setDisplayActions] = useState(false);
  const [actionsPosition, setActionsPosition] = useState({
    top: "110%",
    bottom: "",
  });
  const dispatch = useDispatch();
  const handleClickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        props.setDisplayActions(false);
      }
    },
    [props]
  );

  useEffect(() => {
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleActionsClick = (event) => {
    if (event.clientY + 178 >= window.innerHeight) {
      setActionsPosition({ top: "110%", bottom: "20px" });
    }
    event.stopPropagation();
    props.onClick();
    setDisplayActions((prev) => {
      if (props.selected && prev) {
        return false;
      }
      return true;
    });
  };

  const handleClone = (edit) => {
    schedulingFetchGet("/default_images").then((response) => {
      dispatch(setDefaultImages(response));
    });
    schedulingFetchGet(`/programs/${props.id}`).then((result) => {
      const responseAttributes = result;
      const events = responseAttributes.events;
      const timeZone = responseAttributes.timeZone;
      const startTime = responseAttributes.start.slice(11, 16);

      const endTime = responseAttributes.end.slice(11, 16);

      const registrationParsedStartTime = parseDate(
        responseAttributes.registrationStartTime,
        timeZone,
        11,
        16
      );

      const registrationParsedEndTime = parseDate(
        responseAttributes.registrationEndTime,
        timeZone,
        11,
        16
      );

      const untilDate = parseDate(
        new Date(
          events[events.length - 1].timeRange.split("...")[1]
        ).toISOString(),
        timeZone,
        0,
        23
      );
      const privacy = responseAttributes.privacy;
      const attributes = {
        ...result,
        startTime,
        endTime,
        untilDate:
          responseAttributes.attendance !== "clinic" ? untilDate : null,
        registrationParsedStartTime,
        registrationParsedEndTime,
        privacy,
      };

      const cloneState = constructCloneProps(attributes, edit, false);
      dispatch(setCloneData(cloneState));

      history.push(`/programs/build`);
    });
  };

  const handleView = () => {
    let url = `/programs/${props.id}/view`;
    dispatch(setId(props.id));
    if (props.privacy === "public" && !url.includes("/public")) {
      url = url.replace("/programs", "/public/programs");
    }
    history.push(url);
  };

  const checkYear = () => {
    const rightIndex = startDay < 9 ? 5 : 6;
    return startTime.slice(-4) === endTime.slice(-4)
      ? `${startTime.slice(0, rightIndex)} - ${endTime}`
      : `${startTime} - ${endTime}`;
  };

  return (
    <div
      className={`${styles.program_item} ${
        props.isStudent && styles.clickable
      }`}
      onClick={props.isStudent ? handleView : () => null}
    >
      <div onClick={handleView}>
        <UserAvatar
          src={attributes?.externalThumbnailUrl}
          height={45}
          width={45}
          extraContainerStyles={styles.image_container}
        />
      </div>
      <div className={styles.title}>
        <div className={styles.title_container}>
          <div className={styles.title_text} onClick={handleView}>
            <span>{attributes.title}</span>
          </div>
          <div className={styles.subtitle_text}>
            <span>{attributes.subtitle}</span>
          </div>
        </div>
      </div>
      <p className={styles.dates}>
        {startTime === endTime ? startTime : checkYear()}
      </p>
      <div className={props.isStudent ? styles.status_student : styles.status}>
        {props.isActive && (
          <SquaredColorOption
            color={getColorCode(attributes.registrationStatusCode)}
            text={attributes.registrationStatus}
          />
        )}
      </div>
      <div
        className={
          props.isStudent
            ? styles.spots_container_student
            : styles.spots_container
        }
      >
        <p className={styles.spots}>{attributes.spotsBooked}</p>
        <p className={styles.spots_18}>/{attributes.capacity}</p>
      </div>
      {!props.isStudent && (
        <div className={styles.action_container} onClick={handleActionsClick}>
          <img src={MoreIcon} alt="arrow_down" data-testid="arrow-up" />

          {displayActions && props.selected ? (
            <ProgramActionMenu
              isActive={props.isActive}
              dropdownRef={dropdownRef}
              handleClone={handleClone}
              handleView={handleView}
              position={actionsPosition}
              {...props}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ProgramItem;
