import React from "react";
import styles from "./styles.module.scss";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import CoachnowOrangeIcon from "../../../../../../public/images/logo-orange.png";
import Button from "app/components/Button/ButtonVariant";
import ExpandedListContainer from "./listContainer";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  selectedMembers: any;
  selectedTeams: any;
  pending?: boolean;
  onBack?: () => void;
  onConfirm?: () => void;
}

const ConfirmInvitesModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onBack,
  selectedMembers,
  selectedTeams,
  pending,
  onConfirm,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Confirm Invites")}
      className={styles.modal_styles}
    >
      <div className={styles.container}>
        <img
          src={CoachnowOrangeIcon}
          alt="coachnow icon"
          className={styles.group_icon}
        />
        <div className={styles.header_text_wrapper}>
          <p>
            <b>{t("Almost Done.")}</b>
          </p>
          <p>
            {t("Inviting")}{" "}
            {t("count_member", {
              count: selectedMembers?.length ?? 0,
            })}
            {t("to General")}{" "}
            {(selectedTeams.length ?? 0) > 0 ? t("and other channels") : null}
          </p>
        </div>
        <ExpandedListContainer
          members={selectedMembers}
          teamGroups={selectedTeams}
        />
        <div className={styles.btn_wrapper}>
          <Button
            buttonType="secondary-outline"
            title={t("Back")}
            onClickFunc={onBack}
          />
          <Button
            buttonType="primary"
            title={t("Confirm")}
            onClickFunc={onConfirm}
            disabled={selectedMembers?.length === 0 || pending}
          />
        </div>
      </div>
    </ModalLayoutWithTitle>
  );
};

export default ConfirmInvitesModal;
