import styles from "./styles.module.scss";
import reactDom from "react-dom";
import React from "react";
import CloseIcon from "../../../../public/images/close.svg";

export default function CalendarIntegrationModal({
  showModal,
  buttons,
  children,
  closeIcon,
}) {
  const modal = (
    <div className={`${styles.wrapper} modal`}>
      <div className={styles.modal}>
        <div className={styles.header}>
          {closeIcon && (
            <img
              src={CloseIcon}
              className={styles.closeIcon}
              alt="X"
              onClick={showModal}
            />
          )}
        </div>
        {children}
        <div>{buttons}</div>
      </div>
    </div>
  );
  return reactDom.createPortal(modal, document.body);
}
