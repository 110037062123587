import React from "react";
import styles from "./styles.module.scss";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import Button from "app/components/Button/ButtonVariant";
import mailSuccessIcon from "../../../../../../public/images/mail-success-icon.png";
import incompleteIcon from "../../../../../../public/images/incomplete-icon.png";
import coachnowLogo from "../../../../../../public/images/logo-orange.png";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  results?: {
    importedMembersCount?: number;
    skippedMembersCount?: number;
    incompleteMembersCount?: number;
    totalMembersCount?: number;
  };
}

const SuccessImportModal: React.FC<Props> = ({
  results = {},
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const {
    incompleteMembersCount = 0,
    importedMembersCount = 0,
    skippedMembersCount = 0,
    totalMembersCount = 0,
  } = results || {};

  const successfullyInvitedCount = () => {
    if (incompleteMembersCount > 0) {
      return totalMembersCount - (incompleteMembersCount + skippedMembersCount);
    } else {
      return importedMembersCount - incompleteMembersCount;
    }
  };

  const displayResults = () => {
    if (incompleteMembersCount > 0 || skippedMembersCount > 0) {
      return (
        <>
          <div className={styles.result_wrapper}>
            <div className={styles.result_icon_container}>
              <img
                src={mailSuccessIcon}
                alt={t("Mail success icon")}
                className={styles.result_icon}
              />
            </div>
            <div className={styles.result}>
              <p className={styles.result_name}>{t("Invited")}</p>
              <p className={styles.result_description}>
                {successfullyInvitedCount()}{" "}
                {t(successfullyInvitedCount() === 1 ? "member" : "members")}{" "}
                {t("invited successfully.")}
              </p>
            </div>
          </div>
          {incompleteMembersCount > 0 && (
            <div className={styles.result_wrapper}>
              <div className={styles.result_icon_container}>
                <img
                  src={incompleteIcon}
                  alt={t("Incomplete icon")}
                  className={styles.result_icon}
                />
              </div>
              <div className={styles.result}>
                <p className={styles.result_name}>{t("Incomplete")}</p>
                <p className={styles.result_description}>
                  {incompleteMembersCount}{" "}
                  {t(incompleteMembersCount === 1 ? "member" : "members")}{" "}
                  {t("need a valid email to be invited.")}
                </p>
              </div>
            </div>
          )}
        </>
      );
    }

    return (
      <div className={styles.result_wrapper}>
        <img
          src={mailSuccessIcon}
          alt={t("Mail success icon")}
          className={styles.result_icon}
        />
        <div className={styles.result}>
          <p className={styles.result_name}>{t("Invited")}</p>
          <p className={styles.result_description}>
            {t("All members invited successfully.")}
          </p>
        </div>
      </div>
    );
  };

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Invitations Sent")}
    >
      <div className={styles.container}>
        <img
          src={coachnowLogo}
          alt={t("CoachNow logo")}
          className={styles.logo}
        />
        <div className={styles.header_text_wrapper}>
          <p>
            <b>{t("Members Imported")}</b>
          </p>
          <p>
            {importedMembersCount > 0
              ? importedMembersCount
              : totalMembersCount}
            &nbsp;
            {t(totalMembersCount === 1 ? "Member" : "Members")}&nbsp;
            {t(" were imported to your Group. Here are the results.")}
          </p>
        </div>
        <div className={styles.results_container}>{displayResults()}</div>
        <div className={styles.btn_wrapper}>
          <Button
            buttonType="primary"
            title={t("Done")}
            onClickFunc={onClose}
          />
        </div>
      </div>
    </ModalLayoutWithTitle>
  );
};

export default SuccessImportModal;
