import React, { useEffect, memo, useCallback, useState } from "react";
import styles from "./styles.module.scss";
import DropdownButton from "../DropdownButton";
import { cls } from "app/utils";
import TabButton from "../Button/TabButton";
import Notifications from "./Notifications";
import { useActions, useSelector } from "app/utils/hooks";
import {
  getTotalUnseen,
  markAllSeen,
  markAllRead,
} from "app/actions/Notification";
import Invitations from "./Invitations";
import { useHistory } from "react-router-dom";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
import useInterval from "app/utils/hooks/useInterval";
import { shallowEqual } from "react-redux";

type TabNameTypes = "Notifications" | "Invitations";

type TabType = {
  label: TabNameTypes;
  isActive: boolean;
  onClick: () => void;
};

const NotificationsDropdown = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<TabNameTypes>("Notifications");
  const notificationAction = useActions({
    getTotalUnseen,
    markAllSeen,
    markAllRead,
  });

  const { totalUnseen, seenCount } = useSelector(
    (state: RootState) => state.notification,
    shallowEqual,
  );
  const history = useHistory();

  const route = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  const onTabClick = useCallback(
    (tab: TabNameTypes) => {
      setSelectedTab(tab);
    },
    [selectedTab],
  );

  const tabs: TabType[] = [
    {
      label: t("Notifications"),
      isActive: selectedTab === "Notifications",
      onClick: () => onTabClick("Notifications"),
    },
    {
      label: t("Invitations"),
      isActive: selectedTab === "Invitations",
      onClick: () => onTabClick("Invitations"),
    },
  ];

  const updateCount = useCallback(() => {
    notificationAction.getTotalUnseen();
  }, []);

  useInterval(updateCount, 10000);

  useEffect(() => {
    notificationAction.getTotalUnseen();
  }, []);

  const notificationsIcon = useCallback(() => {
    return (
      <i
        className={cls(
          "ico ico-notifications-o activity-notification-icon dark",
          styles.notification_icon,
        )}
      />
    );
  }, []);

  // on dropdown open trigger seen
  const markAllAsSeen = async () => {
    if (seenCount === 0) {
      return;
    }

    await notificationAction.markAllSeen();
    notificationAction.getTotalUnseen();
  };

  const btnContentBadge = () => {
    if (totalUnseen?.total === 0) {
      return null;
    }

    return (
      <p className={cls(styles.counter_badge, styles.desktop)}>
        {totalUnseen?.total}
      </p>
    );
  };

  return (
    <>
      {/* display only for desktop */}
      <DropdownButton
        dropdownWrapperClass={cls(styles.dropdown_wrapper, styles.desktop)}
        btnClassName={cls(styles.dropdown_btn, styles.desktop)}
        btnContent={notificationsIcon()}
        onOpen={() => {
          setSelectedTab("Notifications");
          markAllAsSeen();
        }}
        btnContentBadge={btnContentBadge()}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            {tabs.map((tab: TabType) => (
              // add badge for invitations
              <TabButton
                key={tab.label}
                label={tab.label}
                isActive={tab.isActive}
                onClick={tab.onClick}
                className={styles.tab}
                labelClassName={styles.tab_label}
              />
            ))}
          </div>
          <div className={styles.content_container}>
            {selectedTab === "Notifications" && <Notifications />}
            {selectedTab === "Invitations" && <Invitations />}
          </div>
        </div>
      </DropdownButton>
      {/* display only for mobile */}
      <DropdownButton
        dropdownWrapperClass={cls(styles.dropdown_wrapper, styles.mobile)}
        btnClassName={cls(styles.dropdown_btn, styles.mobile)}
        onOpen={() => route("/notifications")}
        btnContent={notificationsIcon()}
        btnContentBadge={
          totalUnseen?.total > 0 ? (
            <p className={cls(styles.counter_badge, styles.mobile)}>
              {totalUnseen.total}
            </p>
          ) : null
        }
      />
    </>
  );
};

export default memo(NotificationsDropdown);
