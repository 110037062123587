import { useEffect, useState } from "react";
import { isNil } from "lodash";
import {
  CoachProfileModel,
  CoachProfileResponse,
} from "app/models/scheduling/CoachProfileModel";


interface IndexedCoaches {
  [id: string]: CoachProfileModel[];
}

const parseCoaches = (coaches: CoachProfileResponse[]): IndexedCoaches => {
  if (isNil(coaches)) {
    return {};
  }

  const indexedCoaches: IndexedCoaches = {};
  coaches
    .sort((a, b) => a.orderNumber - b.orderNumber)
    .forEach((coach) => {
      if (!indexedCoaches[coach.facilityId]) {
        indexedCoaches[coach.facilityId] = [];
      }
      indexedCoaches[coach.facilityId].push(new CoachProfileModel(coach));
    });

  return indexedCoaches;
};

interface BookLessonData {
  coaches: Record<string, any>;
}

export default function useProgramData(fetcher: () => Promise<[CoachProfileResponse[]]>, isPublicPage = false): [boolean, BookLessonData, boolean] {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [bookLessonData, setBookLessonData] = useState({
    coaches: {},
  });

  useEffect(() => {
    setLoading(true);
    fetcher()
      .then((data: [CoachProfileResponse[]]) => {
        if (!data) {
          return;
        }
        const [coaches] = data;
        const parsedCoaches = parseCoaches(coaches);
        // @ts-ignore:next-line
        const coachModels = Object.values(parsedCoaches).flat();

        setBookLessonData({
          coaches: parsedCoaches,
        });

        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [fetcher, isPublicPage]);

  return [loading, bookLessonData, error];
}
