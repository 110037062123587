import React from "react";
import UserAvatar from "app/components/UserAvatar";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import UserModel from "app/models/UserModel";

interface Props {
  membersList?: UserModel[];
  selectedMembers?: UserModel[];
  onSelectHandler?: (id: string, checked: boolean) => void;
  sentryRef?: any;
}

const MembersList = ({
  membersList,
  selectedMembers,
  onSelectHandler,
  sentryRef,
}: Props) => {
  return (
    membersList?.length > 0 && (
      <div className={cls(styles.form_wrapper, "stack-list")}>
        <ul className={cls(styles.list_container, "stack-list-items")}>
          {membersList?.map((user: UserModel) => (
            <li
              key={user.id}
              className={cls(
                "stack-list-item selectable-item selectable-item-middle",
                {
                  ["active"]: selectedMembers.find((m) => m.id === user.id),
                }
              )}
            >
              <label
                className="selectable-item-trigger"
                htmlFor={user.id}
              ></label>
              <div className="selectable-item-control">
                <div className="checkbox">
                  <input
                    id={user.id}
                    onChange={(e) => onSelectHandler(user.id, e.target.checked)}
                    type="checkbox"
                  />
                  <label htmlFor={user.id}></label>
                </div>
              </div>

              <div className={styles.details_container}>
                <UserAvatar
                  altName="avatar"
                  className={styles.avatar}
                  sizeHeight={44}
                  sizeWidth={44}
                  userName={user.name}
                  srcName={user.avatar as string}
                />
                <p className={styles.user_name}>{user.name}</p>
              </div>
            </li>
          ))}
          <div ref={sentryRef} />
        </ul>
      </div>
    )
  );
};

export default MembersList;
