import { declineInvitation, fetchInvitations } from "app/actions/invitation";
import InvitationModel from "app/models/InvitationModel";
import { useActions } from "app/utils/hooks";
import { notificationSortDate } from "app/utils/notificationDate";
import { useCallback, useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

type GroupNotificationTypes = {
  today: InvitationModel[];
  thisWeek: InvitationModel[];
  thisMonth: InvitationModel[];
  everythingElse: InvitationModel[];
};

export const InvitationsListModel = () => {
  const invitationAction = useActions({ fetchInvitations, declineInvitation });
  const [invitationQuery] = useState<{ [key: string]: any }>({ perPage: 50 });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState(true);
  const [invitedInvitationsCount, setInvitedInvitationsCount] =
    useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [incomingInvitations, setIncomingInvitations] = useState<{
    today: InvitationModel[];
    thisWeek: InvitationModel[];
    thisMonth: InvitationModel[];
    everythingElse: InvitationModel[];
  }>({
    today: [],
    thisWeek: [],
    thisMonth: [],
    everythingElse: [],
  });

  const groupNotificationsByDate = useCallback(
    (notifications: InvitationModel[]) => {
      const grouped: GroupNotificationTypes = {
        today: [],
        thisWeek: [],
        thisMonth: [],
        everythingElse: [],
      };

      notifications?.forEach((notification: InvitationModel) => {
        const label = notificationSortDate(notification.createdAt);
        if (label === "Today") {
          grouped.today.push(notification);
        } else if (label === "This Week") {
          grouped.thisWeek.push(notification);
        } else if (label === "This Month") {
          grouped.thisMonth.push(notification);
        } else {
          grouped.everythingElse.push(notification);
        }
      });

      return grouped;
    },
    [],
  );

  const loadData = useCallback(
    async (fetchPage?: number) => {
      setLoading(true);
      const res: InvitationModel[] = await invitationAction.fetchInvitations({
        ...invitationQuery,
        page: fetchPage ? fetchPage : currentPage,
      });
      setLoading(false);

      if (fetchPage) {
        setCurrentPage(fetchPage);
      }

      if (!res || res?.length < invitationQuery.perPage) {
        setHasMore(false);
      }

      if (res.length > 0) {
        const newGroupedNotifications = groupNotificationsByDate(res);
        setInvitedInvitationsCount(res.length);
        if (fetchPage) {
          setCurrentPage(fetchPage + 1);
        } else {
          setCurrentPage((prev) => prev + 1);
        }
        setIncomingInvitations((prev) => ({
          today: [...prev.today, ...newGroupedNotifications.today],
          thisWeek: [...prev.thisWeek, ...newGroupedNotifications.thisWeek],
          thisMonth: [...prev.thisMonth, ...newGroupedNotifications.thisMonth],
          everythingElse: [
            ...prev.everythingElse,
            ...newGroupedNotifications.everythingElse,
          ],
        }));
      }
    },
    [currentPage, invitationQuery, incomingInvitations],
  );

  const [notificationsContainer] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadData,
    disabled: false,
    rootMargin: "0px 0px 200px 0px",
  });

  const resetStates = () => {
    setCurrentPage(1);
    setHasMore(true);
    setInvitedInvitationsCount(0);
    setLoading(false);
    setIncomingInvitations({
      today: [],
      thisWeek: [],
      thisMonth: [],
      everythingElse: [],
    });
  };

  const onDeclineInvite = useCallback(async (inviteId: string) => {
    await invitationAction.declineInvitation(inviteId);
    resetStates();
    loadData(1);
  }, []);

  useEffect(() => {
    loadData();

    return () => {
      setCurrentPage(1);
      setHasMore(true);
      setInvitedInvitationsCount(0);
      setLoading(false);
      setIncomingInvitations({
        today: [],
        thisWeek: [],
        thisMonth: [],
        everythingElse: [],
      });
    };
  }, []);

  return {
    notificationsContainer,
    loading,
    incomingInvitations,
    invitedInvitationsCount,
    onDeclineInvite,
  };
};
