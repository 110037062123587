import React from "react";
import { Link } from "react-router-dom";
import classNames from "app/utils/classNames";
import styles from "./styles.module.scss";
import { SearchAllModel } from "app/models/SearchAllModel";
import pdfIcon from "../../../../../public/images/ico_pdf.png";
import docIcon from "../../../../../public/images/ico_doc.png";
import xlsIcon from "../../../../../public/images/ico_xls.png";
import pptIcon from "../../../../../public/images/ico_ppt.png";
import audioIcon from "../../../../../public/images/ico_audio.png";
import TagModel from "app/models/TagModel";

export interface SearchCardPropsDef {
  searchValue: SearchAllModel;
}

const fileIcons = {
  docx: docIcon,
  pdf: pdfIcon,
  xlsx: xlsIcon,
  xls: xlsIcon,
  xlxx: xlsIcon,
  pptx: pptIcon,
};

const SearchCard = ({ searchValue }: SearchCardPropsDef) => {
  const renderFileIcon = (extension: any) => {
    const icon = fileIcons[extension];
    return icon ? (
      <img src={icon} alt={`${extension} icon`} className={styles.image} />
    ) : null;
  };

  const renderTags = (tags: TagModel[]) =>
    tags ? (
      <div className={styles.team_name}>
        {/* {tags?.map((tag) => `#${tag}`).join(" ")} */}
        {Array.isArray(searchValue.tags) &&
          searchValue.tags.map((tag) => `#${tag}`).join(" ")}
      </div>
    ) : null;

  const renderContent = () => {
    switch (searchValue?.object) {
      case "team_group":
        return (
          <Link
            to={`/groups/${searchValue?.team?.id}/channels/${searchValue?.id}`}
            className={styles.searchCard}
          >
            <img
              src={searchValue?.team?.logo}
              alt="Team Logo"
              className={classNames(styles.image, styles.circle)}
            />
            <div className={styles.searchCardInfo}>
              <h2>
                <div className={styles.name}>{searchValue?.name}</div>
                {searchValue?.team && (
                  <div className={styles.team_name}>
                    {searchValue?.team?.name}
                    {renderTags(searchValue?.tags)}
                  </div>
                )}
              </h2>
            </div>
          </Link>
        );

      case "note":
        return (
          <Link to={`/posts/${searchValue?.id}`} className={styles.searchCard}>
            <div className={styles.note}>Aa</div>
            <div className={styles.searchCardInfo}>
              <h2>
                <div className={styles.name}>{searchValue?.details}</div>
                <div className={styles.team_name}>{searchValue?.details}</div>
                {renderTags(searchValue?.tags)}
              </h2>
            </div>
          </Link>
        );

      case "datafile":
        return (
          <Link to={`/posts/${searchValue.id}`} className={styles.searchCard}>
            {renderFileIcon(searchValue.datafile.extension)}
            <div className={styles.searchCardInfo}>
              <h2>
                <div className={styles.name}>{searchValue.details}</div>
                <div className={styles.team_name}>{searchValue.details}</div>
                {renderTags(searchValue.tags)}earchValue.tags
              </h2>
            </div>
          </Link>
        );

      case "audio":
        return (
          <div className={styles.searchCard}>
            <img src={audioIcon} alt="Audio icon" className={styles.image} />
            <div className={styles.searchCardInfo}>
              <h2>
                <div className={styles.name}>{searchValue.details}</div>
                <div className={styles.team_name}>{searchValue.details}</div>
                {renderTags(searchValue.tags)}
              </h2>
            </div>
          </div>
        );

      case "video":
        return (
          <div className={styles.searchCard}>
            <img
              src={searchValue.video.smallThumbnailUrl}
              alt="Video thumbnail"
              className={classNames(styles.image, styles.radius)}
              onClick={() =>
                (window.location.href = `/posts/${searchValue.id}`)
              }
            />
            <div className={styles.searchCardInfo}>
              <h2>
                <Link to={`/posts/${searchValue.id}`} className={styles.name}>
                  {searchValue.details}
                  {renderTags(searchValue.tags)}
                </Link>
                <span className={styles.team_name}>{searchValue.details}</span>
              </h2>
            </div>
          </div>
        );

      case "image":
        return (
          <div className={styles.searchCard}>
            <img
              src={searchValue.image.smallUrl}
              alt="Image thumbnail"
              className={classNames(styles.image, styles.radius)}
            />
            <div className={styles.searchCardInfo}>
              <h2>
                <Link to={`/posts/${searchValue.id}`} className={styles.name}>
                  {searchValue.details}
                  {searchValue?.tags && renderTags(searchValue.tags)}
                </Link>
                <span className={styles.team_name}>{searchValue.details}</span>
              </h2>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return renderContent();
};

export { SearchCard };
