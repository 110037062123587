import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import AudioPlayer from "app/components/AudioPlayer";
import playIcon from "../../../../../public/images/play-overlay.png";
import Datafile from "app/components/Datafile";
import { fetchMediaById } from "app/actions/media-library-data";
import { useActions } from "app/utils/hooks";
import PostMediaModal from "./PostMediaView/PostMediaModal";

interface Props {
  mediaData: any;
  // onClick: () => void;
}

export const PostMedia = ({ mediaData }: Props) => {
  const mediaActions = useActions({ fetchMediaById });

  const [postMedia, setPostMedia] = useState(mediaData);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalHandler = () => {
    setIsModalOpen((prev) => !prev);
  };

  useEffect(() => {
    const fetchMedia = () => {
      if (postMedia?.id && !postMedia?.processed) {
        mediaActions.fetchMediaById(postMedia?.id).then((res) => {
          if (res?.processed) {
            setPostMedia(res);
            console.log("res", res);
          }
        });
      }
    };

    let pollingInterval: any;

    pollingInterval = setInterval(() => {
      fetchMedia();

      if (postMedia?.processed) {
        clearInterval(pollingInterval);
      }
    }, 5000);

    return () => {
      clearInterval(pollingInterval);
    };
  }, [postMedia]);

  const renderVideoThumbnail = () => {
    if (!postMedia?.processed) {
      return (
        <div className={styles.video_container}>
          <div className="media-sprite media-sprite-md media-sprite-vid" />
        </div>
      );
    } else {
      return (
        <div className={styles.video_container}>
          <span className={styles.mediaOverlay}>
            <img alt="play" src={playIcon} />
          </span>
          <img
            className={styles.thumbnail}
            src={postMedia?.thumbnails?.default}
            alt="media"
          />
        </div>
      );
    }
  };

  const renderMedia = () => {
    if (postMedia?.type === "image" || postMedia?.type === "video") {
      return (
        <div
          className={styles.preview_container}
          onClick={postMedia?.processed ? modalHandler : undefined}
        >
          {postMedia?.type === "image" && (
            <img
              className={styles.thumbnail}
              src={postMedia?.originalUrl}
              alt="media"
            />
          )}
          {postMedia?.type === "video" && renderVideoThumbnail()}
        </div>
      );
    }

    if (postMedia?.type === "datafile") {
      return (
        <a
          className={cls(styles.datafile_container)}
          href={postMedia?.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Datafile title={postMedia?.filename} type={postMedia?.extension} />
        </a>
      );
    }

    if (postMedia?.type === "audio") {
      return <AudioPlayer data={postMedia} />;
    }

    return null;
  };

  return (
    <>
      {renderMedia()}
      <PostMediaModal
        onClose={modalHandler}
        isOpen={isModalOpen}
        mediaData={postMedia}
      />
    </>
  );
};
