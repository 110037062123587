import { useEffect, useState } from "react";
import Papa from "papaparse";
import * as XLSX from "xlsx";

interface PlayerData {
  email: string;
  name: string;
  displayName: string;
  avatar: string | null;
}

interface UseFileParserProps {
  file: File | null;
}

export const useSpaceCsvUploadParser = ({ file }: UseFileParserProps) => {
  const [data, setData] = useState<PlayerData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (file) {
      const fileType = file.name.split(".").pop()?.toLowerCase();
      setIsLoading(true);
      if (fileType === "csv") {
        handleCSVUpload(file);
      } else if (fileType === "xls" || fileType === "xlsx") {
        handleXLSXUpload(file);
      } else {
        setError("Unsupported file format. Please upload a CSV or XLSX file.");
        setIsLoading(false);
      }
    }
  }, [file]);

  const handleCSVUpload = (file: File) => {
    Papa.parse(file, {
      complete: (result) => {
        const rawData = result.data as string[][];
        const cleanedData = sanitizeData(rawData);
        setData(cleanedData);
        setIsLoading(false);
      },
      error: () => {
        setError("Error parsing CSV file.");
        setIsLoading(false);
      },
    });
  };

  const handleXLSXUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const rawData: string[][] = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          defval: "",
        });

        const cleanedData = sanitizeData(rawData);
        setData(cleanedData);
      } catch (err) {
        setError("Error parsing XLSX file.");
      }
      setIsLoading(false);
    };
    reader.readAsArrayBuffer(file);
  };

  const sanitizeData = (rawData: string[][]): PlayerData[] => {
    const cleanedData: PlayerData[] = [];

    rawData.forEach((row) => {
      const displayName = row[0]?.trim() || "";
      const name = row[0]?.trim() || "";
      const email = row[1]?.trim() || "";
      const avatar = null;

      if (email || name || displayName) {
        cleanedData.push({ email, name, displayName, avatar });
      }
    });

    return cleanedData;
  };

  return { data, error, isLoading };
};
