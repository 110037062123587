import React, { useEffect, useState } from "react";
import { useActions, useSelector } from "app/utils/hooks";
import {
  fetchSegmentById,
  clearSegments,
  editSegment,
} from "app/actions/segments";
import { RootState } from "typedefs";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import Button from "app/components/Button/ButtonVariant";
import { cls } from "app/utils";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { shallowEqual } from "react-redux";

const EditSegment = () => {
  const { t } = useTranslation();

  const { segmentId } = useParams<{ segmentId: string }>();

  const segmentActions = useActions({
    fetchSegmentById,
    clearSegments,
    editSegment,
  });

  const history = useHistory();

  const { segmentsData, segmentsPending } = useSelector(
    (state: RootState) => state.segments,
    shallowEqual,
  );

  const [name, setName] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (segmentId) {
          const res = await segmentActions.fetchSegmentById(segmentId);
          if (res) {
            setName(res.name);
          }
        }
      } catch (err) {
        setError(true);
        history.goBack();
      }
    };

    fetchData();

    return () => {
      segmentActions.clearSegments();
      setError(false);
      setName(null);
    };
  }, [segmentId, history]);

  const onChangeHandler = (e: any) => {
    setName(e);
    if (e.length <= 0) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const updateHandler = async () => {
    try {
      await segmentActions.editSegment(segmentId, {
        ...segmentsData,
        name,
      });
      history.goBack();
    } catch (error) {
      console.error("Failed to update segment:", error);
    }
  };

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <PagePanel title={t("Edit List")} />
        <div className={styles.content_wrapper}>
          <span className={styles.input_wrapper}>
            <label htmlFor="segment_name">{t("Name")}</label>
            <input
              disabled={segmentsPending}
              id="segment_name"
              name="segment_name"
              className={cls(styles.input, {
                [styles.error]: error,
                ["disabled"]: segmentsPending,
              })}
              onChange={(e) => onChangeHandler(e.target.value)}
              defaultValue={name}
            />
          </span>
          <span className={styles.btn_wrapper}>
            <Button
              title={t("Update")}
              buttonType="primary"
              disabled={error || segmentsPending}
              onClickFunc={updateHandler}
            />
            <Button
              title={t("Cancel")}
              buttonType="secondary-outline"
              onClickFunc={() => history.goBack()}
            />
          </span>
        </div>
      </div>
    </>
  );
};

export default EditSegment;
