import React from "react";
import ViewProgram from "app/routes/ViewProgram";
import {useSelector} from "react-redux";
import {RootState} from "typedefs";
import UserModel from "app/models/UserModel";
import SiteWrapper from "app/components/Layouts/SiteWrapper";

const PublicProgram = () => {
  const userState = useSelector(
    (state: RootState): UserModel => state.user.data
  );

  if(userState) {
    return <SiteWrapper><ViewProgram isPublic={true} /></SiteWrapper>;
  } else {
    return <ViewProgram isPublic={true} />;
  }
};

export default PublicProgram;
