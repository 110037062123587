import React from "react";
import { DateTime } from "luxon";

import DatePicker from "app/components/DatePicker";

import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { cls } from "app/utils";

type PropsDef = {
  date: DateTime;
  onNext: () => void;
  onPrev: () => void;
  onDateChange: (DateTime) => void;
  handleCalendarSettings: () => void;
  initNewEvent: () => void;
  calendarView: string;
};

const formatDate = (date: DateTime, calendarView: string) => {
  let startOfWeek = date;
  let endOfWeek: DateTime = date.plus({ day: 6 });

  if (calendarView === "timeGridNarrow") {
    if (startOfWeek.weekday !== 7) {
      startOfWeek = startOfWeek.minus({ days: startOfWeek.weekday });
    }

    endOfWeek = startOfWeek.plus({ days: 6 });
  }

  const startMonth = startOfWeek.toLocaleString({ month: "long" });
  const startYear = startOfWeek.toLocaleString({ year: "numeric" });

  let dateLabel;

  if (startOfWeek.month === endOfWeek.month) {
    dateLabel = `${startMonth} ${startYear}`;
  } else {
    if (startOfWeek.year === endOfWeek.year) {
      dateLabel = `${startOfWeek.toLocaleString({ month: "short" })} - ${endOfWeek.toLocaleString({ month: "short", year: 'numeric' })}`;
    } else {
      dateLabel = `${startOfWeek.toLocaleString({ month: "short", year: 'numeric' })} - ${endOfWeek.toLocaleString({ month: "short", year: 'numeric' })}`;
    }
  }

  return dateLabel;
};

export default function CalendarToolbar(props: PropsDef) {
  const {
    date,
    onNext,
    onPrev,
    onDateChange,
    handleCalendarSettings,
    initNewEvent,
    calendarView,
  } = props;
  const { t } = useTranslation();

  if (!date) {
    return null;
  }
  return (
    <div className={styles.calendar_toolbar_container}>
      <div className={styles.toolbar_left}>
        <div className={styles.toolbar_date}>
          <DatePicker
            date={date}
            setDate={onDateChange}
            formatDate={(date) => formatDate(date, calendarView)}
          />
        </div>
      </div>

      <div className={styles.toolbar_right}>
        <button
          className={cls(
            styles.toolbar_button_left,
            "btn",
            "btn-outline-secondary"
          )}
          style={{ transform: "rotate(90deg)" }}
          onClick={onPrev}
        >
          <span className={styles.arrow_container}>
            <i className="ico ico-chevron" />
          </span>
        </button>
        <button
          onClick={() => onDateChange(DateTime.now())}
          type="button"
          className={cls(styles.button_today, "btn btn-outline-primary")}
        >
          {t("Today")}
        </button>
        <button
          className={cls(
            styles.toolbar_button_right,
            "btn",
            "btn-outline-secondary"
          )}
          style={{ transform: "rotate(-90deg)" }}
          onClick={onNext}
        >
          <span className={styles.arrow_container}>
            <i className="ico ico-chevron" />
          </span>
        </button>

        <div className={styles.divider} />

        <button
          onClick={initNewEvent}
          type="button"
          className={cls(styles.button_primary, "btn btn-primary")}
        >
          + {t("Add")}
        </button>

        <button
          type="button"
          className={cls(
            styles.page_panel_settings_button,
            styles.toolbar_settings,
            "btn",
            "btn-outline-secondary"
          )}
          onClick={handleCalendarSettings}
        >
          <i className={cls("ico ico-cog", styles.icon_settings)} />
        </button>
      </div>
    </div>
  );
}
