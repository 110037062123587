import React, { useState } from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";

import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import SpaceSettingsModal from "./SpaceSettingsModal";
import TransferSpaceModal from "./TransferSpaceModal";
import SpaceNotificationsModal from "./SpaceNotificationsModal";
import customPrompt from "../inputs/SelectBox/helpers/customPrompt";
import { useTranslation } from "react-i18next";
import { useActions } from "app/utils/hooks";
import { deleteMemberFromSpace } from "app/actions/space";
import { useHistory, useParams } from "react-router-dom";

const SpaceSettings = () => {
  const { t } = useTranslation();
  const { id: spaceId } = useParams<{ id: string }>();
  const history = useHistory();

  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);
  const [isSpaceSettingsModalOpen, setIsSpaceSettingsModalOpen] =
    useState(false);
  const [isTransferSpaceModalOpen, setIsTransferSpaceModalOpen] =
    useState(false);
  const { selectedSpace } = useSelector(
    (state: RootState) => state.space,
    shallowEqual,
  );
  const leaveAction = useActions({ deleteMemberFromSpace });

  const { self } = useSelector((state: RootState) => state.user);

  const route = (path: string) => {
    history.push(path);
  };

  const notificationsModalHandler = () => {
    setIsNotificationsModalOpen((prev) => !prev);
  };

  const settingsModalHandler = () => {
    setIsSpaceSettingsModalOpen((prev) => !prev);
  };

  const transferSpaceModalHandler = () => {
    if (isSpaceSettingsModalOpen) {
      setIsSpaceSettingsModalOpen(false);
    }
    setIsTransferSpaceModalOpen((prev) => !prev);
  };

  const onLeave = () => {
    customPrompt({
      message: t(
        `Are you sure you would like to leave {{athleteName}}'s space?`,
        {
          athleteName: selectedSpace.athleteName,
        },
      ),
      onYes: () => {
        leaveAction
          .deleteMemberFromSpace(spaceId, self?.id)
          .then(() => route("/spaces"));
      },
      onCancel: () => {
        return;
      },
    });
  };

  return (
    <>
      <ul className={styles.dropdown_list}>
        {selectedSpace.permissions.includes("edit") &&
          selectedSpace.planPermissions.includes("edit") && (
            <li onClick={settingsModalHandler}>{t("Space Settings")}</li>
          )}
        <li onClick={notificationsModalHandler}>{t("Your Notifications")}</li>
        {selectedSpace.permissions.includes("leave") &&
          selectedSpace.planPermissions.includes("leave") && (
            <li className={cls("text-danger")} onClick={onLeave}>
              {t("Leave Space")}
            </li>
          )}
      </ul>
      {isNotificationsModalOpen && (
        <SpaceNotificationsModal
          isModalOpen={isNotificationsModalOpen}
          modalHandler={notificationsModalHandler}
        />
      )}
      {isSpaceSettingsModalOpen && (
        <SpaceSettingsModal
          isModalOpen={isSpaceSettingsModalOpen}
          modalHandler={settingsModalHandler}
          transferSpaceModalHandler={transferSpaceModalHandler}
        />
      )}
      {isTransferSpaceModalOpen && (
        <TransferSpaceModal
          isModalOpen={isTransferSpaceModalOpen}
          modalHandler={transferSpaceModalHandler}
        />
      )}
    </>
  );
};

export default SpaceSettings;
