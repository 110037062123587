/**
 * @module Model.CoachProfileModel
 *
 */
import { Model } from "typedefs";
import { LessonRateModel } from "./LessonRateModel";

export interface CoachProfileResponse extends Model {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  timeZone: string;
  externalId: string;
  facilityExternalId: string;
  calendarUuid: string;
  orderNumber: number;
  currencySymbol: string;
  currency: string;
  profilePicture: string | null;
}

export class CoachProfileModel implements Model {
  id: string;
  email: string;
  lessonRates: LessonRateModel[];
  timeZone: string;
  externalId: string;
  facilityExternalId: string;
  facilityId: string;
  orderNumber: number;
  calendarSubscriptionUrl: string;
  currencySymbol: string;
  currency: string;
  firstName: string;
  lastName: string;
  profilePicture: string | null;
  fullName: string;
  calendarUuid: string;

  constructor(coachProfile: CoachProfileResponse) {
    this.id = coachProfile.id;
    this.firstName = coachProfile.firstName;
    this.lastName = coachProfile.lastName;
    this.email = coachProfile.email;
    this.timeZone = coachProfile.timeZone;
    this.externalId = coachProfile.externalId;
    this.facilityExternalId = coachProfile.facilityExternalId;
    this.facilityId = coachProfile.facilityId;
    this.orderNumber = coachProfile.orderNumber;
    this.calendarSubscriptionUrl = coachProfile.calendarSubscriptionUrl;
    this.currencySymbol = coachProfile.currencySymbol;
    this.currency = coachProfile.currency;
    this.profilePicture = coachProfile.profilePicture;
    this.fullName = `${this.firstName} ${this.lastName}`;
    this.calendarUuid = coachProfile.calendarUuid;

    this.lessonRates = [];
    if (coachProfile.lessonRates && coachProfile.lessonRates.length > 0) {
      this.lessonRates = coachProfile.lessonRates.map(
        (lessonRate: any) => new LessonRateModel(lessonRate)
      );
    }
  }

  get defaultLessonRate(): LessonRateModel | null {
    if (!this.lessonRates || this.lessonRates.length === 0) {
      return null;
    }

    return this.lessonRates.find(lr => lr.defaultRate) || this.lessonRates[0];
  }
}
