import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import UserAvatar from "app/components/UserAvatar";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import Button from "app/components/Button/ButtonVariant";
import { useActions } from "app/utils/hooks";
import { clear, fetchTimezone } from "app/actions/timezone";
import AccountPhoneVerificationModal from "app/components/AccountPhoneVerificationModal";
import upload from "app/utils/uploader";
import { editProfileSettings, fetchSelf } from "app/actions/user";
import AccountWrap from "..";
import { Toast } from "app/components/helpers";
import UserModel from "app/models/UserModel";
import i18n from "app/i18n";

const ProfileEdit = () => {
  const { t } = useTranslation();
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const timezones = useSelector(
    (state: RootState) => state.timezone.data,
    shallowEqual
  );
  const timeZoneAction = useActions({ fetchTimezone, clear });
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState<boolean>(false);
  const [userState, setUserState] = useState<UserModel>({
    avatar: self?.avatar,
  });
  const [uploadedAvatar, setUploadedAvatar] = useState(null);
  const [toastAlert, setToastAlert] = useState(null);

  const languages = useMemo(
    () => [
      { label: t("English"), value: "en" },
      { label: t("Japanese"), value: "ja" },
    ],
    [i18n.language]
  );


  useEffect(() => {
    timeZoneAction.fetchTimezone();
    return () => {
      timeZoneAction.clear();
    };
  }, []);

  const genders = [
    { label: t("Male"), value: "male" },
    { label: t("Female"), value: "female" },
  ];

  // const roles = [
  //   { label: t("Coach"), value: "coach" },
  //   { label: t("Athlete"), value: "athlete" },
  //   { label: t("Parent / Fan"), value: "other" },
  // ];
  function timeZoneOffset(timezoneOffset: number) {
    const decimal = timezoneOffset / 3600;
    const sign = decimal < 0 ? "-" : "+";
    const hour = Math.floor(Math.abs(decimal));
    const min = Math.floor((Math.abs(decimal) * 60) % 60);

    return (
      sign + (hour < 10 ? "0" : "") + hour + ":" + (min < 10 ? "0" : "") + min
    );
  }

  const handlePhoneModal = () => {
    setIsPhoneModalOpen((prev) => !prev);
  };

  const fileInputRef = React.useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event.target.files[0];
      const filename = file.name;
      const type = "avatar";

      const uploadResult = await upload(file, filename, type, "avatar");
      setUploadedAvatar(uploadResult.url);
      setUserState({
        ...userState,
        avatar: uploadResult.url,
        avatar_id: uploadResult.id,
        remove_avatar: false,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const updateUserData = ({
    type,
    value,
  }: {
    type: string;
    value: string | boolean;
  }) => {
    setUserState({ ...userState, [type]: value });
  };

  const UploadNewMediaRender = () => {
    return (
      <>
        <input
          type="file"
          accept="image/*"
          className={styles.file_uploader_input}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <Button
          title={t("Upload New Avatar")}
          buttonType="secondary-outline"
          buttonStyles={styles.remove_image}
          onClickFunc={handleButtonClick}
        />
      </>
    );
  };

  const userActions = useActions({ fetchSelf, editProfileSettings });

  const updateUser = () => {
    userActions.editProfileSettings({ ...userState }).then(() => {
      userActions.fetchSelf().then(() => {
        setToastAlert(t("Your account was successfully updated."));
      });
    });
  };

  const onAvatarRemove = () => {
    setUploadedAvatar(null);
    setUserState({
      ...userState,
      avatar: null,
      avatar_id: null,
      remove_avatar: true,
    });
  };

  if (!self) {
    return null;
  }

  const RenderUserAvatar = () => {
    return (
      <div className={styles.avatar_container}>
        <UserAvatar
          sizeWidth={120}
          sizeHeight={120}
          altName="user"
          srcName={
            uploadedAvatar
              ? uploadedAvatar
              : (userState?.avatar as string) || "default"
          }
          userName={self.displayName}
          className={cls("avatar avatar-lg font-lg", styles.user_avatar)}
        />
        {userState?.avatar && !String(userState.avatar).includes("default") && (
          <span
            className={styles.delete_icon_container}
            onClick={onAvatarRemove}
          >
            <i className={cls(styles.delete_icon, "ico ico-trash-outline")} />
          </span>
        )}
      </div>
    );
  };

  return (
    <AccountWrap>
      <div className={cls(styles.container, "panel")}>
        <h2 className="text-muted">{t("Profile")}</h2>
        <RenderUserAvatar />
        <UploadNewMediaRender />
        {/* {self.avatar && ( */}
        {/*   <div className="control-group checkbox-control-group"> */}
        {/*     <div className="checkbox"> */}
        {/*       <input */}
        {/*         id="removeImage" */}
        {/*         type="checkbox" */}
        {/*         checked={userState.remove_avatar} */}
        {/*         onChange={(e) => */}
        {/*           updateUserData({ */}
        {/*             type: "remove_avatar", */}
        {/*             value: e.target.checked, */}
        {/*           }) */}
        {/*         } */}
        {/*       /> */}
        {/*       <label htmlFor="removeImage"></label> */}
        {/*     </div> */}
        {/*     <label htmlFor="removeImage">{t("Remove Photo")}</label> */}
        {/*   </div> */}
        {/* )} */}
        <span className={styles.input_wrapper}>
          <label htmlFor="name">{t("First Name")}</label>
          <input
            type="text"
            id="name"
            defaultValue={self.firstName}
            onChange={(e) =>
              updateUserData({ type: "first_name", value: e.target.value })
            }
          />
        </span>
        <span className={styles.input_wrapper}>
          <label htmlFor="lastName">{t("Last Name")}</label>
          <input
            type="text"
            id="lastName"
            defaultValue={self.lastName}
            onChange={(e) =>
              updateUserData({ type: "last_name", value: e.target.value })
            }
          />
        </span>
        <span className={styles.input_wrapper}>
          <label htmlFor="gender">{t("Gender")}</label>
          <select
            id="gender"
            defaultValue={self && self.gender ? self.gender : ""}
            onChange={(e) =>
              updateUserData({ type: "gender", value: e.target.value })
            }
          >
            {genders.map((gender: { label: string; value: string }) => {
              return (
                <option
                  key={gender.value}
                  defaultValue={
                    gender.value ? gender.value : t("Please Select...")
                  }
                >
                  {gender.label}
                </option>
              );
            })}
          </select>
        </span>
        <span className={styles.input_wrapper}>
          <label htmlFor="email">{t("Email")}</label>
          <input
            type="email"
            id="email"
            defaultValue={self.email}
            onChange={(e) =>
              updateUserData({ type: "email", value: e.target.value })
            }
          />
        </span>
        <span className={styles.input_wrapper}>
          <label htmlFor="phone">{t("Phone")}</label>
          <AccountPhoneVerificationModal
            isPhoneModalOpen={isPhoneModalOpen}
            handlePhoneModal={handlePhoneModal}
          />
          <input
            type="text"
            id="phone"
            defaultValue={
              self.phoneVerified && self.phoneNormalized
                ? self.phoneNormalized
                : null
            }
            placeholder={t("Add Phone Number")}
            onClick={handlePhoneModal}
          />
          {self.phoneVerified && self.phoneNormalized !== null && (
            <span className="change-phone-text" onClick={handlePhoneModal}>
              {t("Change")}
            </span>
          )}
        </span>
        {/* <span className={styles.input_wrapper}> */}
        {/*   <label htmlFor="kindrole">{t("What role best describes you?")}</label> */}
        {/*   <select */}
        {/*     id="kindrole" */}
        {/*     // value={self?.kind.charAt(0).toUpperCase() + self.kind.slice(1)} */}
        {/*     onChange={(e) => { */}
        {/*       updateUserData({ */}
        {/*         type: "kind", */}
        {/*         value: */}
        {/*           e.target.value === "Parent / Fan" */}
        {/*             ? "other" */}
        {/*             : e.target.value.toLowerCase(), */}
        {/*       }); */}
        {/*     }} */}
        {/*   > */}
        {/*     {roles.map((role: { label: string; value: string }) => { */}
        {/*       return ( */}
        {/*         <option */}
        {/*           key={role.value} */}
        {/*           selected={userState?.kind === role.value} */}
        {/*         > */}
        {/*           {role.label} */}
        {/*         </option> */}
        {/*       ); */}
        {/*     })} */}
        {/*   </select> */}
        {/* </span> */}
        {/* <span className={styles.input_wrapper}> */}
        {/*   <label htmlFor="calledName"> */}
        {/*     {t("What do you like to be called?")} */}
        {/*   </label> */}
        {/*   <input */}
        {/*     type="text" */}
        {/*     id="calledName" */}
        {/*     defaultValue={self.kindTitle} */}
        {/*     onChange={(e) => */}
        {/*       updateUserData({ type: "kind_title", value: e.target.value }) */}
        {/*     } */}
        {/*   /> */}
        {/* </span> */}
        <span className={styles.input_wrapper}>
          <label htmlFor="language">{t("Language")}</label>
          <select
            id="language"
            defaultValue={self && self.locale ? self.locale : ""}
            onChange={(e) =>
                updateUserData({ type: "locale", value: e.target.value })
            }
          >
            {languages.map((language: { label: string; value: string }) => {
              return (
                <option
                  key={language.value}
                  value={language.value}
                >
                  {language.label}
                </option>
              );
            })}
          </select>
        </span>
        <span className={styles.input_wrapper}>
          <label htmlFor="timezone">{t("Timezone")}</label>
          <select
            id="timezone"
            onChange={(e) =>
              updateUserData({
                type: "timezone",
                value: e.target.value.split(" ")[0],
              })
            }
          >
            {timezones &&
              timezones
                .slice()
                .sort((a: any, b: any) => a.utcTotalOffset - b.utcTotalOffset)
                .map((timezone: { name: string; utcTotalOffset: number }) => {
                  return (
                    <option
                      key={timezone.name}
                      selected={timezone.name === self.timezone}
                    >
                      {timezone.name} {timeZoneOffset(timezone.utcTotalOffset)}
                    </option>
                  );
                })}
          </select>
        </span>
        <div className="control-group checkbox-control-group mb-0">
          <div className="checkbox">
            <input
              id="analytics"
              type="checkbox"
              defaultChecked={self.trackingOptIn}
              onChange={(e) =>
                updateUserData({
                  type: "tracking_opt_in",
                  value: e.target.checked,
                })
              }
            />
            <label htmlFor="analytics"></label>
          </div>
          <label htmlFor="analytics">
            {t("Allow analytics data tracking")}
          </label>
        </div>
        <Button
          buttonType="primary"
          title={t("Save Changes")}
          buttonStyles={styles.save_button}
          onClickFunc={updateUser}
        />
      </div>
      <Toast success={toastAlert} onDone={() => setToastAlert(null)} />
    </AccountWrap>
  );
};

export default ProfileEdit;
