import {
  clearState,
  inviteModalIsOpen,
  inviteModalType,
} from "app/actions/team-group-member-import";
import { GroupInviteModalTypes } from "app/models/InvitationModel";
import { useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";

interface Props {
  defaultModalContent?: GroupInviteModalTypes;
}

export const GroupInvitationModel = ({ defaultModalContent }: Props) => {
  const dispatch = useDispatch();
  const { invitationModalType, modalInviteIsOpen } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual
  );
  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual
  );

  useMemo(() => {
    if (defaultModalContent) {
      dispatch(inviteModalType(defaultModalContent));
    }

    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  const modalCloseHandler = useCallback(() => {
    dispatch(clearState());
    dispatch(inviteModalIsOpen(false));
  }, [dispatch]);

  const groupInviteModalsHandler = useCallback(
    (type: GroupInviteModalTypes) => {
      if (type) {
        dispatch(inviteModalType(type));
      }
    },
    [dispatch]
  );

  const onCreateGroupHandler = () => {
    dispatch(inviteModalType("invite-members"));
  };

  return {
    modalInviteIsOpen,
    modalCloseHandler,
    groupInviteModalsHandler,
    invitationModalType,
    onCreateGroupHandler,
    selectedGroup,
  };
};
