import React from 'react';

import styles from './styles.module.scss';
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { useTranslation } from 'react-i18next';

type PropsDef = {
  actions: any,
  data: any,
};

export default function ConfirmationPrompt(props: PropsDef) {
  const { t } = useTranslation();
  const { data, actions } = props;
  const buttonStyle = data.operation === 'delete' ? styles.btn_danger : "btn-primary";
  const isRecurring = data?.event?.recurrenceGroupId;

  return (
    <ModalLayout isOpen classNames={`${styles.modal}`}>
      <div className={styles.confirmation_modal_content}>
        <div className={styles.close_button} onClick={actions.onCancel}>
          <i className="ico ico-x" />
        </div>
        <div className={styles.title}>
          {props.data.operation === 'delete' ? <i className={`ico ico-calendar-x ${styles.danger}`} /> : <i className="ico ico-pencil-o" />}
          <h2>{props.data.title}</h2>
        </div>
        <div className={styles.content}>
          {props.data.content}
        </div>
        <div className={styles.actions}>
          <button type="button" className={`btn ${buttonStyle}`} onClick={actions.onConfirm}>
            {isRecurring ? t('Only this') : t('Confirm')}
          </button>
          {isRecurring && <button type="button" className={`btn ${buttonStyle}`} onClick={actions.onConfirmFollowing}>{t('This and following')}</button>}
          <button type="button" className={`btn ${styles.cancel_button}`} onClick={actions.onCancel}>{t('Cancel')}</button>
        </div>
      </div>
    </ModalLayout>
  );
}
