import React from "react";
import styles from "./styles.module.scss";

const ButtonViewProgram = ({ colorStyle, text, handleClick = () => null }) => {
  const buttonClass = `${styles.button} ${colorStyle}`;

  return (
    <button className={buttonClass} onClick={handleClick}>
      {text}
    </button>
  );
};

export default ButtonViewProgram;
