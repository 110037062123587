import React from "react";
import styles from "./styles.module.scss";
import SearchBar from "app/components/inputs/SearchBar";
import ContentPanel from "app/components/Layouts/ContentPanel";
import { cls } from "app/utils";
import UserModel from "app/models/UserModel";
import MembersRoleFilter from "app/components/MembersRoleFilter";
import DropdownButton from "app/components/DropdownButton";
import Button from "app/components/Button/ButtonVariant";
import GroupPageLayout from "app/components/Layouts/GroupPageLayout";
import TeamsTabsContainer from "../TeamsTabsContainer";
import TeamGroupMember from "app/components/TeamGroupMember";
import { useTeamsGroupMembersModel } from "./indexModel";
import { useTranslation } from "react-i18next";
import GroupInviteModal from "app/routes/Groups/Group/GroupInviteModal";

export type tabType = {
  label: "Active" | "Invited" | "Incomplete";
  value: "accepted" | "pending" | "incomplete";
};

const TeamsMembers = () => {
  const { t } = useTranslation();
  const {
    canManageMembers,
    members,
    membersFilterHandler,
    containerRef,
    onDeleteMember,
    onMemberUpdate,
    memberFilterParams,
    memberSearchHandler,
    inviteMembersModalHandler,
    onMemberInvite,
    modalInviteIsOpen,
  } = useTeamsGroupMembersModel();

  const allRolesBtnTitle = () => {
    return (
      <>
        {t("All Roles")} <i className="ico ico-chevron" />
      </>
    );
  };

  const allRulesBtn = (
    <Button
      title={allRolesBtnTitle()}
      buttonStyles={styles.dropdown_button}
      buttonType="secondary-outline"
    />
  );

  return (
    <GroupPageLayout>
      <div className={styles.group_tabs_container}>
        <TeamsTabsContainer />
      </div>
      <ContentPanel
        className={cls({
          [styles.panel]: canManageMembers,
        })}
      >
        <div className={styles.title_header_wrapper}>
          <h2 className={cls("text-muted", styles.header_title)}>
            {t("Members")}
          </h2>
          <Button
            title={t("Add Members")}
            buttonType="primary"
            onClickFunc={inviteMembersModalHandler}
            buttonStyles={styles.invite_member_btn}
          />
        </div>
      </ContentPanel>

      <ContentPanel>
        <div className={styles.search_container}>
          <SearchBar
            className={styles.search_input}
            placeholder={t("Search Members")}
            onChange={memberSearchHandler}
          />
          <DropdownButton
            dropdownClassname={styles.dropdownClassname}
            btnContent={allRulesBtn}
            btnClassName={styles.dropdown_btn}
          >
            <MembersRoleFilter
              memberFilterParams={memberFilterParams}
              onChange={membersFilterHandler}
            />
          </DropdownButton>
        </div>
        <div className={styles.members_list}>
          {members &&
            members.map((member: UserModel) => {
              return (
                <TeamGroupMember
                  key={member?.id}
                  member={member}
                  onRemoveMember={onDeleteMember}
                  onMemberUpdate={onMemberUpdate}
                />
              );
            })}
          {!members ||
            (members && !members.length && (
              <div className={styles.no_membbers}>{t("No members found.")}</div>
            ))}
        </div>
        <div ref={containerRef} />
      </ContentPanel>
      {modalInviteIsOpen && (
        <GroupInviteModal
          defaultModalContent="select-group-members"
          onSubmit={onMemberInvite}
        />
      )}
    </GroupPageLayout>
  );
};

export default TeamsMembers;
