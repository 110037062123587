import { FormProvider, useForm } from "react-hook-form";
import styles from "./styles.module.scss";
import utilStyles from "../utilStyles.module.scss";
import FormInput from "../../../components/Programs/FormInput";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RootState } from "typedefs";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isNull, lowerFirst } from "lodash";
import { DateTime } from "luxon";
import Label from "../../../components/Programs/Label";
import StateDayPicker from "../../../components/Programs/StateDayPicker";
import SaveBar from "../../Sessions/SaveBar";
import SelectBox from "../../../components/OptionButtonGroup/SelectBox";
import { hoursWithLablesFromSpecificValue } from "../../../utils/timeUtils";
import {privacy_options, repeat_options} from "../Pricing/utils";
import {
  resetProgramSettings,
  setGeneralSettings,
} from "../../../actions/scheduling/program";
import { ProgramState } from "../../../reducers/scheduling/program";
import { useHistory } from "react-router-dom";
import ButtonsContainer from "app/components/Programs/Buttons/ButtonsContainer";
import InformationModal from "app/components/Programs/InformationModal";
import { useTranslation } from "react-i18next";

const GeneralProgramOptions = () => {
  const { t } = useTranslation();
  const generalOptionState = useSelector(
    (state: RootState): ProgramState => state.program
  );
  const methods = useForm({
    defaultValues: {
      category: generalOptionState.category,
      title: generalOptionState.title,
      subtitle: generalOptionState.subtitle,
      location: generalOptionState.location,
      email: generalOptionState.email,
      phone: generalOptionState.phone,
    },
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [date, setDate] = useState(generalOptionState.date);
  const [startTime, setStartTime] = useState(generalOptionState.startTime);
  const [endTime, setEndTime] = useState(generalOptionState.endTime);
  const [untilDateError, setUntilDateError] = useState(false);
  const privacyOption = privacy_options.find(
    (option) => option.value === generalOptionState.privacy
  );
  const [privacy, setPrivacy] = useState(privacyOption || privacy_options[0]);
  const [maxDate, setMaxDate] = useState<number | Date>(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );
  const [repeat, setRepeat] = useState(
    isNull(generalOptionState?.repeat?.label)
      ? repeat_options[0]
      : generalOptionState?.repeat
  );
  const [untilDate, setUntilDate] = useState<string | Date | null>(
    generalOptionState.untilDate || null
  );

  const [formErrors, setFormErrors] = useState({
    date: false,
    startTime: false,
    endTime: false,
    repeat: false,
    untilDate: false,
    phone: false,
  });

  const handleDateChange = useCallback(
    (value, label) => {
      if (label === "date") {
        const oneYearLater = DateTime.fromISO(value)
          .plus({ years: 1 })
          .toJSDate();
        const oneMonthLater = DateTime.fromISO(value)
          .plus({ months: 1 })
          .toJSDate();

        setDate(value);
        if (!generalOptionState.edit) {
          setUntilDate(oneMonthLater.toISOString());
        }
        repeat?.value === "Daily"
          ? setMaxDate(oneMonthLater)
          : setMaxDate(oneYearLater);
      } else {
        setUntilDate(value);
      }
    },
    [repeat]
  );

  const handleRepeat = (value) => {
    if (date) {
      const oneYearLater = DateTime.fromISO(date).plus({ years: 1 }).toJSDate();
      const oneMonthLater = DateTime.fromISO(date)
        .plus({ months: 1 })
        .toJSDate();
      if (value.value === "Daily") {
        if (untilDate > oneMonthLater.toISOString()) {
          setUntilDate(oneMonthLater);
        }
        setMaxDate(oneMonthLater);
      } else {
        setMaxDate(oneYearLater);
      }
    }
    setRepeat(value);
  };

  const onChange = (time, label) => {
    label === "startTime" ? setStartTime(time) : setEndTime(time);
  };

  const checkDateErrors = () => {
    if (
      untilDate < date &&
      repeat?.value !== "Never" &&
      generalOptionState.type === "Series"
    ) {
      setUntilDateError(true);
      return true;
    }
    return false;
  };

  const checkErrors = useCallback(() => {
    let hasErrors = false;
    const newErrors = { date: false, startTime: false, endTime: false };
    if (date === null) {
      newErrors.date = true;
      hasErrors = true;
    }

    if (startTime === null) {
      newErrors.startTime = true;
      hasErrors = true;
    }
    if (endTime === null) {
      newErrors.endTime = true;
      hasErrors = true;
    }

    setFormErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

    return hasErrors;
  }, [date, endTime, startTime]);

  useEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      checkErrors();
    }
  }, [methods.formState.errors, checkErrors]);

  const onSubmit = (data) => {
    const externalErrors = checkErrors();
    if (externalErrors) {
      return;
    }

    const dateErrors = checkDateErrors();
    if (dateErrors) {
      return;
    }

    const generalSettings = {
      category: data.category,
      title: data.title,
      subtitle: data.subtitle,
      date,
      startTime,
      endTime,
      location: data.location,
      email: data.email,
      privacy: privacy?.value,
      untilDate,
      repeat,
      phone: data.phone,
      page: 2,
    };
    dispatch(setGeneralSettings(generalSettings));
  };

  const calendar = useMemo(() => {
    return (
      <StateDayPicker
        onChange={(value) => handleDateChange(value, "date")}
        date={new Date(date)}
        openToDate={date === null ? new Date() : null}
      />
    );
  }, [date, handleDateChange]);

  return (
    <div
      className={`${styles.container} ${styles.coach_min_height} calendar-page-container`}
    >
      <FormProvider {...methods}>
        <form id="programForm">
          <div className={styles.row}>
            <div className={`${utilStyles.column} ${styles.section}`}>
              <label>General</label>
              <div className={styles.horizontal_line} />
              <div className={styles.column_gap_16}>
                <FormInput
                  tooltipText={t(
                    "Organize your programs into categories for custom reporting."
                  )}
                  tagTitle={t("Program Category")}
                  placeholder={t("Ex: Junior Programs")}
                  fieldName="category"
                  isRequired={true}
                />
                <FormInput
                  tagTitle={t("Title")}
                  fieldName="title"
                  placeholder={t(
                    `Enter ${lowerFirst(generalOptionState.type)} name`
                  )}
                  isRequired={true}
                />
                <FormInput
                  tagTitle={t("Subtitle")}
                  fieldName="subtitle"
                  isRequired={true}
                  placeholder={t("Ex: Wednesday 2:00 - 3:00 PM")}
                />
                <div className={styles.mt_16}>
                  <Label
                    text="Privacy"
                    required={true}
                    item={
                      // tslint:disable-next-line:jsx-wrap-multiline
                      <ButtonsContainer
                        customStyle={styles.pricing_item}
                        items={privacy_options}
                        containerStyle={styles.option_button_group}
                        openCalendarSelected={privacy}
                        setOpenCalendarSelected={setPrivacy}
                      />
                    }
                    containerStyle={styles.label_container}
                  />
                </div>
              </div>
            </div>
            <div className={styles.vertical_line} />
            <div className={`${utilStyles.column} ${styles.middle_section}`}>
              <div className={`${utilStyles.column}  `}>
                <label>{t("Date & Time")}</label>
                <div className={styles.horizontal_line} />
                <div className={`${utilStyles.column} ${utilStyles.mt_16}`}>
                  <div>
                    <Label
                      text={t("Date")}
                      required={true}
                      item={calendar}
                      error={formErrors.date ? t("This field is required") : ""}
                    />
                  </div>
                  <div
                    className={`${styles.time_container} ${utilStyles.mt_16}`}
                  >
                    <Label
                      text={t("Start Time")}
                      item={(
                        <SelectBox
                          options={hoursWithLablesFromSpecificValue(
                            endTime,
                            "end"
                          )}
                          customColor="#e6e6e6"
                          value={startTime}
                          setValue={(time) => onChange(time, "startTime")}
                          containerStyle={styles.mt_8}
                        />
                      )}
                      showUnder
                      containerStyle={styles.label_container}
                      error={
                        formErrors.startTime ? t("This field is required") : ""
                      }
                      required={true}
                    />
                    <Label
                      text={t("End Time")}
                      item={(
                        <SelectBox
                          options={hoursWithLablesFromSpecificValue(
                            startTime,
                            "start"
                          )}
                          customColor="#e6e6e6"
                          value={endTime}
                          setValue={(time) => onChange(time, "endTime")}
                          containerStyle={styles.mt_8}
                        />
                      )}
                      showUnder
                      containerStyle={styles.label_container}
                      error={
                        formErrors.endTime ? t("This field is required") : ""
                      }
                      required={true}
                    />
                  </div>
                  {generalOptionState.type === "Series" && (
                    <div className={`${utilStyles.mt_16}`}>
                      <Label
                        text={t("Repeat")}
                        item={(
                          <SelectBox
                            isDisabled={generalOptionState.edit}
                            customColor="#e6e6e6"
                            options={repeat_options}
                            value={repeat}
                            setValue={handleRepeat}
                          />
                        )}
                        containerStyle={styles.label_container}
                      />
                      {repeat?.value !== "Never" && (
                        <Label
                          containerStyle={utilStyles.mt_16}
                          text={t("Until Date")}
                          item={(
                            <div className={utilStyles.mt_8}>
                              <StateDayPicker
                                disabled={generalOptionState.edit}
                                onChange={(value) =>
                                  handleDateChange(value, "open")
                                }
                                maxDate={maxDate}
                                date={new Date(untilDate)}
                                openToDate={
                                  untilDate === null ? new Date() : null
                                }
                              />
                            </div>
                          )}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.vertical_line} />
            <div className={`${utilStyles.column} ${styles.contact_section}`}>
              <label>{t("Contact Info")}</label>
              <div className={styles.horizontal_line} />
              <div className={utilStyles.column}>
                <FormInput
                  tagTitle={t("Location")}
                  fieldName="location"
                  isRequired={true}
                />
                <FormInput
                  tagTitle={t("Email")}
                  fieldName="email"
                  isRequired={true}
                />
                <FormInput
                  tagTitle={t("Phone Number")}
                  fieldName="phone"
                  isRequired={true}
                />
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
      {untilDateError && (
        <InformationModal
          handleAction={() => setUntilDateError(false)}
          title={t("Until date cannot be set before the Start date.")}
          width="500px"
        />
      )}
      <SaveBar
        showCancel
        onSubmit={methods.handleSubmit(onSubmit)}
        onCancel={() => {
          history.goBack();
          dispatch(resetProgramSettings());
        }}
      />
    </div>
  );
};

export default GeneralProgramOptions;
