import PostingForm from "app/components/PostingForm";
import React, { memo } from "react";
import { FeedPostingFormModel } from "./indexModel";
import PostModel from "app/models/PostModel";
import { useTranslation } from "react-i18next";

interface Props {
  onPost?: (post: PostModel) => void;
}

const FeedPostingForm = ({ onPost }: Props) => {
  const { t } = useTranslation();
  const { user, canUploadMedia, onSubmit, onCancel } = FeedPostingFormModel({
    onPost,
  });

  return (
    <PostingForm
      textAreaPlaceholder={t("Create post...")}
      canUploadMedia={canUploadMedia()}
      canSchedule={
        user && user.planPermissions.includes("access_scheduled_posts")
      }
      profilePicture={null}
      postButtonLabel={t("Post")}
      postTextIsRequired
      locationsIsRequired
      onPost={onSubmit}
      onCancel={onCancel}
    />
  );
};

export default memo(FeedPostingForm);
