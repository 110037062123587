/* import { useTranslation } from 'react-i18next'; */
import React, { useEffect, useCallback } from 'react';
import PublicWrapper from "app/components/PublicWrapper";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { RootState } from 'typedefs';
import { useTranslation } from "react-i18next";
import { Loading } from "app/components/Wrappers";
import SessionForm from '../../Sessions/SessionForm';
import useBookSessionData from '../../Sessions/hooks/useBookSessionData';
import { defaultSelection } from '../../Sessions/helpers';
import { setBookSession } from 'app/actions/scheduling/bookSession';
import { schedulingFetchGet } from 'app/utils/request/scheduling/fetch';
import { useTimeZonesModel } from '../../Sessions/indexModel';
import useUrlSeach from 'app/utils/hooks/useUrlSearch';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const NewSession = () => {
  const [searchParams, _] = useUrlSeach();
  const coach = searchParams.coach;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const bookLessonState = useSelector((state: RootState) => state.bookSession);
  const { self: user } = useSelector(
    (state: RootState) => state.user,
    shallowEqual
  );

  const fetcher = useCallback(() => {
    if (!coach) {
      return Promise.resolve([]);
    }
    const coachPromise = schedulingFetchGet(`/coach_profiles/${coach}`, {}, {}, null, true);
    const lessonTypesPromise = schedulingFetchGet('/lesson_types');
    const promise = Promise.all([coachPromise, lessonTypesPromise]).then(
      ([coach, lessonTypes]) => {
        const facilities = [coach['facility']];
        return [facilities, [coach], lessonTypes];
      }
    ).catch((_) => {
      history.push('/');
      toast.error(t('An unexpected error has occurred.'));
    } );
    return promise;
  }, [coach]);

  const [loadingBookLessonData, bookLessonData] = useBookSessionData(fetcher);

  useEffect(()=> {
    if(user !== null && user.kind !== 'athlete') {
      history.push('/');
      toast.error(t('You do not have access to that page.'));
    }
  }
  , [user]);


  useEffect(() => {
    if (loadingBookLessonData || coach === null) {
      return;
    }

    if(!loadingBookLessonData && bookLessonData['facilities'].length === 0) {
      history.push('/');
      toast.error(t('An unexpected error has occurred.'));
      return;
    }

    const initialData = defaultSelection(bookLessonData);
    let lessonState = {
      ...initialData,
      date: initialData.date.toISODate(),
      time: null,
    };
    if (bookLessonState.duration) {
      lessonState = {
        ...lessonState,
        duration: bookLessonState.pricingValue,
      };
    }
    if (bookLessonState.time) {
      lessonState = {
        ...lessonState,
        time: bookLessonState.time,
      };
    }
    dispatch(setBookSession(lessonState));
    // Following eslint disable is intentional.
    // I do not want this useEffect to rerun if other things change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingBookLessonData, dispatch]);

  const { pending: loadingTimeZones } = useTimeZonesModel();

  if (loadingBookLessonData || !bookLessonState.facility || loadingTimeZones) {
    return <Loading isLoading loadType="spinner" />;
  }

  return (
    <PublicWrapper>
      <SessionForm bookLessonData={bookLessonData} isPublicPage={true}/>
    </PublicWrapper>

  );
};
export default NewSession;
