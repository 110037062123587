import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";
import UserModel from "app/models/UserModel";
import { rearangedInviteData as setRearangedInviteData } from "app/actions/team-group-member-import";

interface Props {
  isOpen?: boolean;
  onBack?: (colName: string) => void;
  changeFor: string;
  onConfirm?: (value: string, data: string[], columnIndex: number) => void;
}

const ChangeImportedColumnModal: React.FC<Props> = ({
  isOpen,
  onBack,
  changeFor,
  onConfirm,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { membersListToInvite, rearangedInviteData } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual,
  );

  const [selectedColumnPage, setSelectedColumnPage] = useState(1);
  const [selectedColumnData, setSelectedColumnData] = useState<string[][]>([]);

  const pagesCount = 4;

  const pageMapping: { [key: string]: number } = {
    jerseys: 4,
    positions: 3,
    names: 2,
    emails: 1,
  };

  // Update selectedColumnData based on membersListToInvite
  useEffect(() => {
    if (membersListToInvite && membersListToInvite.length > 0) {
      const data = generateColumnData(membersListToInvite);
      setSelectedColumnData(data);
    }
  }, [membersListToInvite]);

  // Set the initial selected column page based on the changeFor prop
  useEffect(() => {
    const initialPage = pageMapping[changeFor] || 1;
    setSelectedColumnPage(initialPage);
  }, [changeFor]);

  const generateColumnData = (members: UserModel[]) => {
    return Array.from({ length: pagesCount }, (_, index) => {
      return members.map((member: UserModel) => {
        switch (index) {
          case 0:
            return member.email || "";
          case 1:
            return member.name || "";
          case 2:
            return member.position || "";
          case 3:
            return member.jersey || "";
          default:
            return "";
        }
      });
    });
  };

  const renderPageData = (page: number) => {
    const pageData = selectedColumnData[page - 1];

    if (!pageData) {
      return null;
    }

    return (
      <table className="csv-column-table">
        <tbody>
          {pageData.map((data: string, index: number) => (
            <tr key={index}>
              <td>{data.length > 0 ? data : <i>{t("Empty")}</i>}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const onColumnChangeConfirm = (data: string[]) => {
    const selectedIndex = selectedColumnPage - 1;
    if (selectedIndex !== -1) {
      const filteredData = data.filter((item) => item.length > 0);

      const updatedMembersList = rearangedInviteData.map(
        (member: any, index: number) => {
          const updatedField = filteredData[index] || "";
          switch (changeFor) {
            case "emails":
              return { ...member, email: updatedField };
            case "names":
              return { ...member, name: updatedField };
            case "positions":
              return { ...member, position: updatedField };
            case "jerseys":
              return { ...member, jersey: updatedField };
            default:
              return member; // If no matching column, return the member as-is
          }
        },
      );

      dispatch(setRearangedInviteData(updatedMembersList));
      onConfirm?.(changeFor, filteredData, selectedIndex);
    }
  };

  const titleNameRender = () => {
    const titles: { [key: string]: string } = {
      emails: t("email addresses?"),
      names: t("names?"),
      positions: t("positions?"),
      jerseys: t("jersey numbers?"),
    };
    return titles[changeFor] || t("Unknown column?");
  };

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={() => onBack?.("")}
      headerTitle={t("Select Email Column")}
    >
      <div className={styles.header_text_wrapper}>
        <p>
          {t("Which column from your file is for")} <b>{titleNameRender()}</b>{" "}
          {t("Use the numbers or arrows to switch columns.")}
        </p>
      </div>
      <div className={styles.list_wrapper}>
        <div className={styles.csv_column_preview}>
          <div className={styles.pager}>
            <Button
              icon={<i className="ico ico-back" />}
              title=""
              buttonType="secondary-outline"
              disabled={selectedColumnPage === 1}
              onClickFunc={() =>
                selectedColumnPage > 1 &&
                setSelectedColumnPage(selectedColumnPage - 1)
              }
              buttonStyles={cls({
                [styles.inactive_page_btn]: selectedColumnPage !== 1,
              })}
            />
            {Array.from({ length: pagesCount }, (_, index) => index + 1).map(
              (page) => (
                <Button
                  key={page}
                  title={page.toString()}
                  onClickFunc={() => setSelectedColumnPage(page)}
                  disabled={selectedColumnPage === page}
                  buttonStyles={cls({
                    [styles.inactive_page_btn]: selectedColumnPage !== page,
                  })}
                  buttonType={
                    selectedColumnPage === page ? null : "secondary-outline"
                  }
                />
              ),
            )}
            <Button
              icon={<i className="ico ico-forward" />}
              title=""
              onClickFunc={() =>
                selectedColumnPage < pagesCount &&
                setSelectedColumnPage(selectedColumnPage + 1)
              }
              disabled={selectedColumnPage === pagesCount}
              buttonType="secondary-outline"
              buttonStyles={cls({
                [styles.inactive_page_btn]: selectedColumnPage !== pagesCount,
              })}
            />
          </div>
          <div className={styles.list_data_container}>
            {renderPageData(selectedColumnPage)}
          </div>
        </div>
        <div className={styles.select_column_btn_container}>
          <Button
            title={t("Select This Column")}
            buttonType="primary"
            icon={<i className="ico ico-check" />}
            onClickFunc={() =>
              onColumnChangeConfirm(selectedColumnData[selectedColumnPage - 1])
            }
            buttonStyles={styles.select_column_btn}
          />
        </div>
      </div>
      <div className={styles.cancel_btn}>
        <Button
          buttonType="secondary-outline"
          title={t("Cancel")}
          onClickFunc={() => onBack?.("")} // Optional chaining
        />
      </div>
    </ModalLayoutWithTitle>
  );
};

export default ChangeImportedColumnModal;
