import React, { useState } from "react";
import styles from "./styles.module.scss";
import DefiningPopup from "app/components/Popup/DefiningPopup";
import SpaceUserSelect from "app/components/inputs/SelectBox/SpaceUserSelect";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { newSpaceValues, addByPhone } from "app/actions/spaceCreation";
import { DefaultOption } from "app/components/inputs/SelectBox/Base";
import SelectedUsers from "app/components/SelectedUser";
import { UserOption } from "app/components/inputs/SelectBox/UserSelect";
import { ValueType } from "react-select";
import Button from "app/components/Button/ButtonVariant";
import { useCreateNewSpaceModel } from "../../index.model";
import CustomSelect from "app/components/inputs/CustomSelector";
import { useStoreActions } from "app/utils/hooks";
import { fetchSports } from "app/actions/sports";
import { IUserPhone } from "app/interfaces/UserTypes";
import AddPhoneNumber from "app/components/AddPhoneNumber";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TemplateSelect } from "app/components/inputs/SelectBox";

const SelectSpaceSport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { sports } = useStoreActions({ sports: fetchSports }, { noAuth: true });

  const { spaces_import, add_by_phone } = useSelector(
    (state: RootState) => state.createSpace,
    shallowEqual,
  );
  // const self = useSelector(
  //   (state: RootState) => state.user.self,
  //   shallowEqual,
  // );

  const [error, setError] = useState<string | null>(null);
  const { stepForward } = useCreateNewSpaceModel();

  const [phoneInfo, setPhoneInfo] = useState({
    displayName: "",
    phone: "",
    phoneCountry: "",
    phoneNormalized: "",
  });

  const addUserByPhoneHandler = () => {
    if (!phoneInfo.displayName) {
      setError("displayName");
    } else if (!phoneInfo.phone) {
      setError("phone");
    } else {
      setError(null);
      dispatch(
        newSpaceValues({
          ...spaces_import,
          athletes: [{ ...phoneInfo }] as any,
        }),
      );
    }
  };

  const nextPageHandler = () => {
    if (!add_by_phone) {
      addUserByPhoneHandler();
    }
    if (!spaces_import.sport) {
      setError("sport");
    } else if (
      !spaces_import.create_for_self &&
      (!spaces_import.athletes || !spaces_import.athletes.length)
    ) {
      setError("athlete");
    } else {
      stepForward();
      setError(null);
    }
  };

  const history = useHistory();

  const handlePhoneInputChange = (
    value: string,
    data: IUserPhone,
    _event: React.FormEvent<HTMLInputElement>,
    formattedValue: string,
  ) => {
    const phoneNormalized = formattedValue;
    const phone = value.substring(data.dialCode.length);
    const phoneCountry = data.countryCode.toUpperCase();
    setPhoneInfo({
      ...phoneInfo,
      phoneNormalized,
      phone,
      phoneCountry,
    });
  };

  const handleSportSelectChange = (
    data?: { value?: string; label?: string; __isNew__?: boolean } | null,
  ) => {
    const sportValue =
      data && sports.data.includes(data.value) ? data.value : "Custom";

    dispatch(
      newSpaceValues({
        ...spaces_import,
        sport: data ? sportValue : "",
        sport_other: data ? data.value : "",
      }),
    );
  };

  const templateSelectHandler = (value: DefaultOption) => {
    dispatch(
      newSpaceValues({
        ...spaces_import,
        space_template_id: {
          label: value ? value.label : "",
          id: value ? value.id : "",
        },
      }),
    );
  };

  const handleUserRemove = () => {
    dispatch(
      newSpaceValues({
        ...spaces_import,
        athletes: [],
      }),
    );
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.panel_title}>{t("Who is this Space for?")}</h4>
      <div className={styles.radio_select_container}>
        <div className={styles.radio_select}>
          <input
            id="for_me"
            type="radio"
            value="1"
            name="4592"
            defaultChecked={spaces_import.create_for_self}
            onClick={() => {
              dispatch(
                newSpaceValues({
                  ...spaces_import,
                  create_for_self: true,
                }),
              );
            }}
          />
          <label htmlFor="for_me">{t("It's for me, I'm the Athlete")}</label>
        </div>
        <div className={styles.radio_select}>
          <input
            id="for_another"
            onClick={() =>
              dispatch(
                newSpaceValues({
                  ...spaces_import,
                  create_for_self: false,
                  athletes: [],
                }),
              )
            }
            type="radio"
            value="1"
            name="4592"
            defaultChecked={!spaces_import.create_for_self}
          />
          <label htmlFor="for_another">{t("It's for another Athlete")}</label>
        </div>
      </div>

      {!spaces_import.create_for_self && (
        <div className={styles.selectbox_container}>
          {spaces_import.athletes && spaces_import.athletes.length < 1 ? (
            <>
              {!add_by_phone && (
                <label className={styles.title} htmlFor="space_template">
                  {t("Add athlete")}
                </label>
              )}
              <div className={styles.space_user_select_container}>
                {!add_by_phone ? (
                  <>
                    <SpaceUserSelect
                      addAthleteBtn
                      isCreatable
                      hideSelected
                      noOptionsMessage={() => t("Add a New Athlete")}
                      isMulti={false}
                      onChange={(selectedValue: ValueType<UserOption>) => {
                        if (selectedValue && !Array.isArray(selectedValue)) {
                          const userOption = selectedValue as UserOption;
                          dispatch(
                            newSpaceValues({
                              ...spaces_import,
                              athletes: [userOption.model],
                            }),
                          );
                        } else if (
                          selectedValue &&
                          Array.isArray(selectedValue)
                        ) {
                          const userOption = selectedValue[0] as UserOption;
                          dispatch(
                            newSpaceValues({
                              ...spaces_import,
                              athletes: [userOption.model],
                            }),
                          );
                        }
                      }}
                    />
                    {error && error.includes("athlete") && (
                      <div className="helper-text error">
                        {t("An athlete is required.")}
                      </div>
                    )}
                  </>
                ) : (
                  <div className={styles.add_by_phone_container}>
                    <div className={styles.selectbox_container}>
                      <label className={styles.title} htmlFor="name">
                        {t("Name")}
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder={t("Name")}
                        onChange={(e) => {
                          setPhoneInfo({
                            ...phoneInfo,
                            displayName: e.target.value,
                          });
                        }}
                      />
                      {error && error.includes("displayName") && (
                        <div className="helper-text error">
                          {t("Name is required.")}
                        </div>
                      )}
                    </div>
                    <div className={styles.selectbox_container}>
                      <AddPhoneNumber
                        onChange={handlePhoneInputChange as any}
                      />
                      {error && error.includes("phone") && (
                        <div className="helper-text error">
                          {t("Phone Number is required.")}
                        </div>
                      )}
                    </div>
                    <Button
                      title={t("Add")}
                      buttonType="primary"
                      onClickFunc={addUserByPhoneHandler}
                    />
                  </div>
                )}

                <div className={styles.another_way_wrapper}>
                  <span>{t("or")}</span>&nbsp;
                  <span
                    className={styles.add_with_another_way}
                    onClick={() => dispatch(addByPhone(!add_by_phone))}
                  >
                    {!add_by_phone
                      ? t("Add by Phone Number")
                      : t("Add by Name or Email")}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <SelectedUsers
              onRemoveUserClick={handleUserRemove}
              checkIfItsMe
              users={spaces_import.athletes}
            />
          )}
        </div>
      )}

      <div className={styles.select_input_container}>
        <div className={styles.selectbox_container}>
          <label className={styles.title} htmlFor="sport">
            {t("Sport")}
          </label>
          <CustomSelect
            placeholder={t("Select Sport / Subject")}
            isCratable
            options={sports?.data}
            value={
              spaces_import?.sport
                ? {
                    label: spaces_import?.sport,
                    value: spaces_import?.sport,
                  }
                : {
                    label: "",
                    value: "",
                  }
            }
            defaultValue={{
              label: spaces_import?.sport,
              value: spaces_import?.sport,
            }}
            isClearable
            isSearchable
            onChange={handleSportSelectChange}
          />
          {error && error.includes("sport") && (
            <div className="helper-text error">{t("A sport is required.")}</div>
          )}
        </div>
        {self && (
          <div className={styles.selectbox_container}>
            <label className={styles.title} htmlFor="space_template">
              {t("Template")} &nbsp;
              <DefiningPopup
                content={t(
                  "If have Templates they will show here, if not you can create them in the Library",
                )}
              />
            </label>
            <TemplateSelect
              defaultValue={
                spaces_import?.space_template_id?.id?.length && {
                  label: spaces_import?.space_template_id.label,
                  value: spaces_import?.space_template_id.id,
                }
              }
              onChange={templateSelectHandler}
              isSearchable
              isClearable
            />
          </div>
        )}
      </div>
      <div className={styles.button_container}>
        <Button
          title={t("Cancel")}
          buttonType="secondary-outline"
          onClickFunc={() => history.goBack()}
        />
        <Button
          title={t("Next")}
          buttonType="primary"
          onClickFunc={nextPageHandler}
        />
      </div>
    </div>
  );
};

export default SelectSpaceSport;
