import React, { useState, useEffect, useRef, useCallback } from "react";
import { Loading } from "app/components/Wrappers";
import utilStyles from "./styles.module.scss";

const defaultResultCountFetcher = () => null;

export default function ItemListView(props) {
  const {
    Item,
    NoItemsView,
    callback,
    objectList = null,
    filters = null,
    refresh,
    setRefresh,
    query = null,
    sortBy = null,
    order = null,
    loading,
    setLoading,
    setResultCount,
    selectedItem,
    setSelectedItem,
    handleBecome,
    resultCountFetcher = defaultResultCountFetcher,
    errors,
    selectedUserType = null,
    debounceTimeout = 1000,
    ...itemProps
  } = props;
  const userType = "CoachProfile";
  const [items, setItems] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [hasItems, setHasitems] = useState(
    objectList ? objectList.length > 0 : false
  );

  const prevPageNumberRef = useRef(null);

  const list = objectList ? objectList : items;

  const setVariables = useCallback(
    (data) => {
      setHasMore(pageNumber < data.noPages);
      setHasitems(data.items?.length !== 0);
      setItems((previtems) => {
        if (pageNumber === 1) {
          return data.items;
        }
        return [...previtems, ...data.items];
      });
      if (setResultCount) {
        setResultCount(resultCountFetcher(data));
      }
      setIsFetchingNextPage(false);
      setLoading(false);
      if (setRefresh) {
        setRefresh(false);
      }
    },
    [pageNumber, setLoading, setRefresh, setResultCount, resultCountFetcher]
  );

  useEffect(() => {
    if (callback != null) {
      if (
        prevPageNumberRef.current !== null &&
        prevPageNumberRef.current !== pageNumber
      ) {
        setIsFetchingNextPage(true);
      } else {
        setLoading(true);
        setItems([]);
      }

      prevPageNumberRef.current = pageNumber;
      const delayDebounceFn = setTimeout(() => {
        callback(
          {
            pageNumber,
            query,
            order,
            sortBy,
          },
          setVariables,
          filters
        );

        return () => null;
      }, debounceTimeout);
      return () => clearTimeout(delayDebounceFn);
    } else {
      return undefined;
    }
  }, [
    query,
    pageNumber,
    filters,
    callback,
    userType,
    order,
    sortBy,
    setLoading,
    setResultCount,
    setRefresh,
    setVariables,
    resultCountFetcher,
    debounceTimeout,
  ]);

  useEffect(() => {
    setPageNumber(1);
  }, [filters, order, sortBy, query, refresh]);

  useEffect(() => {
    if (refresh && callback) {
      setLoading(true);
      setItems([]);
      callback(
        {
          pageNumber,
          query,
          order,
          sortBy,
        },
        setVariables,
        filters
      );
    }
  }, [
    refresh,
    callback,
    filters,
    order,
    pageNumber,
    query,
    sortBy,
    setVariables,
    setLoading,
  ]);

  const handleId = (id) => {
    setSelectedItem(list.find((club) => club.id === id));
  };

  const observer = useRef<IntersectionObserver | null>(null);
  const lastClubRef = useCallback(
    (node) => {
      if (isFetchingNextPage) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((current) => current + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isFetchingNextPage, hasMore]
  );

  const itemList = list?.map((item, index) => {
    const error = errors ? errors[index + 1] : null;
    return (
      <Item
        innerRef={list.length === index + 1 ? lastClubRef : null}
        key={item.id}
        onClick={() => handleId(item.id)}
        selected={selectedItem ? selectedItem.id === item.id : false}
        {...itemProps}
        {...item}
        errors={error}
        handleBecome={handleBecome}
        selectedUserType={selectedUserType}
      />
    );
  });

  const loadingBars = (
    <div className={utilStyles.center_horizontal}>
      <Loading isLoading loadType="spinner" />
    </div>
  );

  if (!hasItems && (isFetchingNextPage || loading)) {
    return loadingBars;
  }

  if (!hasItems && !loading && !isFetchingNextPage && NoItemsView) {
    return <NoItemsView />;
  }
  return (
    <>
      {itemList}
      {isFetchingNextPage || loading ? loadingBars : null}
    </>
  );
}
