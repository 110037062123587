import styles from "./styles.module.scss";
import React from "react";
const UserAvatar = ({
  src,
  height,
  width,
  first_name = "",
  last_name = "",
  font_size = null,

  isProgram = false,
  extraContainerStyles = null,
}) => {
  const getNameInitials = () => {
    if (!(first_name && last_name)) {
      return "";
    }
    return `${first_name[0]}${last_name[0]}`;
  };

  const getSrc = () => {
    if (src === null || src === undefined) {
      return;
    }
    if (src.substring(0, 5) === "https" || src.substring(0, 5) === "http") {
      return src;
    }
    if (src.substring(0, 5) === "blob:") {
      return src;
    }
    return `${process.env.SERVER_HOST}${src}`;
  };

  const profilePicture = (
    <img style={{ height: "100%" }} src={getSrc()} alt="Profile picture" />
  );
  const picturePlaceholder = (
    <div
      className={styles.avatar_placeholder}
      style={font_size ? { fontSize: `${font_size}px` } : null}
    >
      {getNameInitials()}
    </div>
  );

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: isProgram ? "white" : "",
      }}
      className={`${styles.avatar_container} ${
        src ? null : styles.default_avatar_container
      } ${extraContainerStyles}`}
    >
      {src ? profilePicture : picturePlaceholder}
    </div>
  );
};

export default UserAvatar;
