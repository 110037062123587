import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import closeIcon from "../../../../../public/images/close-icon.svg";
import { TeamsSettingsModel } from "./indexModel";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import Button from "app/components/Button/ButtonVariant";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const TeamsSettingsModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const {
    ChannelSettingsData,
    newChannelData,
    handleNameChange,
    groupTypeHandler,
    handleDescriptionChange,
    updateHandler,
    deleteHandler,
    canDelete,
    canEdit,
    loading,
    error,
  } = TeamsSettingsModel({ onClose });

  return (
    <ModalLayout isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.title_container}>
          <h1>{t("Channel Settings")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={onClose}
          />
        </div>
        <div className={styles.form_wrapper}>
          <div className={styles.input_wrapper}>
            <label htmlFor="channel_name">{t("Channel Name")}</label>
            <input
              type="text"
              name="channel_name"
              id="channel_name"
              value={newChannelData.name}
              required
              onChange={handleNameChange}
            />
          </div>

          <ul className={cls("stack-list-items")}>
            {ChannelSettingsData.map((setting) => (
              <li
                key={setting.label}
                className={cls("stack-list-item selectable-item", {
                  active: newChannelData.groupType === setting.value,
                })}
              >
                <label
                  className="selectable-item-trigger"
                  htmlFor={setting.value}
                ></label>
                <div className="selectable-item-control">
                  <div className="radio">
                    <input
                      type="radio"
                      name="groupType"
                      id={setting.value}
                      value={setting.value}
                      defaultChecked={
                        newChannelData.groupType === setting.value
                      }
                      onClick={() => groupTypeHandler(setting.value as any)}
                    />
                    <label className="opaque" htmlFor={setting.value}></label>
                  </div>
                </div>
                <div className="selectable-item-details">
                  <div className="selectable-item-label">{setting.label}</div>
                  <div className="selectable-item-summary">
                    {setting.description}
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className={styles.input_wrapper}>
            <label htmlFor="description">{t("Description")}</label>
            <input
              type="text"
              name="description"
              id="description"
              value={newChannelData.description}
              required
              onChange={handleDescriptionChange}
            />
          </div>
        </div>

        {canDelete && (
          <Button
            title={t("Delete Channel")}
            buttonType="secondary-outline-danger"
            buttonStyles={styles.delete_button}
            onClickFunc={deleteHandler}
          />
        )}

        {error && (
          <p className="text-danger">
            {t("Something went wrong. Please try again later")}
          </p>
        )}
        <div className={styles.buttons}>
          <button
            className={cls("btn btn-outline-secondary", styles.button)}
            onClick={onClose}
          >
            {t("Cancel")}
          </button>
          <button
            disabled={!newChannelData.name || !canEdit || loading}
            className={cls("btn btn-primary", styles.button, {
              ["disabled"]:
                !newChannelData.name ||
                newChannelData.name.trim().length === 0 ||
                !canEdit,
            })}
            onClick={updateHandler}
          >
            {t("Save")}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default TeamsSettingsModal;
