import styleInput from "./styles.module.scss";
import DownArrow from "../../../../public/images/down-chevron.svg";
import React, { useState, forwardRef } from "react";
import DayPicker from "../DayPicker";

interface Props {
  value: string;
  onClick: () => void;
  highlight: boolean;
}
const StateDayPicker = ({
  openToDate = null,
  disabled = false,
  date = null,
  CustomInput = null,
  onChange,
  minDate = null,
  maxDate = null,
  customInputStyle = null,
}) => {
  const [_isOpen, setIsOpen] = useState(false);
  const DefaultInput = forwardRef<HTMLInputElement, Props>(
    ({ value, onClick, highlight }, ref) => {
      const customStyle = {
        border: highlight ? "1px solid #27a4ff" : "1px solid #e6e6e6 ",
        borderRadius: "4px",
      };

      const isNull = new Date(value).getFullYear() <= 1970;
      return (
        <div
          className={`${styleInput.container_42} ${customInputStyle} ${
            isNull && styleInput.container_placeholder
          } ${disabled && styleInput.disabled}`}
          style={customStyle}
          onClick={() => onClick()}
          ref={ref}
        >
          <p>{isNull ? "Select" : value}</p>

          <img src={DownArrow} alt="info" />
        </div>
      );
    }
  );

  DefaultInput.displayName = "DayPickerInput";

  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <DayPicker
      openToDate={openToDate}
      disabled={disabled}
      date={date}
      CustomInput={CustomInput || DefaultInput}
      onChange={handleChange}
      setIsOpen={setIsOpen}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

export default StateDayPicker;
