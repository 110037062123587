import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import { useActions, useSelector } from "app/utils/hooks";
import { fetchSpaces, clearSpace } from "app/actions/space";
import {
  fetchSegmentById,
  deleteSegment,
  clearSegments,
} from "app/actions/segments";
import { RootState } from "typedefs";
import DropdownButton from "app/components/DropdownButton";
import { cls } from "app/utils";
import SpacesList from "app/routes/Segments/SegmentSpaces/SpacesList";
import SegmentsLayout from "app/components/Layouts/SegmentsLayout";
import { Link, useHistory } from "react-router-dom";
import { shallowEqual } from "react-redux";

const SegmentSpaces = () => {
  const { t } = useTranslation();
  const id = window.location.pathname.split("/")[2];
  const spaceQuery = {
    segmentIds: [id],
    type: "athlete",
  };

  const segmentActions = useActions({
    fetchSpaces,
    clearSpace,
    fetchSegmentById,
    clearSegments,
    deleteSegment,
  });
  const history = useHistory();
  useEffect(() => {
    if (id) {
      segmentActions.fetchSpaces(spaceQuery);
      segmentActions.fetchSegmentById(id);
    }

    return () => {
      segmentActions.clearSpace();
      segmentActions.clearSegments();
    };
  }, [id]);

  const segmentData = useSelector(
    (state: RootState) => state.segments.segmentsData,
    shallowEqual,
  );

  const segmentsPending = useSelector(
    (state: RootState) => state.segments.segmentsPending,
    shallowEqual,
  );

  const canPost = segmentData && segmentData.planPermissions.includes("post");
  const canInvite =
    segmentData?.planPermissions?.includes("invite") &&
    segmentData?.spaceIds?.length > 0;
  const canManageSpaces =
    segmentData && segmentData.planPermissions.includes("manage_spaces");
  const canEdit = segmentData && segmentData.planPermissions.includes("edit");
  const canRemoveList =
    segmentData && segmentData.planPermissions.includes("delete");

  const listRemoveHandler = () => {
    const confirmed = window.confirm(
      t("Are you sure you want to remove this space from list?"),
    );
    if (confirmed) {
      segmentActions.deleteSegment(id).then(() => {
        // window.location.pathname = `/lists`;
        history.push("/lists");
      });
    }
  };

  const dropdownList = [
    {
      label: t("Manage Spaces"),
      link: "spaces/edit",
      permission: canManageSpaces,
    },
    { label: t("Post into List"), link: "spaces/post", permission: canPost },
    {
      label: t("Invite Coaching Staff to all Spaces"),
      link: "add_members",
      permission: canInvite,
    },
    { label: t("Edit Name"), link: "edit", permission: canEdit },
    {
      label: t("Remove"),
      link: "",
      permission: canRemoveList,
      onClickFunc: listRemoveHandler,
    },
  ];

  const dropdownIcon = (
    <span className={styles.dropdown_icons}>
      {" "}
      <i className="icon-cog" /> <i className="ico ico-chevron" />{" "}
    </span>
  );

  const dropdownContent = (
    <DropdownButton
      btnClassName={styles.dropdown_btn}
      dropdownWrapperClass={styles.dropdown_container}
      btnContent={dropdownIcon}
    >
      <ul>
        {dropdownList.map((item, index) => {
          return (
            item.permission && (
              <li
                key={index}
                onClick={item.onClickFunc ? item.onClickFunc : null}
              >
                <Link
                  to={item.link ? `/lists/${id}/${item.link}` : ""}
                  className={cls({
                    [styles.remove_btn]: item.label.includes(t("Remove")),
                  })}
                >
                  {item.label}
                </Link>
              </li>
            )
          );
        })}
      </ul>
    </DropdownButton>
  );

  return (
    <SegmentsLayout isLoading={segmentsPending}>
      <div className={styles.container}>
        {segmentData && (
          <PagePanel
            title={segmentData && segmentData.name}
            headerChildren={dropdownContent}
          >
            <ol className={styles.breadcrumb_wrapper}>
              <li>
                <Link to="/lists">{t("List")}</Link>
              </li>{" "}
              / <li>{segmentData.name}</li>
            </ol>
          </PagePanel>
        )}
        <SpacesList />
      </div>
    </SegmentsLayout>
  );
};

export default SegmentSpaces;
