/* eslint-disable no-console */
/* tslint:disable:no-console */
import { useActions, useSelector } from "app/utils/hooks";
import { useEffect } from "react";
import { RootState } from "typedefs";
import { clearSpace, fetchSpaceById } from "../../../actions/space";
import { useHistory } from "react-router-dom";
import { shallowEqual } from "react-redux";

export const SpacePageLayoutModel = () => {
  const { selectedSpace } = useSelector(
    (state: RootState) => state.space,
    shallowEqual
  );
  const spaceActions = useActions({
    fetchSpaceById,
    clearSpace,
  });

  const url = window.location.pathname;
  const parts = url.split("/");
  const spaceId = parts[parts.indexOf("spaces") + 1];

  useEffect(() => {
    if (spaceId) {
      try {
        spaceActions.fetchSpaceById(spaceId, {
          "options[include]": ["invitations_info", "user_settings"],
        });
      } catch (error) {
        console.error("Failed to fetch space:", error);
      }
    }
  }, [spaceId, spaceActions]);

  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const onTimelineClick = () => {
    route(`/spaces/${selectedSpace.id}`);
  };

  const onScheduleClick = () => {
    route(`/spaces/${selectedSpace.id}/schedule`);
  };

  const onMembersClick = () => {
    route(`/spaces/${selectedSpace.id}/members`);
  };

  return { selectedSpace, onTimelineClick, onScheduleClick, onMembersClick };
};
