import { DateTime } from "luxon";
import { dayHours, hoursWithLables } from "../../../utils/timeUtils";
export const attendance_buttons = [
  {
    value: 0,
    label: "On",
  },
  {
    value: 1,
    label: "Off",
  },
];

export const program_type_options = [
  {
    value: 0,
    label: "Clinic",
  },
  {
    value: 1,
    label: "Series",
  },
];

export const privacy_options = [
  {
    value: "private",
    label: "Private",
  },
  {
    value: "public",
    label: "Public",
  },
];

export const registrations_status_options = [
  {
    value: "open",
    label: "Open",
  },
  {
    value: "closed",
    label: "Closed",
  },
  {
    value: "opens_in_future",
    label: "Opens in Future",
  },
];

export const cancellation_buttons = [
  {
    value: 0,
    label: "Start Time",
    tooltip: "Select a cut-off time based on the program's start time.",
  },
  {
    value: 1,
    label: "Static",
    tooltip:
      "Select a cut-off time that applies to the program on a given day.",
  },
];

export const repeat_options = [
  { label: "Does not repeat", value: "Never" },
  { label: "Daily", value: "Daily" },
  { label: "Weekly", value: "Weekly" },
  { label: "Bi-weekly", value: "Bi-weekly" },
  { label: "Monthly", value: "Monthly" },
];

const reminders = [
  { value: 1, label: "1 Hour" },
  { value: 3, label: "3 Hours" },
  { value: 6, label: "6 Hours" },
  { value: 12, label: "12 Hours" },
  { value: 24, label: "1 Day" },
];

for (let i = 2; i < 30; i++) {
  reminders.push({ value: i * 24, label: `${i} Days` });
}

export const cut_off_list = [{ value: -1, label: "Unlimited" }, ...reminders];
export const cut_off_days = [
  { value: 0, label: "Day Of" },
  { value: 1, label: "Day Before" },
  { value: 2, label: "2 Days Before" },
  { value: 3, label: "3 Days Before" },
];

const findCutOffTime = (attributes) => {
  let cutOffTime = null;
  let cutOffDay = null;
  if (attributes.cancellationCutOffType === "start_time") {
    cutOffDay = attributes.cancellationCutOffTime;
    cutOffDay = cut_off_list.find((item) => item.value === cutOffDay);
    return { cutOffDay, cutOffTime };
  } else {
    const days = Math.floor(attributes.cancellationCutOffTime / 24);
    cutOffTime = attributes.cancellationCutOffTime - days * 24;
    const options = dayHours();
    cutOffTime = options.find((item) => item.value === cutOffTime);
    cutOffDay = cut_off_days.find((item) => item.value === days);
    return { cutOffDay, cutOffTime };
  }
};

const getDate = (date) => {
   const newDate = new Date(date);
   newDate.setDate(DateTime.fromISO(date, { setZone: true }).day);

   return newDate.toISOString();
};

export const constructCloneProps = (attributes, edit, view) => {
  const { cutOffDay, cutOffTime } = findCutOffTime(attributes);
  const tags = attributes?.tags?.map((item) => {
    return { label: item.name, value: item.id };
  });
  const cloneState = {
    id: attributes.id,
    type:
      attributes?.attendance?.charAt(0).toUpperCase() +
      attributes?.attendance?.slice(1),
    category: attributes?.category,
    title: view
      ? attributes.title
      : edit
      ? attributes.title
      : `[Clone Of] ${attributes.title}`,
    subtitle: attributes.subtitle,
    page: 1,
    email: attributes.email,
    phone: attributes.phone,
    location: attributes.location,
    tab: 1,
    capacity: attributes.capacity,
    price: attributes.price,
    shortDescription: attributes.shortDescription,
    longDescription: attributes.longDescription,
    thumbnailURL: attributes.externalThumbnailUrl,
    headerURL: attributes.externalHeaderUrl,
    tags,
    coachIds: attributes?.coaches?.map((item) => item.id),
    date: getDate(attributes?.start),
    startTime: hoursWithLables.find(
      (item) => item.value === attributes?.startTime
    ),
    endTime: hoursWithLables.find((item) => item.value === attributes?.endTime),
    waitlist: attributes.withWaitlist,
    registrationOpenDate: getDate(attributes?.registrationStartTime),
    registrationOpenTime: hoursWithLables.find(
      (item) => item.value === attributes.registrationParsedStartTime
    ),
    registrationCloseDate: getDate(attributes?.registrationEndTime),
    registrationCloseTime: hoursWithLables.find(
      (item) => item.value === attributes.registrationParsedEndTime
    ),
    cutOffType: attributes.cancellationCutOffType,
    cutOffDay,
    cutOffTime,
    edit,
    spotsBooked: attributes.spotsBooked,
    status: attributes.status,
    events: attributes.events,
    coaches: attributes.coaches,
    repeat: repeat_options.find(
      (item) => item.value.toLowerCase() === attributes.repeat
    ),
    untilDate: attributes.untilDate,
    facilityId: attributes.facilityId,
    currency: attributes.facilityCurrencySymbol,
    timeZone: attributes.facilityTimeZone,
    registrationStatus: attributes.registrationStatus,
    privacy: attributes.privacy,
  };

  return cloneState;
};

export const getColorCode = (status) => {
  switch (status) {
    case "open":
      return {
        background: "#43A0471A",
        text: "#43A047",
      };
    case "not_started":
      return {
        background: "#F4F4F4",
        text: "#000000",
      };
    case "registered":
      return {
        background: "#E545001A",
        text: "#E54500",
      };
    case "waitlisted":
      return {
        background: "#E545001A",
        text: "#E54500",
      };
    case "closed":
      return {
        background: "#F4F4F4",
        text: "#8C8C8C",
      };
  }

  return {
    background: "#F4F4F4",
    text: "#8C8C8C",
  };
};

export const parseDate = (date, timezone, leftSlice = 0, rightSlice = 0) => {
  const parsedDate = DateTime.fromISO(date, {
    zone: timezone,
  }).toISO();
  return leftSlice !== rightSlice && leftSlice !== 0 && rightSlice !== 0
    ? parsedDate.slice(leftSlice, rightSlice)
    : parsedDate;
};

export async function getBlobFromBlobUrl(blobUrl) {
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  return blob;
}

export const generateCurrencySymbol = (currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "AUD":
      return "$";
    case "GBP":
      return "£";
    case "CAD":
      return "$";
    case "JPY":
      return "¥";
    case "AED":
      return "AED";
    case "EUR":
      return "€";
    case "MXN":
      return "MXN";
    case "NZD":
      return "$";
    case "TRY":
      return "₺";
    default:
      return "$";
  }
};
