import React, { useState } from "react";
import styles from "./styles.module.scss";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import ChannelIcon from "../../../../../../public/images/group-success-icon.png";
import Button from "app/components/Button/ButtonVariant";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";
import {
  getImportTeamGroupMembersStatus,
  importTeamGroupMembers,
  inviteModalIsOpen,
  inviteModalType,
} from "app/actions/team-group-member-import";
import { useActions } from "app/utils/hooks";
import { useParams } from "react-router-dom";
import PendingImportMembers from "../PendingImportMembers";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}

const POLLING_INTERVAL = 3000;

const ChannelCreatedModal: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { teamId } = useParams<{ id: string; teamId: string }>();

  const channelActions = useActions({
    importTeamGroupMembers,
    getImportTeamGroupMembersStatus,
  });

  const [importPending, setImportPending] = useState(false);
  const [polling, setPolling] = useState(false);

  const { selectedTeam } = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual
  );

  const handleSelectGroupMembers = () => {
    dispatch(inviteModalType("select-group-members"));
  };

  const handleAddAllGroupMembers = () => {
    channelActions.importTeamGroupMembers(teamId).then((res) => {
      if (res?.status !== "success") {
        setImportPending(true);
        pollForStatus(teamId, res?.id);
      }
    });
  };

  const pollForStatus = (teamId: string, importId: string) => {
    if (!polling) {
      setPolling(true);
      const poll = () => {
        channelActions
          .getImportTeamGroupMembersStatus(teamId, importId)
          .then((res) => {
            if (res?.status === "success") {
              setImportPending(false);
              setPolling(false);
              dispatch(inviteModalIsOpen(false));
            } else {
              setTimeout(poll, POLLING_INTERVAL);
            }
          });
      };
      poll();
    }
  };

  if (importPending) {
    return <PendingImportMembers isOpen={importPending} />;
  }

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Channel Created")}
    >
      <div className={styles.container}>
        <img
          src={ChannelIcon}
          alt="channel icon"
          className={styles.group_icon}
        />
        <span>
          {selectedTeam && (
            <b>
              {t(`{{channelName}} Created!`, {
                channelName: selectedTeam?.name,
              })}
            </b>
          )}
          <p>{t("Choose Group Members to form the Channel.")}</p>
        </span>
        <div className={styles.invite_buttons_container}>
          <Button
            buttonType="primary"
            title={t("Select Group Members")}
            onClickFunc={handleSelectGroupMembers}
          />
          <Button
            buttonType="primary"
            title={t("Add All Group Members")}
            onClickFunc={handleAddAllGroupMembers}
          />
        </div>
        <div className={styles.cancel_btn}>
          <Button
            buttonType="secondary-outline"
            title={t("Done")}
            onClickFunc={onClose}
          />
        </div>
      </div>
    </ModalLayoutWithTitle>
  );
};

export default ChannelCreatedModal;
