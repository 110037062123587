// import UserItem from './UserItem';
import StudentItem from "../StudentItem";
import style from "./styles.module.scss";
import React, { useState } from "react";
import CloseIcon from "../../../../public/images/close-icon-dark.svg";

const UserItem = (props) => {
  const [focused, setFocused] = useState(false);

  const close = (
    <div
      data-testid="remove"
      onClick={() => props.onClose(props)}
      className={props.closeStyle}
    >
      <div className={style.image_container}>
        <img src={CloseIcon} />
      </div>
    </div>
  );
  return (
    <div
      className={props.itemStyle || style.student_item}
      data-testid="studentItem"
      onMouseEnter={() => setFocused(true)}
      onMouseLeave={() => setFocused(false)}
    >
      <StudentItem
        key={props.id}
        {...props}
        firstName={props.firstName}
        lastName={props.lastName}
        profile_url={props.profilePicture || props.avatar}
        height={props.height}
        width={props.width}
      />
      {(focused || props.displayClose) && !props.cantClose ? close : null}
    </div>
  );
};

export default UserItem;
