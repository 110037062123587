import styles from "./styles.module.scss";
import xIco from "../../../../public/images/close.svg";
import reactDom from "react-dom";
import { useTranslation } from "react-i18next";
import React from "react";

const Modal = ({
  showModal,
  title,
  confirmButton,
  children,
  modal_style = styles.modal,
  showCloseIcon = true,
  backgroundColor = "white",
  width = "600px",
  cancelButtonProp,
  zIndex = 5,
  showButtons = true,
  topImage = null,
  stackedButtons = false,
  confirmFirst = false,
}) => {
  const { t } = useTranslation();
  const cancelButton = (
    <button
      data-testid="cancel-edit"
      className={styles.cancel_button}
      onClick={() => showModal(false)}
    >
      {t("Cancel")}
    </button>
  );

  const imageModal = () => {
    return (
      <div>
        <div className={styles.line_container}>
          {showCloseIcon && (
            <img
              className={styles.clickable_image}
              src={xIco}
              alt="envelope"
              id="repeat-indicator"
              onClick={() => showModal(false)}
            />
          )}
        </div>

        <div className={styles.header_container_centered}>
          {topImage && <img src={topImage} />}

          <p>{title}</p>
        </div>
      </div>
    );
  };

  const noImageModal = () => {
    return (
      <div className={styles.header_container}>
        {topImage && <img src={topImage} />}

        <p>{title}</p>
        {showCloseIcon && (
          <img
            className={styles.clickable_image}
            src={xIco}
            alt="envelope"
            id="repeat-indicator"
            onClick={() => showModal(false)}
          />
        )}
      </div>
    );
  };

  const modal = (
    <div className={`${styles.wrapper} modal`} style={{ zIndex }}>
      <div className={modal_style} style={{ width }}>
        {}
        <div
          className={topImage ? styles.image_header : styles.header}
          style={{ backgroundColor }}
        >
          {topImage ? imageModal() : noImageModal()}
        </div>
        {children}
        <div className={styles.footer}>
          <div
            className={
              stackedButtons ? styles.stacked_buttons : styles.buttons_container
            }
          >
            {showButtons ? (
              <>
                {confirmFirst ? (
                  <>
                    {confirmButton} {cancelButtonProp || cancelButton}
                  </>
                ) : (
                  <>
                    {cancelButtonProp || cancelButton} {confirmButton}
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
  return reactDom.createPortal(modal, document.body);
};

export default Modal;
