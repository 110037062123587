import { FormProvider, useForm } from "react-hook-form";
import styles from "./styles.module.scss";
import utilStyles from "../utilStyles.module.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  dayHours,
  hoursWithLables,
  useSettingsValues,
} from "../../../utils/timeUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  attendance_buttons,
  cancellation_buttons,
  generateCurrencySymbol,
} from "./utils";
import StateDayPicker from "../../../components/Programs/StateDayPicker";
import SelectBox from "../../../components/OptionButtonGroup/SelectBox";
import FormInput from "../../../components/Programs/FormInput";
import Label from "../../../components/Programs/Label";
import ButtonsContainer from "../../../components/Programs/Buttons/ButtonsContainer";
import SaveBar from "../../Sessions/SaveBar";
import { ProgramState } from "../../../reducers/scheduling/program";
import { RootState } from "typedefs";
import { isEmpty, isNull } from "lodash";
import { setPage } from "../../../actions/scheduling/program";
import {
  schedulingFetchPatch,
  schedulingFetchPost,
} from "../../../utils/request/scheduling/fetch";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import InformationModal from "app/components/Programs/InformationModal";
import { useTranslation } from "react-i18next";
import upload from "app/utils/uploader";
import CalendarX from "../../../../public/images/calendar-x-red.svg";

const PricingOptions = ({
  thumbnail,
  header,
  headerChanged,
  thumbnailChanged,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { cut_off_days, cut_off_list } = useSettingsValues();
  const dispatch = useDispatch();
  const pricingState = useSelector(
    (state: RootState): ProgramState => state.program
  );
  const currencyLabel = pricingState?.edit
    ? pricingState?.currency
    : generateCurrencySymbol(pricingState?.currency);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiErrorText, setApiErrorText] = useState("");
  const methods = useForm({
    defaultValues: {
      capacity: pricingState.capacity,
      price: pricingState.price,
    },
  });
  const [registrationOpenTime, setRegistrationOpenTime] = useState(
    pricingState.registrationOpenTime
  );
  const [registrationCloseTime, setRegistrationCloseTime] = useState(
    pricingState.registrationCloseTime
  );
  const [registrationOpenDate, setRegistrationOpenDate] = useState(
    pricingState.registrationOpenDate
  );
  const [registrationCloseDate, setRegistrationCloseDate] = useState(
    pricingState.registrationCloseDate
  );
  const attendanceOptionSelector = () => {
    if (pricingState.waitlist || isNull(pricingState.waitlist)) {
      return attendance_buttons[0];
    }
    return attendance_buttons[1];
  };
  const [attendanceOption, setAttendanceOption] = useState(
    attendanceOptionSelector()
  );
  const [cancellationOption, setCancellationOption] = useState(
    pricingState.cutOffType === "static"
      ? cancellation_buttons[1]
      : cancellation_buttons[0]
  );
  const [cutOffDay, setCutOffDay] = useState(
    pricingState.cutOffDay || { value: 24, label: t("1 Day") }
  );
  const [cutOffTime, setCutOffTime] = useState(
    pricingState.cutOffTime || {
      value: 0,
      label: "12:00 AM",
    }
  );
  const [formErrors, setFormErrors] = useState({
    registrationOpenTime: false,
    registrationCloseTime: false,
    registrationOpenDate: false,
    registrationCloseDate: false,
    start_time: false,
    static: false,
  });

  const [loading, setLoading] = useState(false);

  const errorMessage = t("This field is required");

  const handleDateChange = (value, label) => {
    label === "open"
      ? setRegistrationOpenDate(value)
      : setRegistrationCloseDate(value);
  };

  const handleTimeChange = (time, label) => {
    label === "registrationOpen"
      ? setRegistrationOpenTime(time)
      : setRegistrationCloseTime(time);
  };

  const handleCutOffButtonChange = (value) => {
    setCancellationOption(value);
    value.value === 0
      ? setCutOffDay({ value: 0, label: t("Day Of") })
      : setCutOffDay({ value: 24, label: t("1 Day") });
  };

  const handleCutOffChange = (value, label) => {
    label === "static" ? setCutOffDay(value) : setCutOffTime(value);
  };
  const registrationOpenCalendar = useMemo(
    () => (
      <StateDayPicker
        onChange={(value) => handleDateChange(value, "open")}
        date={new Date(registrationOpenDate)}
        openToDate={registrationOpenDate === null ? new Date() : null}
      />
    ),
    [registrationOpenDate]
  );

  const registrationCloseCalendar = useMemo(
    () => (
      <StateDayPicker
        onChange={(value) => handleDateChange(value, "close")}
        date={new Date(registrationCloseDate)}
        openToDate={registrationCloseDate === null ? new Date() : null}
      />
    ),
    [registrationCloseDate]
  );

  const checkErrors = useCallback(() => {
    let hasErrors = false;
    const newErrors = {
      registrationOpenTime: false,
      registrationCloseTime: false,
      registrationOpenDate: false,
      registrationCloseDate: false,
      start_time: false,
      static: false,
    };
    if (registrationOpenTime === null) {
      newErrors.registrationOpenTime = true;
      hasErrors = true;
    }
    if (registrationCloseTime === null) {
      newErrors.registrationCloseTime = true;
      hasErrors = true;
    }
    if (registrationOpenDate === null) {
      newErrors.registrationOpenDate = true;
      hasErrors = true;
    }
    if (registrationCloseDate === null) {
      newErrors.registrationCloseDate = true;
      hasErrors = true;
    }
    if (cutOffDay === null) {
      newErrors.start_time = true;
      hasErrors = true;
    }
    if (cancellationOption.value === 1 && cutOffTime === null) {
      newErrors.static = true;
      hasErrors = true;
    }

    setFormErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

    return hasErrors;
  }, [
    registrationCloseDate,
    registrationCloseTime,
    registrationOpenDate,
    registrationOpenTime,
    cutOffDay,
    cutOffTime,
    cancellationOption,
  ]);

  useEffect(() => {
    if (!isEmpty(methods.formState.errors)) {
      checkErrors();
    }
  }, [methods.formState.errors, checkErrors]);

  const constructDate = (date, hour) => {
    let newDate = DateTime.fromISO(date).setZone(pricingState.timeZone, {
      keepLocalTime: true,
    });

    const [hours, minutes] = hour.split(":");
    newDate = newDate.set({
      hour: parseInt(hours, 10),
      minute: parseInt(minutes, 10),
    });
    return newDate.toISO();
  };

  const checkRegistrationErrors = () => {
    if (
      registrationCloseDate < registrationOpenDate ||
      (registrationCloseDate === registrationOpenDate &&
        registrationCloseTime?.value <= registrationOpenTime?.value)
    ) {
      setShowErrorModal(true);
      return true;
    }
    return false;
  };

  const selectURL = (stateURL, newURL) => {
    if (stateURL?.substring(0, 5) === "blob:" || !stateURL) {
      return newURL;
    } else {
      return stateURL;
    }
  };

  const handleBack = () => {
    dispatch(setPage(2));
  };

  const onSubmit = async (data) => {
    const cancellationTime =
      cancellationOption.value === 0
        ? cutOffDay.value
        : 24 * cutOffDay.value + cutOffTime.value;
    const externalErrors = checkErrors();
    if (externalErrors) {
      return;
    }
    const registrationErrors = checkRegistrationErrors();
    if (registrationErrors) {
      return;
    }

    setLoading(true);
    let event = {
      start_time: constructDate(
        pricingState.date,
        pricingState.startTime?.value
      ),
      end_time: constructDate(pricingState.date, pricingState.endTime?.value),
      repeat: null,
      until_date: null,
    };
    if (pricingState.repeat && pricingState.repeat?.value !== "Never") {
      event = { ...event, repeat: pricingState.repeat.value.toLowerCase() };
    }
    if (pricingState.untilDate) {
      event = {
        ...event,
        until_date: DateTime.fromISO(pricingState.untilDate.toString())
          .setZone("utc", {
            keepLocalTime: true,
          })
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toISO(),
      };
    }

    const newTags = pricingState.tags
      ?.map((item) => item?.__isNew__ && item.label)
      .filter((item) => item);

    const selectedTags = pricingState.tags
      ?.map((item) => !item?.__isNew__ && item.value)
      .filter((item) => item);

    let thumbnail_url: string = "";
    let header_url: string = "";
    if (thumbnail && thumbnailChanged) {
      const uploadResult = await upload(
        thumbnail,
        thumbnail.name,
        thumbnail.type,
        undefined
      );
      thumbnail_url = uploadResult.url;
    }
    if (header && headerChanged) {
      const uploadResult = await upload(
        header,
        header.name,
        header.type,
        undefined
      );
      header_url = uploadResult.url;
    }

    const body = {
      attendance: pricingState.type.toLowerCase(),
      category: pricingState.category,
      title: pricingState.title,
      subtitle: pricingState.subtitle,
      event,
      location: pricingState.location,
      email: pricingState.email,
      phone: pricingState.phone,
      short_description: pricingState.shortDescription,
      long_description: pricingState.longDescription,
      program_tag_ids: selectedTags,
      new_tag_names: newTags,
      coach_ids: pricingState.coachIds,
      price: data.price,
      capacity: parseInt(data.capacity, 10),
      with_waitlist: attendanceOption?.value === 0 ? true : false,
      registration_start_time: constructDate(
        registrationOpenDate,
        registrationOpenTime?.value
      ),
      registration_end_time: constructDate(
        registrationCloseDate,
        registrationCloseTime?.value
      ),
      cancellation_cut_off_time: cancellationTime,
      cancellation_cut_off_type:
        cancellationOption.value === 0 ? "start_time" : "static",
      privacy: pricingState.privacy,
      external_thumbnail_url: selectURL(
        pricingState.thumbnailURL,
        thumbnail_url
      ),
      external_header_url: selectURL(pricingState.headerURL, header_url),
    };

    pricingState.edit
      ? schedulingFetchPatch(`/programs/${pricingState.id}`, body)
          .then(() => {
            history.push("/programs");
          })
          .catch((error) => {
            setApiError(true);
            setLoading(false);
            if (error.errors[0].details) {
              setApiErrorText(error.errors[0].details[0].message?.text);
            } else {
              setApiErrorText(error.errors[0]?.detail);
            }
          })
      : schedulingFetchPost(`/programs`, body)
          .then(() => {
            history.push("/programs");
          })
          .catch((error) => {
            setApiError(true);
            setLoading(false);
            if (error.errors[0].details) {
              setApiErrorText(error.errors[0].details[0].message?.text);
            } else {
              setApiErrorText(error.errors[0]?.detail);
            }
          });
  };

  const cutOffExtraOptions = useMemo(() => {
    const extraSpace = cancellationOption?.value === 1;
    return (
      <div className={extraSpace ? styles.pricing_row_with_gap : ""}>
        <div className={extraSpace ? styles.pricing_item : ""}>
          <SelectBox
            options={extraSpace ? cut_off_days : cut_off_list}
            value={cutOffDay}
            setValue={(selection) => handleCutOffChange(selection, "static")}
            containerStyle={styles.mt_8}
            customColor="#e6e6e6"
          />
        </div>
        {cancellationOption?.value === 1 && (
          <div className={styles.pricing_item}>
            <SelectBox
              options={dayHours()}
              value={cutOffTime}
              setValue={(selection) => handleCutOffChange(selection, "time")}
              containerStyle={styles.mt_8}
              customColor="#e6e6e6"
            />
          </div>
        )}
      </div>
    );
  }, [cancellationOption, cutOffDay, cutOffTime, cut_off_days, cut_off_list]);

  return (
    <div
      className={`${styles.container} ${styles.pricing_heigth} calendar-page-container`}
    >
      {showErrorModal && (
        <InformationModal
          topImage={CalendarX}
          handleAction={() => setShowErrorModal(false)}
          title={t("Error")}
          subtitle={t(
            "The Registration Closes cannot be set prior to the Registration Opens."
          )}
          width="500px"
        />
      )}
      {apiError && (
        <InformationModal
          topImage={CalendarX}
          handleAction={() => setApiError(false)}
          title={t("Error")}
          subtitle={apiErrorText}
          width="400px"
        />
      )}
      <FormProvider {...methods}>
        <form id="programForm">
          <div className={styles.pricing_container}>
            <div className={`${utilStyles.column} ${styles.pricing_section}`}>
              <label className={styles.gray_text}>
                {t("Reservation Policy")}
              </label>
              <div className={styles.horizontal_line} />
              <div className={styles.column}>
                <div className={styles.pricing_item}>
                  <FormInput
                    tagTitle={t("Capacity")}
                    placeholder={t("Max Number of Athletes")}
                    fieldName="capacity"
                    isNumberOnly={true}
                    isRequired={true}
                  />
                </div>
                <div className={`${styles.pricing_item} `}>
                  <Label
                    text={t("Waitlist")}
                    required={true}
                    item={(
                      <ButtonsContainer
                        customStyle={styles.pricing_item}
                        items={attendance_buttons}
                        containerStyle={styles.option_button_group}
                        openCalendarSelected={attendanceOption}
                        setOpenCalendarSelected={setAttendanceOption}
                      />
                    )}
                    containerStyle={styles.label_container}
                  />
                </div>
                <div className={`${styles.pricing_row} `}>
                  <div className={styles.pricing_item}>
                    <Label
                      text={t("Registration Opens")}
                      required={true}
                      item={registrationOpenCalendar}
                      error={formErrors.registrationOpenDate && errorMessage}
                    />
                  </div>
                  <div className={styles.pricing_item}>
                    <Label
                      text={t("Time")}
                      required={true}
                      error={formErrors.registrationOpenTime && errorMessage}
                      item={(
                        <SelectBox
                          options={hoursWithLables}
                          value={registrationOpenTime}
                          setValue={(time) =>
                            handleTimeChange(time, "registrationOpen")
                          }
                          customColor="#e6e6e6"
                          containerStyle={styles.no_padding}
                          customControl={{
                            height: "40px",
                            borderRadius: "8px",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={`${styles.pricing_row} `}>
                  <div className={styles.pricing_item}>
                    <Label
                      text={t("Registration Closes")}
                      required={true}
                      error={formErrors.registrationCloseDate && errorMessage}
                      item={registrationCloseCalendar}
                    />
                  </div>
                  <div className={styles.pricing_item}>
                    <Label
                      text={t("Time")}
                      required={true}
                      error={formErrors.registrationCloseTime && errorMessage}
                      item={(
                        <SelectBox
                          options={hoursWithLables}
                          value={registrationCloseTime}
                          setValue={(time) =>
                            handleTimeChange(time, "registrationClose")
                          }
                          customColor="#e6e6e6"
                          containerStyle={styles.no_padding}
                          customControl={{
                            height: "40px",
                            borderRadius: "8px",
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className={`${styles.pricing_row} `}>
                  <div className={styles.pricing_item}>
                    <Label
                      text={t("Cancellation Cut-off Time")}
                      required={true}
                      item={(
                        <ButtonsContainer
                          customStyle={styles.pricing_item}
                          items={cancellation_buttons}
                          containerStyle={styles.option_button_group}
                          openCalendarSelected={cancellationOption}
                          setOpenCalendarSelected={handleCutOffButtonChange}
                        />
                      )}
                      containerStyle={styles.label_container}
                    />
                  </div>
                  <div className={styles.pricing_item}>
                    <Label
                      error={
                        cancellationOption.value === 0
                          ? formErrors.start_time && errorMessage
                          : formErrors.static && errorMessage
                      }
                      text={cancellationOption?.label}
                      required={true}
                      item={cutOffExtraOptions}
                      containerStyle={styles.no_padding}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.pricing_vertical_line} />
            <div className={`${utilStyles.column} ${styles.contact_section}`}>
              <label className={styles.gray_text}>{t("Pricing")}</label>
              <div className={styles.horizontal_line} />
              <div className={utilStyles.column}>
                <FormInput
                  tagTitle={t("Price")}
                  fieldName="price"
                  isRequired={true}
                  addDollar={true}
                  currency={{ currency: currencyLabel }}
                />
              </div>
            </div>
          </div>
        </form>
        <SaveBar
          submitDisabled={loading}
          onCancel={handleBack}
          onSubmit={methods.handleSubmit(onSubmit)}
          cancelLabel={t("Back")}
          showCancel
          submitLabel={loading ? (t("Saving") + "...") : t("Save")}
        />
      </FormProvider>
    </div>
  );
};

export default PricingOptions;
