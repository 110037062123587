import { fetchGet, fetchPost, request } from "app/utils/request";
import { NotificationsModel, TotalUnseenModel } from "app/models";
import { action, ActionType } from "typesafe-actions";
import { Dispatch } from "react";
import { NotificationState } from "app/reducers/notification";
import config from "config/environment";

const fetchSuccess = (notification: NotificationsModel[]) =>
  action("@notification.fetch.success", notification);
const unreadNotifications = (notification: {
  notifications?: NotificationsModel[];
  unreadNotificationsCount?: number;
}) => action("@notification.fetch.unreadNotifications", notification);
export const paginatedNotifications = (notification?: NotificationsModel[]) =>
  action("@notification.fetch.paginatedData", notification);
const fetchPending = () => action("@notification.fetch.pending");
const fetchError = (err: string) => action("@notification.fetch.error", err);
const seenCount = (count: number) => action("@notification.seenCount", count);
const totalUnseen = (data: TotalUnseenModel) =>
  action("@notification.totalUnseen", data);
export const hasMore = (hasMore: boolean) =>
  action("@notification.fetch.hasMore", hasMore);
export const currentPage = (currentPage: number) =>
  action("@notification.fetch.currentPage", currentPage);
export const clear = () => action("@notification.clear");

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  unreadNotifications,
  paginatedNotifications,
  seenCount,
  totalUnseen,
  hasMore,
  currentPage,
  clear,
};

export type NotificationAction = ActionType<typeof thisActions>;

export const getTotalUnseen = () => {
  return async (dispatch: Dispatch<TotalUnseenModel>) => {
    const url = `${config.API_SERVER}/api/v2/notifications/total`;

    try {
      const res = await request(url, "GET", {}, { version: 2 });
      dispatch(seenCount(res?.total));
      dispatch(totalUnseen(res));
    } catch (err) {
      console.error("Failed to get total unseen notifications:", err);
    }
  };
};

export const markAllSeen = () => {
  return async () => {
    const url = `${config.API_SERVER}/api/v2/notifications/seen`;

    try {
      await request(url, "POST", {}, { version: 2 });
    } catch (err) {
      console.error("Failed to mark all notifications as seen:", err);
    }
  };
};

export const markAllRead = () => {
  return async (dispatch: Dispatch<NotificationAction>) => {
    try {
      await fetchPost<{
        total: number;
        notifications: number;
        invitations: number;
      }>("notifications/read", {}, { version: 1 });

      dispatch(seenCount(0));
    } catch (error) {
      console.error("Failed to mark all as read:", error);
    }
  };
};

export const fetchNotifications = (
  params?: { [key: string]: any },
  signal?: AbortSignal,
) => {
  return async (
    dispatch: Dispatch<NotificationAction>,
    getState?: () => NotificationState,
  ) => {
    dispatch(fetchPending());

    // @ts-ignore
    const page = getState()?.notification?.currentPage || 1;

    try {
      const res: any = await fetchGet<NotificationsModel>(
        "notifications",
        params || {},
        {},
        signal,
      );

      if (res?.notifications?.length < params?.perPage) {
        dispatch(hasMore(false));
      }

      dispatch(currentPage(page + 1));
      dispatch(fetchSuccess(res));

      return res;
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
      dispatch(fetchError(error));
      throw error;
    }
  };
};

export const actions = { fetchNotifications };
