/**
 * @module Components.Routes
 *
 */
import React, { useState, useEffect, useCallback } from "react";

import PagePanel from "app/components/PagePanel";

import styles from "./styles.module.scss";
import ProgramIcon from "../../../../public/images/group-program.svg";
import { useHistory } from "react-router-dom";
import {
  schedulingFetchDelete,
  schedulingFetchGet,
} from "../../../utils/request/scheduling/fetch";
import ItemListView from "../../../components/ItemListView";
import ProgramItem from "../../../components/Programs/ProgramItem";
import ProgramFilters from "app/components/Programs/ProgramFilters";
import FilterImage from "../../../../public/images/filter.svg";
import SearchImage from "../../../../public/images/search.svg";
import { debounce, truncate } from "lodash";
import { useTranslation } from "react-i18next";
import TextAreaModal from "app/components/Programs/TextAreaModal";
import CalendarX from "../../../../public/images/calendar-x-red.svg";
import { ProgramState } from "app/reducers/scheduling/program";
import { useDispatch, useSelector } from "react-redux";
import { resetProgramSettings } from "app/actions/scheduling/program";
import { Loading } from "app/components/Wrappers";
import { RootState } from "typedefs";
import UserModel from "app/models/UserModel";

interface Programs {
  active: ProgramState[];
  completed: ProgramState[];
}

export default function ManageCoachPrograms() {
  const { t } = useTranslation();
  const [refetch, setRefetch] = useState(true);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const [programCategories, setProgramCategories] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [programs, setPrograms] = useState<Programs>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [programId, setProgramId] = useState(null);
  const [facilityId, setFacilityId] = useState(null);
  const dispatch = useDispatch();
  const userState = useSelector(
    (state: RootState): UserModel => state.user.data
  );

  const initialFilters = {
    name: "",
    category: null,
    attendance: null,
    coach: null,
    registration_status: null,
    tags: [],
  };
  const [filters, setFilters] = useState(initialFilters);
  useEffect(() => {
    schedulingFetchGet("/program_categories").then((response) => {
      const categories = response?.map((item, index) => {
        return { label: item, value: index };
      });
      setProgramCategories(categories);
    });
    schedulingFetchGet(`/coach_profiles/${userState?.id}`).then((response) => {
      setFacilityId(response?.facilityId);
    });
  }, []);

  useEffect(() => {
    if (refetch) {
      const selectedFilters = {
        name,
        category: filters.category?.label,
        attendance: filters.attendance?.label.toLowerCase(),
        coach_profile_id: filters.coach ? [filters.coach?.id] : null,
        registration_status: filters.registration_status?.value,
        tag_ids:
          filters.tags?.length > 0
            ? filters.tags.map((item) => item?.value)
            : null,
      };
      schedulingFetchGet("/programs", selectedFilters).then((response) => {
        setPrograms(response.data);
        setRefetch(false);
        setLoading(false);
      });
    }
  }, [refetch, filters, name]);
  const history = useHistory();

  const onAddPress = () => {
    dispatch(resetProgramSettings());
    history.push("/programs/build");
  };

  const handleInputChange = (event) => {
    debouncedFunction(event.target.value);
  };

  const renderEmpty = (programType) => {
    return (
      <div className={styles.empty}>
        <div>
          <img
            style={{
              width: "30px",
              height: "30px",
            }}
            src={ProgramIcon}
          />
        </div>
        <div className={styles.text_container}>
          <p className={styles.bold}>{`${
            programType.charAt(0).toUpperCase() + programType.slice(1)
          } Programs`}</p>
          <p>{t(`You have no ${programType} group programs yet.`)}</p>
        </div>
      </div>
    );
  };

  const onApplyFilters = useCallback(() => {
    setRefetch(true);
    setShowFilters(false);
    return null;
  }, []);

  const debouncedResults = useCallback(
    () =>
      debounce((value) => {
        setName(value);
        onApplyFilters();
      }, 1000),
    [onApplyFilters]
  );

  const debouncedFunction = debouncedResults();

  const handleDeletePopup = (id) => {
    setDeleteModal(true);
    setProgramId(id);
  };

  const handleDeleteProgram = () => {
    schedulingFetchDelete(`/programs/${programId}`, {
      apply_to_recurrence: "all",
      reason: deleteMessage,
    }).then(() => {
      window.location.reload();
    });
  };

  const renderTab = (programList, isActive) => {
    if (loading) {
      return <Loading isLoading loadType="spinner" />;
    }
    return programList?.length > 0 ? (
      <div className={styles.padding_16}>
        <ItemListView
          Item={ProgramItem}
          isActive={isActive}
          objectList={programList}
          selectedItem={selectedItem}
          handleDelete={(id) => handleDeletePopup(id)}
          setSelectedItem={setSelectedItem}
        />
      </div>
    ) : (
      renderEmpty(selectedTab === 0 ? "active" : "completed")
    );
  };
  return (
    <div className={styles.position}>
      <PagePanel
        title={t("Programs")}
        headerChildren={(
          <div>
            <button
              onClick={onAddPress}
              type="button"
              className="btn btn-primary"
            >
              {`+ ${t("Add Program")}`}
            </button>
          </div>
        )}
      />

      <div className={`${styles.empty_container} calendar-page-container`}>
        <div>
          <div className={styles.tabs_container}>
            <nav className={styles.navbar}>
              <ul className={styles.navList}>
                <li
                  className={`${styles.navItem} ${
                    selectedTab === 0 && styles.active
                  }`}
                  onClick={() => setSelectedTab(0)}
                >
                  {`${t("Active")} (${programs?.active?.length || 0})`}
                </li>
                <li
                  className={`${styles.navItem} ${
                    selectedTab === 1 && styles.active
                  }`}
                  onClick={() => setSelectedTab(1)}
                >
                  {`${t("Completed")} (${programs?.completed?.length || 0})`}
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className={styles.line_container}>
          <div className={styles.search_container}>
            <input
              className={styles.search}
              placeholder={`${t("Search Program")}...`}
              onChange={handleInputChange}
            />
            <img src={SearchImage} />
          </div>
          <div
            className={styles.filter_button}
            onClick={() => setShowFilters((old) => !old)}
          >
            <img src={FilterImage} />
          </div>
        </div>
        {renderTab(
          selectedTab === 0 ? programs?.active : programs?.completed,
          selectedTab === 0 ? true : false
        )}
      </div>
      {showFilters && (
        <ProgramFilters
          facilityId={facilityId}
          filters={filters}
          setFilters={setFilters}
          onApply={onApplyFilters}
          programCategories={programCategories}
          onReset={() => setFilters(initialFilters)}
        />
      )}
      {deleteModal && (
        <TextAreaModal
          topImage={CalendarX}
          stackedButtons
          showCloseIcon
          title={t("Delete Program?")}
          subTitle={`${t("Are you sure you want to delete")} ${truncate(
            selectedItem.title,
            { length: 25 }
          )}? ${t(
            "All the registered, waitlisted students and coaches will be notified via email."
          )}`}
          placeholder={t(
            "Ex: Today's clinic is canceled due to inclement weather."
          )}
          width="400px"
          value={deleteMessage}
          setValue={setDeleteMessage}
          cancelText={t("No, keep it")}
          confirmFirst
          confirmText={t("Yes, delete it")}
          setShowModal={setDeleteModal}
          handleAction={handleDeleteProgram}
        />
      )}
    </div>
  );
}
