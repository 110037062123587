import React from "react";
import { DateTime } from "luxon";
import styles from "./styles.module.scss";

import { EventModel } from "app/models/EventModel";

type PropsDef = {
  startOfWeek: Date;
  events: EventModel[];
  view: any;
  onDateChange: (date: DateTime) => void;
  selectedDate: DateTime | null;
};

const DayHeader = (props: PropsDef) => {
  const { startOfWeek, events, view, onDateChange, selectedDate } = props;
  const startDate = DateTime.fromJSDate(startOfWeek);

  const daysOfWeek = Array.from({ length: 7 }, (_, i) =>
    startDate.plus({ days: i })
  );

  const renderDay = (date: DateTime) => {
    const tzDate = date.setZone(view.dateEnv.timeZone);
    const today = DateTime.now().setZone(view.dateEnv.timeZone);

    const isToday = tzDate.hasSame(today, "day");
    const isSelected = selectedDate && tzDate.hasSame(selectedDate, "day");

    const hasEvent = events.some((event) => {
      const eventTzDate = DateTime.fromJSDate(new Date(event.date)).setZone(
        view.dateEnv.timeZone
      );
      return eventTzDate.hasSame(tzDate, "day");
    });

    const dayClassName = `${styles.number_container} ${
      isSelected ? styles.selected_day : ""
    }`;
    const numberClassName = `${styles.number} ${
      isToday && !isSelected ? styles.today_number : ""
    } ${isSelected ? styles.number_white : ""}`;

    const eventDot = <span className={styles.dot}></span>;

    const dayName = tzDate.toFormat("ccc");
    const dayNumber = tzDate.toFormat("d");

    const handleDayClick = () => {
      onDateChange(tzDate);
    };

    return (
      <div
        key={tzDate.toISO()}
        className={styles.day_container}
        onClick={handleDayClick}
      >
        <p className={styles.day_name}>{dayName}</p>
        <div className={dayClassName}>
          <p className={numberClassName}>{dayNumber}</p>
          {hasEvent ? eventDot : null}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.week_container}>
      {daysOfWeek.map((date) => renderDay(date))}
    </div>
  );
};

export default DayHeader;
