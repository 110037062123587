import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import share_link_icon from "../../../../../../public/images/share-link-icon.png";
import Button from "app/components/Button/ButtonVariant";
import { useActions } from "app/utils/hooks";
import { fetchInviteUrl } from "app/actions/team-group-member-import";
import { cls } from "app/utils";
import useCopyToClipboard from "app/utils/hooks/useCopyToClipboard";
import { Loading } from "app/components/Wrappers";

type memberType = "coach" | "player" | "viewer";

interface Props {
  id: string;
  isOpen?: boolean;
  onClose?: () => void;
}

const InviteWithUrl: React.FC<Props> = ({ id, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const inviteUrlActions = useActions({ fetchInviteUrl });
  const { copied, copyToClipboard } = useCopyToClipboard();

  const [newChannelData, setNewChannelData] = useState<memberType>("player");
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    inviteUrlActions.fetchInviteUrl(id, newChannelData).then((res) => {
      setUrl(res.shortLink);
    });

    return () => {
      setUrl(null);
    };
  }, [id]);

  const ChannelSettingsData = [
    {
      label: t("Coach/Admin (Invite with email only)"),
      value: "coach",
      description: t(
        "Can post and interact, manage the Group, manage Group members, and manage Channels.",
      ),
      disabled: true,
    },
    {
      label: t("Member"),
      value: "player",
      description: t("Can post and interact."),
    },
    {
      label: t("Viewer"),
      value: "viewer",
      description: t("View only."),
    },
  ];

  const groupTypeHandler = (value: memberType) => {
    setNewChannelData(value);
    setLoading(true);
    inviteUrlActions
      .fetchInviteUrl(id, value)
      .then((res: { shortLink: string }) => {
        setUrl(res.shortLink);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //   const onSelectChange = (role: "coach" | "player" | "viewer") => {
  //     inviteUrlActions.fetchInviteUrl(id, role);
  //   };
  const onCopyHandler = (value: string) => {
    copyToClipboard(value);
  };

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Share a Link")}
    >
      <div className={styles.container}>
        <img
          src={share_link_icon}
          alt="group icon"
          className={styles.group_icon}
        />
        <span className={styles.content_info}>
          <b>{t("Share a Link for Members to Join")}</b>
          <p>
            {t(
              "Select a role, copy the link below, and share to invite new members.",
            )}
          </p>
        </span>
        <ul className={cls("stack-list-items")}>
          {ChannelSettingsData.map((setting) => (
            <li
              key={setting.label}
              className={cls("stack-list-item selectable-item", {
                active: newChannelData === setting.value,
                ["disabled"]: setting?.disabled,
              })}
            >
              <label
                className="selectable-item-trigger"
                htmlFor={setting.label}
              ></label>
              <div className="selectable-item-control">
                <div className="radio">
                  <input
                    type="radio"
                    name="inviteMemberType"
                    id={setting.label}
                    value={setting.value}
                    defaultChecked={newChannelData === setting.value}
                    onClick={() => groupTypeHandler(setting.value as any)}
                    disabled={setting?.disabled}
                  />
                  <label className="opaque" htmlFor={setting.label}></label>
                </div>
              </div>

              <div className="selectable-item-details">
                <div className="selectable-item-label ng-binding">
                  {setting.label}
                </div>
                <div className="selectable-item-summary ng-binding">
                  {setting.description}
                </div>
              </div>
            </li>
          ))}
        </ul>
        {url && (
          <div className={styles.copy_btn_wrapper}>
            {loading ? (
              <Loading isLoading loadType="spinner" />
            ) : (
              <p onClick={() => !copied && onCopyHandler(url)}>{url}</p>
            )}
            <Button
              disabled={loading || copied}
              buttonType="primary"
              title={!copied ? t("Copy Link") : t("Copied...")}
              icon={<i className="ico ico-copy" />}
              onClickFunc={() => onCopyHandler(url)}
              buttonStyles={styles.copy_btn}
            />
          </div>
        )}
        <div className={styles.done_btn}>
          <Button
            buttonType="secondary-outline"
            title={t("Done")}
            onClickFunc={onClose}
          />
        </div>
      </div>
    </ModalLayoutWithTitle>
  );
};

export default InviteWithUrl;
