import React from 'react';
import Async from 'react-select/async';
import { debounce, uniqBy } from 'lodash';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { schedulingFetchGet } from 'app/utils/request/scheduling/fetch';
import Option from './Option';

import { SelectStyles } from 'app/routes/Calendar/EventForm/constants';
import styles from './styles.module.scss';

type PropsDef = {
  name: string;
  control: any;
  facilityId: string;
};

const StudentSelector = React.forwardRef((props: PropsDef, _ref) => {
  const { name, control, facilityId } = props;
  const { t } = useTranslation();

  const { field } = useController({ name, control });

  const onSelect = (selectedStudent: any) => {
    const filteredStudents = field.value.filter(s => s.id !== selectedStudent.id);

    if (filteredStudents.length === field.value.length) { // adding student
      field.onChange(uniqBy(field.value.concat([selectedStudent]), 'id'));
    } else {
      field.onChange(filteredStudents);
    }
  };

  const onRemove = (studentId) => {
    const newStudents = field.value.filter(student => {
      return student.id !== studentId;
    });

    field.onChange(newStudents);
  };

  const loadOptions = debounce((inputValue, setValue) => {
    const exceptIds = field.value.map(s => s.id);
    schedulingFetchGet(`/facilities/${facilityId}/student_profiles`, { query: inputValue, per_page: 40, except: exceptIds })
      .then(setValue);
  }, 250);

  const SingleValue = () => {
    return <div>{field.value.length} selected</div>;
  };

  const studentList = field.value.map((student: any) => {
    return (
      <div key={student.id} className={styles.list_item}>
        <div className={styles.student_avatar}>
          <img className={styles.student_avatar} src={student.profilePicture} />
          <div className={styles.remove_item} onClick={() => onRemove(student.id)}>
              <i className="ico ico-x" />
          </div>
        </div>
        <span>{`${student.firstName} ${student.lastName}`}</span>
      </div>
    );
  });

  const hasStudents = field.value.length > 0;

  return (
    <div>
      <Async
        styles={SelectStyles}
        defaultOptions
        getOptionLabel={opt => `${opt.firstName} ${opt.lastName}`}
        getOptionValue={opt => opt.id}
        loadingMessage={() => null}
        components={{ Option, SingleValue, LoadingIndicator: () => null }}
        loadOptions={loadOptions}
        onChange={onSelect}
        placeholder={t('Select Athletes')}
        value={field.value}
      />



      {hasStudents && (<div className={styles.list_container}>{studentList}</div>)}
    </div>
  );
});

export default StudentSelector;
