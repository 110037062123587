import {
  fetchAppliedTemplates,
  clearAppliedTemplates,
} from "app/actions/spaceApplication";
import { useActions, useSelector } from "app/utils/hooks";
import styles from "./styles.module.scss";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { GridItem } from "../../../components/Layouts";
import ContentCard from "../../../components/ContentCard";
import { RootState } from "typedefs";
import { Loading } from "../../../components/Wrappers";
import no_team_png from "app/images/null-posts.svg";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import TemplatePageLayout from "app/components/Layouts/TemplatePageLayout";
import { shallowEqual } from "react-redux";
import { t } from "app/i18n";

const TemplateHistory = () => {
  const { id: templateId } = useParams<{ id: string }>();

  const appliedDataAction = useActions({
    fetchAppliedTemplates,
    clearAppliedTemplates,
  });

  const [allData, setAllData] = useState<any[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const showPerPage = 7;

  const history = useHistory();

  const route = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history]
  );

  const appliedData = useSelector((state: RootState) => state.spaceApplication, shallowEqual);

  const query = {
    space_template_id: templateId,
    page: currentPage,
    per_page: showPerPage,
  };

  const fetchData = async () => {
    try {
      const response: any = await appliedDataAction.fetchAppliedTemplates(
        query
      );
      if (response && response.payload && response.payload.length > 0) {
        setCurrentPage((prevPage) => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    setIsLoadingMore(true);
    return () => {
      appliedDataAction.clearAppliedTemplates();
      setIsLoadingMore(false);
    };
  }, [templateId, appliedDataAction]);

  const fetchMoreData = useCallback(() => {
    setIsLoadingMore(true);
    fetchData().then(() => setIsLoadingMore(false));
  }, [currentPage, appliedDataAction, showPerPage]);

  const onIntersect = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (
        entries[0].isIntersecting &&
        hasMore &&
        appliedData &&
        !appliedData.pending
      ) {
        fetchMoreData();
        setIsLoadingMore(true);
      }
    },
    [hasMore, fetchMoreData]
  );

  useEffect(() => {
    const options = {
      root: null as any,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(onIntersect, options);

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [onIntersect]);

  useEffect(() => {
    if (appliedData.data) {
      setAllData((prevData) => [...prevData, ...appliedData.data]);
      setIsLoadingMore(false);
    }
  }, [appliedData]);

  const onSpaceClickHandle = useCallback((object: string, locationObject) => {
    if (object === "space") {
      route(`/spaces/${locationObject.id}`);
    } else if (object === "team") {
      route(`/groups/${locationObject.id}`);
    } else {
      route(`/groups/${locationObject.team.id}/channels/${locationObject.id}`);
    }
  }, []);

  const getData = (val: Date) => {
    if (val) {
      const formattedDate = moment(val).format("MMMM D, YYYY");
      return formattedDate;
    } else {
      return null;
    }
  };

  return (
    <TemplatePageLayout>
      {appliedData?.data?.length > 0 && (
        <div className={styles.list}>
          <p>
            {t(`Here is a list of Spaces this Template has been applied to. You can
            manage Scheduled Posts for each of them inside a Space.`)}
          </p>
          {allData &&
            allData.length > 0 &&
            allData.map((item: any) => {
              if (
                item.spaces &&
                Array.isArray(item.spaces) &&
                item.spaces.length > 0
              ) {
                return item.spaces.map((space: any) => (
                  <div key={space.id}>
                    <GridItem className={styles.grid} key={space.id}>
                      <ContentCard
                        img={space.avatar}
                        title={space.athleteName}
                        subtitle={space.sport}
                        buttonName="Go to Space"
                        buttonStyles={styles.go_to_space_btn}
                        onClickButton={() => onSpaceClickHandle("space", space)}
                        date={getData(item.createdAt)}
                      />
                    </GridItem>
                  </div>
                ));
              } else if (
                item.teams &&
                Array.isArray(item.teams) &&
                item.teams.length > 0
              ) {
                return item.teams.map((team: any) => (
                  <div key={team.id}>
                    <GridItem className={styles.grid} key={team.id}>
                      <ContentCard
                        img={team && team.logo && team.logo}
                        icon={!team.logo && "team"}
                        title={team.name}
                        subtitle={team.sport}
                        buttonName="Go to Group"
                        buttonStyles={styles.go_to_space_btn}
                        onClickButton={() => onSpaceClickHandle("team", team)}
                        date={getData(item.createdAt)}
                      />
                    </GridItem>
                  </div>
                ));
              } else if (
                item.teamGroups &&
                Array.isArray(item.teamGroups) &&
                item.teamGroups.length > 0
              ) {
                return item.teamGroups.map((teamGroup: any) => (
                  <div key={teamGroup.id}>
                    <GridItem className={styles.grid} key={teamGroup.id}>
                      <ContentCard
                        img={teamGroup.logo && teamGroup.logo}
                        icon={!teamGroup.logo && "team"}
                        title={teamGroup.name}
                        subtitle={teamGroup.sport}
                        buttonName="Go to Channel"
                        buttonStyles={styles.go_to_space_btn}
                        onClickButton={() =>
                          onSpaceClickHandle("teamGroup", teamGroup)
                        }
                        date={getData(item.createdAt)}
                      />
                    </GridItem>
                  </div>
                ));
              } else {
                return null;
              }
            })}
        </div>
      )}
      {appliedData?.data?.length >= showPerPage &&
        (!appliedData?.pending || !isLoadingMore) && (
          <div ref={lastElementRef} />
        )}
      {appliedData?.data?.length === 0 && (
        <div className={styles.no_result}>
          <img src={no_team_png} alt="no history" />
          <h3>{t("No History Yet")}</h3>
          <p>{t("All history created by you will appear here")}</p>
        </div>
      )}
      {isLoadingMore && appliedData?.data?.length > 0 && (
        <Loading
          className={styles.list}
          isLoading={isLoadingMore}
          loadType="spinner"
        />
      )}
    </TemplatePageLayout>
  );
};

export default memo(TemplateHistory);
