import { useCallback, useEffect, useRef, useState } from "react";
import { useActions } from "app/utils/hooks";
import SpaceModel from "app/models/SpaceModel";
import { SpaceSelectProps } from "./index.types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  locations,
  spaceObj,
  applyTemplateToSpace,
} from "app/actions/applyTemplate";
import { RootState } from "typedefs";
import {
  clearSpace,
  clearSpaceForSearch,
  fetchSpaceMemberships,
  spacePage,
} from "app/actions/space";
import useDebounce from "app/utils/hooks/useDebounce";
import useInfiniteScroll from "react-infinite-scroll-hook";

const FORCE_PER_PAGE = 100;
const PER_PAGE = FORCE_PER_PAGE;

const useSpaceSelectModal = ({ onClose, templateId }: SpaceSelectProps) => {
  const dispatch = useDispatch();

  const spaceActions = useActions({
    fetchSpaceMemberships,
    clearSpaceForSearch,
    clearSpace,
  });
  const spaceIds = useSelector(
    (state: RootState) => state.applyTemplate.locations,
    shallowEqual,
  );
  const spacesArr = useSelector(
    (state: RootState) => state.applyTemplate.spaceObj,
    shallowEqual,
  );

  const applyTemplateActions = useActions({
    applyTemplateToSpace,
  });
  const [searchTerm, setSearch] = useState<string>("");
  const [selectedSpacesId, setSelectedSpacesId] = useState<string[]>(spaceIds);
  const [selectedSpace, setSelectedSpace] = useState<SpaceModel[]>(spacesArr);
  const [spacesData, setSpacesData] = useState(null);

  const onSearchSpace = useCallback((value: string) => {
    if (value != null && value.length > 0) {
      setSearch(value);
    } else {
      setSearch("");
    }
  }, []);

  const { page, query, pending, hasMore } = useSelector(
    (state: RootState) => state.space,
    shallowEqual,
  );

  const fetchData = async (page?: number) => {
    if (page && page === 1) {
      dispatch(spacePage(1));
      setSpacesData(null);
    }
    const fetchedSpaces = await fetchSpacesWithMemberships(
      abortController.current,
      page,
    );
    setSpacesData((prev: any) => [...(prev || []), ...fetchedSpaces]);
  };

  const debouncedSearch = useDebounce(searchTerm, 500);

  const abortController = useRef<AbortController | null>(null);

  const fetchSpacesWithMemberships = async (
    abort?: AbortController,
    force_page?: number,
  ): Promise<SpaceModel[]> => {
    try {
      const defaultQuery = {
        page: force_page ? force_page : page,
        ...query,
        force_per_page: FORCE_PER_PAGE,
        per_page: PER_PAGE,
        athlete_name: debouncedSearch,
      };

      const locationQuery = { ...defaultQuery };

      const res: SpaceModel[] = await spaceActions.fetchSpaceMemberships(
        locationQuery,
        abort ? abort.signal : undefined,
      );

      if (res && Array.isArray(res)) {
        return res;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    abortController.current = new AbortController();

    if (debouncedSearch?.length >= 3 || debouncedSearch?.length === 0) {
      setSpacesData(null);
      fetchData(1);
    }

    return () => {
      // spaceActions.clearSpaceForSearch();
      if (abortController.current) {
        abortController.current.abort();
      }
      dispatch(clearSpace());
    };
  }, [debouncedSearch]);

  const [containerRef] = useInfiniteScroll({
    loading: pending,
    hasNextPage: hasMore,
    onLoadMore: fetchData,
    rootMargin: "0px 100px 0px 0px",
  });

  // ____________________________________________

  const spaceSelectHandler = useCallback(
    (value: string, spaceObj: SpaceModel) => {
      setSelectedSpacesId((prevSelectedSpacesId) => {
        if (prevSelectedSpacesId.includes(value)) {
          return prevSelectedSpacesId.filter((val) => val !== value);
        } else {
          return [...prevSelectedSpacesId, value];
        }
      });
      setSelectedSpace((prevSelectedSpace) => {
        if (prevSelectedSpace.includes(spaceObj)) {
          return prevSelectedSpace.filter((val) => val !== spaceObj);
        } else {
          return [...prevSelectedSpace, spaceObj];
        }
      });
    },
    [selectedSpacesId, selectedSpace],
  );

  useEffect(() => {
    dispatch(locations(selectedSpacesId));
    dispatch(spaceObj(selectedSpace));
  }, [selectedSpacesId, selectedSpace]);

  const cancelHandler = () => {
    setSelectedSpacesId([]);
    setSelectedSpace([]);
    setSearch("");
    onClose();
  };

  const applyHandler = () => {
    applyTemplateActions.applyTemplateToSpace(selectedSpacesId, templateId);
    setSearch("");
    onClose();
  };

  return {
    spaceSelectHandler,
    cancelHandler,
    applyHandler,
    onSearchSpace,
    spacesData,
    containerRef,
    searchTerm,
    selectedSpacesId,
    pending,
  };
};

export default useSpaceSelectModal;
