import React from "react";
import styles from "./styles.module.scss";
import ModalLayout from "../Layouts/ModalLayout/ModalLayout";
import { cls } from "app/utils";
import closeIcon from "../../../public/images/close-icon.svg";
import { useTranslation } from "react-i18next";
import { useCreateNewChannelModel } from "./indexModel";
import { Loading } from "../Wrappers";
import { TemplateSelect } from "../inputs/SelectBox";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateNewChannelModal = ({ isOpen, onClose }: Props) => {
  const {
    ChannelSettingsData,
    newChannelData,
    handleNameChange,
    groupTypeHandler,
    handleDescriptionChange,
    handleTemplateSelect,
    createHandler,
    pending,
    createPending,
  } = useCreateNewChannelModel({ onClose });
  const { t } = useTranslation();

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.title_container}>
          <h1>{t("New Channel")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={onClose}
          />
        </div>
        <div className={styles.form_wrapper}>
          <div className={styles.input_wrapper}>
            <label htmlFor="channel_name">{t("Channel Name")}</label>
            <input
              type="text"
              name="channel_name"
              id="channel_name"
              required
              onChange={handleNameChange}
            />
          </div>

          <ul className={cls("stack-list-items")}>
            {ChannelSettingsData.map((setting) => (
              <li
                key={setting.label}
                className={cls("stack-list-item selectable-item", {
                  active: newChannelData.group_type === setting.value,
                })}
              >
                <label
                  className="selectable-item-trigger"
                  htmlFor={setting.value}
                ></label>
                <div className="selectable-item-control">
                  <div className="radio">
                    <input
                      type="radio"
                      name="groupType"
                      id={setting.value}
                      value={setting.value}
                      defaultChecked={
                        newChannelData.group_type === setting.value
                      }
                      onClick={() => groupTypeHandler(setting.value as any)}
                    />
                    <label className="opaque" htmlFor={setting.value}></label>
                  </div>
                </div>

                <div className="selectable-item-details">
                  <div className="selectable-item-label ng-binding">
                    {setting.label}
                  </div>
                  <div className="selectable-item-summary ng-binding">
                    {setting.description}
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className={styles.input_wrapper}>
            <label htmlFor="description">{t("Description")}</label>
            <input
              type="text"
              name="description"
              id="description"
              required
              onChange={handleDescriptionChange}
            />
          </div>

          <div className={styles.input_wrapper}>
            {/* {templpateOptions?.length > 0 && !pending && ( */}
            <div className={styles.selectbox_container}>
              <label className={styles.title} htmlFor="space_template">
                {t("Template")}
              </label>
              <TemplateSelect
                onChange={handleTemplateSelect}
                isClearable
                isSearchable
              />
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          <button
            className={cls("btn btn-outline-secondary", styles.button)}
            onClick={onClose}
          >
            {t("Cancel")}
          </button>
          <button
            disabled={!newChannelData.name || pending || createPending}
            className={cls("btn btn-primary", styles.button, {
              ["disabled"]:
                !newChannelData.name || newChannelData.name.trim().length === 0,
            })}
            onClick={createHandler}
          >
            {pending ? (
              <Loading isLoading={pending} loadType="spinner" />
            ) : (
              t("Create Channel")
            )}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default CreateNewChannelModal;
