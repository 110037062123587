import React, { useState, useEffect } from "react";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Select, { OptionsType, ValueType, StylesConfig } from "react-select";
import styles from "./styles.module.scss";
import ToolTip from "app/routes/Scheduling/ToolTip";
import CloseIcon from "../../../../../../public/images/close.svg";
import CheckMark from "../../../../../../public/images/checkmark.svg";
import { useTranslation } from 'react-i18next';

interface LessonType {
  id: number;
  name: string;
}

interface FormValues {
  id: string | number;
  rate: number | string;
  defaultRate: boolean;
  lessonTypes: LessonType[];
  defaultLessonTypeId: number | null;
  location: string;
  duration: string;
  title: string;
  _destroy?: boolean;
  active: boolean;
}

interface EditPricingProps {
  isOpen: boolean;
  onClose: () => void;
  item?: FormValues;
  lessonTypes: LessonType[];
  onEdit: (updatedItem: FormValues) => void;
  titles: string[];
  coachCurrencySymbol: string;
}

export default function EditPricing({
  isOpen,
  onClose,
  item,
  lessonTypes,
  onEdit,
  titles,
  coachCurrencySymbol,
}: EditPricingProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    trigger,
    watch,
  } = useForm<FormValues>();

  const [selectedLessonTypes1, setSelectedLessonTypes1] = useState<
    OptionsType<LessonType>
  >([]);
  const [selectedLessonType2, setSelectedLessonType2] =
    useState<ValueType<LessonType>>(null);
  const [titleError, setTitleError] = useState<string | null>(null);

  const selectedDefaultRate = watch("defaultRate");
  const { t } = useTranslation();

  useEffect(() => {
    let isMounted = true;

    if (item && isMounted) {
      setValue("id", item.id);
      setValue("title", item.title);
      setValue("duration", item.duration);
      setValue("rate", item.rate || "");
      setValue("lessonTypes", item.lessonTypes);
      setValue("defaultLessonTypeId", item.defaultLessonTypeId);
      setValue("location", item.location);
      setValue("defaultRate", item.defaultRate);

      const selectedLessons = item.lessonTypes.map((type) => ({
        id: type.id,
        name: type.name,
      }));
      setSelectedLessonTypes1(selectedLessons);

      const defaultLesson = lessonTypes.find(
        (l) => l.id === item.defaultLessonTypeId
      );
      setSelectedLessonType2(defaultLesson || null);
    }

    return () => {
      isMounted = false;
    };
  }, [item, lessonTypes, setValue]);

  const handleLessonType1Change = (
    selectedOptions: OptionsType<LessonType>
  ) => {
    const selectedOptionsArray = selectedOptions || [];
    setSelectedLessonTypes1(selectedOptionsArray);
    if (
      selectedLessonType2 &&
      !selectedOptionsArray.find(
        (option) => option.id === (selectedLessonType2 as LessonType).id
      )
    ) {
      setSelectedLessonType2(null);
    }
    setValue("lessonTypes", selectedOptionsArray as any);
    trigger("lessonTypes");
  };

  const handleLessonType2Change = (selectedOption: ValueType<LessonType>) => {
    setSelectedLessonType2(selectedOption);
    setValue(
      "defaultLessonTypeId",
      selectedOption ? (selectedOption as LessonType).id : null
    );
    trigger("defaultLessonTypeId");
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (
      item &&
      data.title !== item.title &&
      Array.isArray(titles) &&
      titles.includes(data.title)
    ) {
      setTitleError(t('Title must be unique'));
      return;
    } else {
      const formattedData = {
        ...data,
        lessonTypes: selectedLessonTypes1.map((option: LessonType) => ({
          id: option.id,
          name: option.name,
        })),
        defaultLessonTypeId:
          selectedLessonType2 && "id" in selectedLessonType2
            ? selectedLessonType2.id
            : null,
        rate: data.rate !== "" ? data.rate : 0,
        active: item.active,
      };

      onEdit(formattedData);
      onClose();
      reset({
        id: undefined,
        title: "",
        duration: "",
        rate: 0,
        lessonTypes: [],
        defaultLessonTypeId: null,
        location: "",
        defaultRate: false,
      });
      setSelectedLessonTypes1([]);
      setSelectedLessonType2(null);
      setTitleError("");
    }
  };

  const customStyles: StylesConfig = {
    control: (base: any) => ({
      ...base,
      background: "white",
      border: "1px solid #ccc",
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: "#27a4ff",
      padding: "4px",
      borderRadius: "4px",
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "white",
      fontSize: "16px",
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: "white",
      ":hover": {
        color: "white",
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "black",
    }),
    container: (provided: any) => ({
      ...provided,
      color: "#8C8C8C",
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "#9faac7",
    }),
  };

  const durationCustomStyles: StylesConfig = {
    ...customStyles,
    valueContainer: (base: any) => ({
      ...base,
      height: '42px',
      display: 'flex',
      alignItems: 'center',
    }),
  };

  const IndicatorSeparator = (): JSX.Element | null => null;

  useEffect(() => {
    register("lessonTypes", { required: true });
    register("defaultLessonTypeId", { required: true });
    register("duration", { required: true });
  }, [register]);

  const durationOptions = [
    { value: '15 mins', label: t('15 minutes') },
    { value: '30 mins', label: t('30 minutes') },
    { value: '45 mins', label: t('45 minutes') },
    { value: '60 mins', label: t('60 minutes') },
    { value: '75 mins', label: t('75 minutes') },
    { value: '90 mins', label: t('90 minutes') },
    { value: '120 mins', label: t('2 hours') },
  ];

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose} classNames={styles.modal}>
      <div className={styles.modal_header}>
        <h2>{t('Edit Booking')}</h2>
        <img
          className={styles.modal_status_icon}
          src={CloseIcon}
          alt="info"
          onClick={onClose}
        />
      </div>
      <div className={styles.modal_content}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_row}>
            <label htmlFor="title">{t('Title')} *</label>
          </div>
          <div className={styles.form_row}>
            <input id="title" {...register("title", { required: true })} />
            {errors.title && <span>{t("This field is required")}</span>}
            {titleError && <span>{titleError}</span>}
          </div>

          <div className={styles.form_row_columns}>
            <label htmlFor="duration">{t("Duration")} *</label>
            <label htmlFor="rate">{t("Rate")}</label>
          </div>
          <div className={styles.form_row_columns} style={{ marginBottom: '10px' }}>
            <Controller
              control={control}
              name="duration"
              render={({ field }) => (
                <Select
                  id="duration"
                  value={durationOptions.find(option => option.value === field.value)}
                  onChange={(selectedOption) => {
                    field.onChange((selectedOption as { value: string; label: string })?.value || "");
                    trigger('duration'); // Trigger validation manually
                  }}
                  options={durationOptions}
                  styles={durationCustomStyles}
                  className={styles.dropdown}
                  components={{ IndicatorSeparator }}
                  placeholder={t('Select Duration')}
                  isSearchable={false}
                />
              )}
            />

            <div className={styles.input_with_dollar}>
              <span className={styles.dollar_sign}>{coachCurrencySymbol}</span>
              <input
                id="rate"
                type="number"
                {...register("rate")}
                placeholder="0"
                defaultValue={item ? item.rate : ""}
                style={{ height: "27px", color: "black" }}
                onKeyPress={(e) => {
                  if (e.key === "-" || e.key === "e" || e.key === "+") {
                    e.preventDefault();
                  }
                }}
                onBlur={(e) => {
                  const value = parseFloat(e.target.value);
                  if (value < 0 || isNaN(value)) {
                    setValue("rate", 0);
                    trigger("rate");
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.form_row_columns_error}>
            {errors.duration && <span>{t("This field is required")}</span>}
          </div>

          <div className={styles.form_row}>
            <label htmlFor="lessonTypes">{t("Session Types")} *</label>
            <Controller
              control={control}
              name="lessonTypes"
              render={({ field }) => (
                <Select
                  isMulti
                  options={lessonTypes}
                  value={selectedLessonTypes1}
                  onChange={(selectedOptions) => {
                    handleLessonType1Change(
                      selectedOptions as OptionsType<LessonType>
                    );
                    field.onChange(selectedOptions);
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => String(option.id)}
                  className={styles.select}
                  placeholder={t("Select all applicable types")}
                  styles={customStyles}
                  components={{ IndicatorSeparator }}
                />
              )}
            />
            {errors.lessonTypes && <span>{t("This field is required")}</span>}
          </div>

          <div className={styles.form_row}>
            <Controller
              control={control}
              name="defaultLessonTypeId"
              render={({ field }) => (
                <Select
                  options={selectedLessonTypes1}
                  value={selectedLessonType2 as LessonType | null}
                  onChange={(selectedOption) => {
                    handleLessonType2Change(selectedOption);
                    field.onChange(
                      selectedOption ? (selectedOption as LessonType).id : null
                    );
                  }}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => String(option.id)}
                  className={styles.select}
                  placeholder={t("Select default type")}
                  styles={customStyles}
                  components={{ IndicatorSeparator }}
                />
              )}
            />
            {errors.defaultLessonTypeId && <span>{t("This field is required")}</span>}
          </div>

          <div className={styles.form_row}>
            <label htmlFor="location">{t("Location")}</label>
            <input id="location" {...register("location")} />
          </div>

          {item.active && (
            <div className={styles.form_row_checkbox}>
              <input
                type="checkbox"
                id="defaultRate"
                {...register("defaultRate")}
              />
              {selectedDefaultRate && (
                <img
                  className={styles.checkboxImage}
                  src={CheckMark}
                  alt="Checkmark"
                />
              )}
              <label htmlFor="defaultRate">{t("Set as Default")}</label>
              <div className={styles.toolTipAlignmentEdit}>
                <ToolTip
                  text={t("Determine the session your Students will see when they view your profile summary. This will also be the default selection when Students book a session with you.")}
                  selected={true}
                  fontSize={'22px'}
                  title={t('Set as Default')}
                  orientation={'right'}  />
              </div>
            </div>
          )}

          <div className={styles.buttonGroup}>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={onClose}
            >
              {t("Cancel")}
            </button>
            <button type="submit" className={styles.confirmButton}>
            {t("Confirm")}
            </button>
          </div>
        </form>
      </div>
    </ModalLayout>
  );
}
