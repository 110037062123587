import { useCallback, useState } from "react";
import PostModel from "app/models/PostModel";
import { useActions } from "app/utils/hooks";
import { pinUnpinPost } from "app/actions/posts";
import { useHistory } from "react-router-dom";
import { planCanLike } from "./Post";

interface Props {
  post: PostModel;
  onPin?: () => void;
  onEdit?: (data: PostModel) => void;
}

export const usePostModel = ({ post, onEdit, onPin }: Props) => {
  const history = useHistory();
  const postActions = useActions({
    pinUnpinPost,
  });
  const [isEditingPost, setIsEditingPost] = useState(false);
  const [isEditingTags, setIsEditingTags] = useState(false);
  const [pinModalOpen, setPinModalOpen] = useState(false);

  const route = (path: string) => {
    history.push(path);
  };

  const onCopyClick = () => {
    route(`/copy/${post.id}`);
  };

  const onMoveClick = () => {
    route(`/move/${post.id}`);
  };

  const onPinClick = () => {
    setPinModalOpen(!pinModalOpen);
  };

  const onPinHandler = async (pinTill: string) => {
    await postActions.pinUnpinPost(post.id, pinTill);
    if (onPin) {
      onPin();
    }
  };

  const onEditClick = () => {
    setIsEditingPost(true);
  };

  const onEditClose = () => {
    setIsEditingPost(false);
  };

  const onEditTagsClick = () => {
    setIsEditingTags(true);
  };

  const onEditTagsClose = () => {
    setIsEditingTags(false);
  };

  const onPostUpdate = (data: PostModel) => {
    if (!data) {
      return;
    }

    onEdit(data);
    post.details = data?.details;
    post.tags = data?.tags;
    post.resources = data?.resources;
    post.scheduledAt = data?.scheduledAt;

    if (isEditingPost) {
      onEditClose();
    } else if (isEditingTags) {
      onEditTagsClose();
    }
  };

  const canLikePost = useCallback(() => {
    return planCanLike(post) && post?.permissions?.includes("like");
  }, [post]);

  return {
    onCopyClick,
    onMoveClick,
    onPinClick,
    onPinHandler,
    pinModalOpen,
    onEditClick,
    onEditClose,
    onEditTagsClick,
    onEditTagsClose,
    isEditingPost,
    isEditingTags,
    onPostUpdate,
    canLikePost,
  };
};
