import React from "react";
import styles from "../../../routes/ViewProgram/styles.module.scss";
import TrashIco from "app/images/trash.svg";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";

const GroupCard = ({
  program,
  multipleDate,
  handleDelete,
  eventId,
  startTimeProgram,
  endTimeProgram,
  isPublic,
}) => {
  const userType = useSelector(
    (state: RootState): string => state.user?.data?.kind
  );
  const date = new Date(multipleDate);
  const shortMonth = date
    .toLocaleDateString("en-US", { month: "short" })
    .toUpperCase();
  const month = date.toLocaleDateString("en-US", { month: "long" });
  const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
  const day = date.toLocaleDateString("en-US", { day: "numeric" });
  const year = date.getFullYear();
  const time = startTimeProgram?.label + " - " + endTimeProgram?.label;

  return (
    <div className={styles.groupCard}>
      <div className={styles.groupCardText}>
        <p className={styles.groupCardMonth}>{shortMonth}</p>
        <h2 className={styles.groupCardDay}>{day}</h2>
      </div>
      <span className={styles.borderVector}></span>
      <p className={styles.groupCardTime}>
        {dayOfWeek} <span className={styles.asterisk}>•</span> {month} {day},{" "}
        {year}
      </p>
      <p className={styles.groupCardTime2}>{time}</p>
      {program?.attendance === "series" &&
        !isPublic && userType !== "athlete" &&
        date > new Date() && (
          <div className={styles.trashIconContainer}>
            <img src={TrashIco} onClick={() => handleDelete(eventId)} />
          </div>
        )}
    </div>
  );
};

export default GroupCard;
