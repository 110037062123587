import styles from "./styles.module.scss";
import React from "react";

const SquaredColorOption = ({ color, text }) => {
  return (
    <p
      className={styles.status_container}
      style={{
        border: color?.hasBorder && `1px solid ${color?.border}`,
        color: color?.text,
        backgroundColor: color?.background,
      }}
    >
      {text}
    </p>
  );
};

export default SquaredColorOption;
