import React, { FC, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

interface IPortalProps {
  children: React.ReactNode;
}

const Portal: FC<IPortalProps> = ({ children }) => {
  const containerRef = useRef(document.createElement("div"));

  useEffect(() => {
    document.body.appendChild(containerRef.current);

    return () => {
      document.body.removeChild(containerRef.current);
    };
  }, []);

  return ReactDOM.createPortal(children, containerRef.current);
};

export default Portal;
