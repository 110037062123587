import styles from "./styles.module.scss";
import React from "react";
const Label = ({
  text,
  item,
  required = false,
  visible = true,
  tooltip = null,
  containerStyle = null,
  error = "",
  showUnder = false,
}) => {
  if (!visible) {
    return null;
  }
  return (
    <div className={containerStyle || styles.container}>
      <div className={showUnder ? "" : styles.label_container}>
        <div className={showUnder ? styles.close : styles.text_container}>
          <span className={styles.text}>{text}</span>
          {tooltip}
          {required && <span className={styles.required}>*</span>}
        </div>
        {error && <span className={styles.error}>{error}</span>}
      </div>
      {item}
    </div>
  );
};

export default Label;
