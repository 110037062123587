import { useState, useEffect } from "react";
import { CreateTeamGroupModel } from "../../models/TeamGroupModel";
import { RootState } from "typedefs";
import { useActions, useSelector } from "app/utils/hooks";
import { createTeamGroup } from "app/actions/team-group";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch } from "react-redux";
import {
  inviteModalIsOpen,
  inviteModalType,
} from "app/actions/team-group-member-import";

interface Props {
  onClose?: () => void;
}

export const useCreateNewChannelModel = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: groupId } = useSelector(
    (state: RootState) => state.groups?.selectedGroup,
    shallowEqual
  );

  const { pending } = useSelector(
    (state: RootState) => state.spaceTemplate || [],
    shallowEqual
  );

  const { pending: createPending } = useSelector(
    (state: RootState) => state.teamGroup || [],
    shallowEqual
  );

  const route = (path: string) => {
    history.push(path);
  };

  const ChannelSettingsData = [
    {
      label: t("Private"),
      value: "private",
      description: t(
        "Only visible to Group Members. Only coaches can add Group Members."
      ),
    },
    {
      label: t("Joinable"),
      value: "joinable",
      description: t(
        "Visible to all Group Members. Any Group Member can join."
      ),
    },
    {
      label: t("Auto-Join"),
      value: "auto_join",
      description: t(
        "All current and new Group members will be automatically added to the Group."
      ),
    },
  ];

  const NEW_CHANNEL_DATA: CreateTeamGroupModel = {
    group_type: "private",
    name: null,
    space_template_id: null,
    team_id: groupId,
    description: null,
  };

  const [newChannelData, setNewChannelData] =
    useState<CreateTeamGroupModel>(NEW_CHANNEL_DATA);

  const teamGroupActions = useActions({ createTeamGroup });

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = event.target.value;
    setNewChannelData({ ...newChannelData, name: value });
  };

  const groupTypeHandler: React.MouseEventHandler<HTMLDivElement> = (value) => {
    setNewChannelData({
      ...newChannelData,
      group_type: value as any,
    });
  };

  const handleDescriptionChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const value = event.target.value;
    setNewChannelData({ ...newChannelData, description: value });
  };
  const [templpateOptions, setTemplateOptions] = useState([]);

  useEffect(() => {
    return () => {
      setTemplateOptions([]);
      setNewChannelData({
        group_type: "private",
        name: null,
        space_template_id: null,
        team_id: groupId,
        description: null,
      });
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (spaceTemplates?.length) {
  //     const templateOptions = spaceTemplates?.map(createTemplateOption);
  //     setTemplateOptions(templateOptions);
  //   }
  // }, [spaceTemplates]);

  const handleTemplateSelect = (value: any) => {
    const space_template_id = value?.id || null;
    setNewChannelData((prevData) => ({ ...prevData, space_template_id }));
  };

  const createHandler = () => {
    const trimmedName = newChannelData.name.trim();
    if (trimmedName.length !== 0) {
      teamGroupActions.createTeamGroup({ ...newChannelData }).then((res) => {
        if (res) {
          if (onClose) {
            onClose();
          }
          dispatch(inviteModalType("channel-invite-members"));
          dispatch(inviteModalIsOpen(true));
          route(`/groups/${groupId}/channels/${res.id}`);
        }
      });
    }
  };

  return {
    ChannelSettingsData,
    setNewChannelData,
    newChannelData,
    handleNameChange,
    groupTypeHandler,
    handleDescriptionChange,
    handleTemplateSelect,
    templpateOptions,
    createHandler,
    pending,
    createPending,
  };
};
