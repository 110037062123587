import React from "react";
import styles from "./styles.module.scss";

interface OptionType {
  title: string;
  isActive: boolean;
  code: string;
}

interface NavbarProps {
  handleClick: (item: string) => void;
  isDisabled?: boolean;
  options: OptionType[];
}

const Navbar: React.FC<NavbarProps> = ({
  handleClick,
  options,
  isDisabled,
}) => {
  return (
    <nav className={styles.navbar}>
      <ul className={styles.navList}>
        {options.map((item, index) => {
          return (
            <li
              key={index}
              className={`${isDisabled ? styles.disabled : styles.navItem} ${
                item.isActive ? styles.active : ""
              }`}
              onClick={() => handleClick(item.code)}
            >
              {item.title}
            </li>
          );
        })}
      </ul>
      <div className={styles.horizontalLine} />
    </nav>
  );
};

export default Navbar;
