import Select, { components } from "react-select";
import React from "react";
import styles from "./styles.module.scss";
import { SelectStyles } from "app/routes/Calendar/EventForm/constants";

interface OptionProps {
  value: string | number;
  label: string;
}

interface SelectBoxProps {
  options: OptionProps[];
  value: OptionProps;
  setValue: any;
  noOptionsText?: string;
  ariaLabel?: string | null;
  containerStyle?: string | null;
  customControl?: any;
  customColor?: string;
  customWidth?: string;
  isDisabled?: boolean;
}

const SelectBox = ({
  options,
  value,
  setValue,
  noOptionsText,
  ariaLabel = null,
  containerStyle = null,
  customColor = null,
  customWidth = "",
  isDisabled = false,
}: SelectBoxProps) => {
  const customOptions = {
    ...SelectStyles,
    control: (base) => ({
      ...base,
      borderColor: customColor || "#ccc",
      width: customWidth,
    }),
  };
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{noOptionsText || "No options"}</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <div className={`${styles.select_box_wrap} ${containerStyle}`}>
      <Select
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        placeholder={"Select"}
        styles={customOptions}
        value={value}
        onChange={setValue}
        aria-label={ariaLabel}
        components={{
          IndicatorSeparator: () => null,
          NoOptionsMessage,
        }}
        options={options}
        menuPlacement="auto"
      />
    </div>
  );
};

export default SelectBox;
