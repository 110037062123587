/** @module reducers */
import { RequestError } from "typedefs";
import { TeamGroupActions, ErrObj } from "app/actions/team-group";
import { TeamGroupModel, TeamGroupMemberModel } from "app/models";
import UserModel from "app/models/UserModel";

export type TeamGroupsMemberState = {
  hasMoreMembers: boolean;
  membersPage: number;
  membersList: null | UserModel[];
  membersPending: false;
  membersError: RequestError | null;
};

const initialMemberState: TeamGroupsMemberState = {
  hasMoreMembers: false,
  membersPage: 1,
  membersList: null,
  membersPending: false,
  membersError: null,
};

export type TeamGroupState = {
  data?: TeamGroupModel[];
  error?: RequestError;
  pending?: boolean;

  selectedTeam: TeamGroupModel | null;

  addMembersData?: TeamGroupMemberModel[];
  addMembersError?: ErrObj[];
  addMembersPending?: boolean;

  membersList?: UserModel[];
  hasMoreMembers: boolean;
  membersPage: number;
  membersPending: boolean;
  membersError: RequestError | null;
};

const initialState: TeamGroupState = {
  data: null,
  error: null,
  pending: false,

  selectedTeam: null,

  addMembersData: null,
  addMembersError: null,
  addMembersPending: false,
  membersList: null,
  hasMoreMembers: true,
  membersPage: 1,
  membersPending: false,
  membersError: null,
};

export default function teamGroupReducer(
  state = initialState,
  action: TeamGroupActions
): TeamGroupState {
  switch (action.type) {
    case "@team-group.fetch.success":
      return { ...state, data: action.payload, pending: false };
    case "@team-group.fetch.pending":
      return { ...state, error: null, pending: true };
    case "@team-group.fetch.error":
      return { ...state, error: action.payload, pending: false };
    case "@team-group.select":
      return { ...state, selectedTeam: action.payload, pending: false };

    case "@team-group-member.fetch.pendingMembers":
      return { ...state, membersPending: true, membersError: null };
    case "@team-group-member.fetch.successMembers":
      return { ...state, membersList: action.payload, membersPending: false };
    case "@team-group-member.fetch.errorMembers":
      return { ...state, membersError: action.payload, membersPending: false };

    case "@team-group-member.post.done":
      return {
        ...state,
        addMembersData: action.payload.success,
        addMembersError: action.payload.error,
        addMembersPending: false,
      };
    case "@team-group-member.post.pending":
      return {
        ...state,
        addMembersData: null,
        addMembersError: null,
        addMembersPending: true,
      };

    case "@team-group-member.fetch.membersList":
      return {
        ...state,
        membersList: action.payload,
        pending: false,
      };

    case "@team-group-member.clearMembers":
      return {
        ...state,
        ...initialMemberState,
      };
    case "@team-group.clear":
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
