import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import groupIcon from "../../../../../../public/images/group-icon.png";

interface Props {
  isOpen?: boolean;
}

const PendingImportMembers: React.FC<Props> = ({ isOpen }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={() => null}
      hideCloseIcon
      headerTitle={t("Adding Members...")}
    >
      <div className={styles.container}>
        <img src={groupIcon} alt="mail" className={styles.csv_import_img} />
        <div className={styles.header_text_wrapper}>
          <p>
            <b>{t("Adding Channel Members")}</b>
          </p>
          <p>{t("This may take a moment...")}</p>
        </div>
        <div>
          <div className="loading-dots">
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
            <div className="loading-dot"></div>
          </div>
        </div>
      </div>
    </ModalLayoutWithTitle>
  );
};

export default PendingImportMembers;
