import { CoachProfileModel } from "../../models/scheduling/CoachProfileModel";
import { ProgramAction } from "../../actions/scheduling/program";
import { Tag } from "../../interfaces/ProgramTypes";

export type ProgramState = {
  id: number | null;
  page: number;
  type: string;
  tab: number;
  category: string;
  title: string;
  subtitle: string;
  date: string | null;
  startTime: { label: string | null; value: string | number | null };
  endTime: { label: string | null; value: string | number | null };
  location: string;
  email: string;
  phone: string;
  shortDescription: string;
  longDescription: string;
  thumbnail: string;
  header: string;
  thumbnailURL: string;
  headerURL: string;
  coachIds: string[];
  tags: Tag[];
  capacity: string;
  price: string;
  coaches: CoachProfileModel[];
  waitlist: boolean | null;
  registrationOpenDate: string | null;
  registrationOpenTime: { label: string | null; value: string | number | null };
  registrationCloseDate: string | null;
  registrationCloseTime: {
    label: string | null;
    value: string | number | null;
  };
  cutOffType: string;
  cutOffDay: { label: string | null; value: number | null };
  cutOffTime: { label: string | null; value: number | null };
  edit: boolean;
  repeat: { label: string | null; value: string | null };
  untilDate: string | Date | null;
  spotsBooked: number;
  status: string | null;
  facilityId: string;
  currency: string | null;
  timeZone: string | null;
  registrationStatus: string;
  privacy: string;
  images: string;
  externalHeaderUrl?: string;
  defaultImages?: string[];
};

const initialState: ProgramState = {
  id: null,
  page: 0,
  type: "",
  tab: 0,
  category: "",
  title: "",
  subtitle: "",
  date: null,
  startTime: null,
  endTime: null,
  location: "",
  email: "",
  phone: "",
  shortDescription: "",
  longDescription: "",
  thumbnail: "",
  header: "",
  thumbnailURL: "",
  headerURL: "",
  coachIds: [],
  tags: null,
  capacity: "",
  price: "",
  coaches: [],
  waitlist: null,
  registrationOpenDate: null,
  registrationOpenTime: null,
  registrationCloseDate: null,
  registrationCloseTime: null,
  cutOffType: "",
  cutOffDay: null,
  cutOffTime: null,
  edit: false,
  repeat: { label: null, value: null },
  untilDate: null,
  spotsBooked: 0,
  status: null,
  facilityId: "0",
  currency: null,
  timeZone: null,
  registrationStatus: "",
  privacy: "",
  images: "",
  defaultImages: [],
};

export default function program(
  state = initialState,
  action: ProgramAction
): ProgramState {
  switch (action.type) {
    case "@program.setPage":
      return { ...state, page: action.payload };
    case "@program.setGeneralSettings":
      return { ...state, ...action.payload };
    case "@program.setDetailsSettings":
      return { ...state, ...action.payload };
    case "@program.setCloneData":
      return { ...state, ...action.payload };
    case "@program.resetProgramSettings":
      return {
        ...state,
        ...initialState,
      };
    case "@program.setId":
      return { ...state, id: action.payload };
    case "@program.setDefaultImages":
      return { ...state, defaultImages: action.payload };
    case "@program.startBuild":
      return { ...state, ...action.payload };

    default:
      return state;
  }
}
