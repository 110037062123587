import CreatableSelect from "react-select/creatable";
import React, { useEffect } from "react";
import { components } from "react-select";
import { isNull, isUndefined } from "lodash";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import { MultiSelectStyles } from "app/routes/Calendar/EventForm/constants";

const TagsPicker = ({
  placeholder,
  setTags,
  tags,
  selectedTags = [],
  handleChange,
  isSearchable = true,
}) => {
  const format = (input) => {
    return `${input} (New Tag)`;
  };

  useEffect(() => {
    if (isNull(tags) || isUndefined(tags)) {
      schedulingFetchGet("program_tags").then((response) => {
        const tags = response?.map((item) => {
          return { label: item.name, value: item.id };
        });
        setTags(tags);
      });
    }
  }, [setTags, tags]);

  const MultiValueRemove = (props: any) => {
    return (
      <components.MultiValueRemove {...props}>
        <i className="ico ico-x" style={{ color: "#fff", cursor: "pointer" }} />
      </components.MultiValueRemove>
    );
  };
  return (
    <CreatableSelect
      styles={MultiSelectStyles}
      formatCreateLabel={format}
      isMulti={true}
      onChange={(value) => handleChange(value)}
      placeholder={placeholder}
      components={{ IndicatorSeparator: () => null, MultiValueRemove }}
      options={tags}
      isSearchable={isSearchable}
      value={selectedTags}
    />
  );
};

export default TagsPicker;
