import React, { useState } from "react";
import styles from "./styles.module.scss";
import LikesText from "app/components/LikesText";
import { cls } from "app/utils";
import { useActions } from "app/utils/hooks";
import { likePost, unlikePost } from "app/actions/currentLikes";

interface Props {
  postId: string;
  likesCount: number;
  liked: boolean;
  likable: boolean;
  isDemo?: boolean;
  canSeeWhoLiked?: boolean;
}

const PostLikes = ({
  likesCount,
  postId,
  liked,
  likable,
  isDemo,
  canSeeWhoLiked,
}: Props) => {
  const likeActions = useActions({ likePost, unlikePost });
  const [isLiked, setIsLiked] = useState(liked);
  const [postLikes, setPostLikes] = useState(likesCount);

  const likeHandler = () => {
    if (!likable || isDemo) {
      return;
    }

    const updateLikes = (newLikes: number) => {
      try {
        if (!isLiked) {
          likeActions.likePost(postId);
        } else {
          likeActions.unlikePost(postId);
        }
        setPostLikes(newLikes);
        setIsLiked((prev) => !prev);
      } catch (error) {
        console.error("Error updating likes:", error);
      }
    };

    if (isLiked) {
      updateLikes(postLikes - 1);
    } else {
      updateLikes(postLikes + 1);
    }
  };

  return (
    <div className={cls(styles.like_container, { [styles.liked]: isLiked })}>
      <div className={styles.like_wrapper} onClick={likeHandler}>
        <i
          className={cls(`post-count-icon ico large-ico`, {
            ["ico-fist"]: isLiked,
            ["ico-fist-o"]: !isLiked,
          })}
        />
        {/* {postLikes} */}
      </div>
      {!isDemo && (
        <LikesText
          postId={postId}
          likes={postLikes}
          canSeeWhoLiked={canSeeWhoLiked}
        />
      )}
    </div>
  );
};

export default PostLikes;
