import React from "react";
import styles from "./styles.module.scss";

const ProgramOption = ({
  title,
  subtitle,
  DisplayImage,
  selected,
  onClick,
}) => {
  return (
    <div className={selected ? "" : styles.option_container}>
      <div
        className={
          selected
            ? styles.selected_options_container
            : styles.program_options_container
        }
      >
        <div
          className={styles.program_options_inside_container}
          onClick={onClick}
        >
          <div className={styles.image_color_container}>
            <div className={styles.program_options_image_container}>
              <img
                style={{
                  width: "64px",
                  height: "64px",
                }}
                src={DisplayImage}
              />
            </div>
          </div>
          <div className={styles.text_container}>
            <span className={styles.program_options_title}>{title}</span>
            <span className={styles.subtitle_container}>{subtitle}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramOption;
