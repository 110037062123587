import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss"; // Import your styles

interface Member {
  email: string;
  name: string;
  position: string;
  jersey: string;
  number: string;
}

interface TeamGroup {
  name: string;
}

interface Props {
  members: Member[];
  teamGroups: TeamGroup[];
}

const ExpandedListContainer: React.FC<Props> = ({ members, teamGroups }) => {
  const { t } = useTranslation();
  const [showMembers, setShowMembers] = useState(true);
  const [showGroups, setShowGroups] = useState(true);

  return (
    <div className={styles.expanded_list_container}>
      {" "}
      <div
        className={`${styles.expanded_list} ${
          showMembers ? styles.active : ""
        }`}
        onClick={() => setShowMembers(!showMembers)}
      >
        <label>
          <i className={styles.toggle_icon}></i>
          {t("Members")}
        </label>
        {showMembers && (
          <table>
            <tbody>
              {members?.length > 0 ? (
                members?.map((member, index) => (
                  <tr key={index}>
                    <td />
                    <td>{member?.email}</td>
                    <td>{member?.name}</td>
                    <td>{member?.position}</td>
                    <td>{member?.jersey}</td>
                    <td>{member?.number}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>{t("No members found")}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {teamGroups.length > 0 && (
        <div
          className={`${styles.expanded_list} ${
            showGroups ? styles.active : ""
          }`}
          onClick={() => setShowGroups(!showGroups)}
        >
          <label>
            <i className={styles.toggle_icon}></i>
            {t("Channels")}
          </label>
          {showGroups && (
            <table>
              <tbody>
                {teamGroups.map((teamGroup, index) => (
                  <tr key={index}>
                    <td />
                    <td>{teamGroup.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default ExpandedListContainer;
