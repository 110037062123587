import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import { cls } from "app/utils";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import styles from "./styles.module.scss";
import closeIcon from "../../../../../../public/images/close-icon.svg";
import Button from "app/components/Button/ButtonVariant";
import { useActions, useStoreActions } from "app/utils/hooks";
import {
  fetchGroupById,
  putGroupSettings,
  deleteGroup,
} from "app/actions/groups";
import GroupsModel from "app/models/GroupsModel";
import CustomSelect from "app/components/inputs/CustomSelector";
import { fetchSports } from "app/actions/sports";
import { Loading } from "app/components/Wrappers";
import FileUploadButton from "app/components/Button/FileUploadButton";
import TeamAvatar from "app/components/TeamAvatar";
import { Spinner } from "app/components/Loading";
import { clearMediaState } from "app/actions/media-library-data";
import upload from "app/utils/uploader";
import customPrompt from "app/components/inputs/SelectBox/helpers/customPrompt";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

type Props = {
  isModalOpen: boolean;
  modalHandler: () => void;
  transferGroupModalHandler: () => void;
};

const GroupSettingsModal = ({
  isModalOpen,
  modalHandler,
  transferGroupModalHandler,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const teamActions = useActions({
    fetchGroupById,
    putGroupSettings,
    deleteGroup,
  });
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };
  const [teamData, setTeamData] = useState(null);
  const { selectedGroup, pending: groupPending } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );
  const { sports } = useStoreActions({ sports: fetchSports }, { noAuth: true });
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  const canManageGroupControls = selectedGroup?.planPermissions?.includes(
    "manage_group_controls",
  );

  useEffect(() => {
    teamActions
      .fetchGroupById(selectedGroup.id)
      .then((res) => setTeamData(res));

    return () => {
      setTeamData(null);
    };
  }, []);

  const onChangeHandler = (key: string, value: boolean | string) => {
    setTeamData((prevData: GroupsModel) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const AllowList = [
    {
      label: t("Allow members to create posts"),
      value: "membersCanPost",
      isActive: (teamData && teamData.membersCanPost) || false,
    },
    {
      label: t("Allow members to reply to posts"),
      value: "membersCanReply",
      isActive: (teamData && teamData.membersCanReply) || false,
    },
    {
      label: t("Allow members to view other members"),
      value: "membersCanViewOtherMembers",
      isActive: (teamData && teamData.membersCanViewOtherMembers) || false,
    },
    {
      label: t("Allow members to see post Views/Likes"),
      value: "membersCanSeePostData",
      isActive: (teamData && teamData.membersCanSeePostData) || false,
    },
  ];

  const onUpdateHandler = () => {
    teamActions.putGroupSettings(teamData).then(() => {
      teamActions.fetchGroupById(teamData.id).then(() => modalHandler());
    });
  };

  const onDeleteGroup = () => {
    customPrompt({
      message: t(`Are you sure you would like to delete {{selectedGroup}}?`, {
        selectedGroup: selectedGroup.name,
      }),
      onYes: () => {
        teamActions.deleteGroup(selectedGroup.id).then(() => route("/groups"));
      },
      onCancel: () => {
        return;
      },
    });
  };

  const uploadRef = useRef(null);

  const handleFileChange = async (acceptedFile: File) => {
    if (acceptedFile) {
      const file = acceptedFile;
      setIsLoading(true);

      try {
        dispatch(clearMediaState());
        const abortableUpload = upload(file, file.name, file.type, undefined);
        uploadRef.current = abortableUpload;
        const response = await abortableUpload.promise;
        setTeamData({ ...teamData, logo: response.url, logo_id: response.id });
      } catch (error) {
        setUploadError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <ModalLayout isOpen={isModalOpen} onClose={modalHandler}>
      <div className={styles.content_wrapper}>
        <div className={styles.modal_header}>
          <h1>{t("Group Settings")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={modalHandler}
          />
        </div>

        <div className={styles.image_upload_seciton}>
          <TeamAvatar
            altName="team"
            className={styles.team_avatar}
            srcName={teamData && teamData.logo ? teamData.logo : null}
          />
          <FileUploadButton
            buttonStyles={styles.spinner}
            buttonLabel={
              !isLoading ? t("Update Group Logo") : <Spinner isLoading />
            }
            accept={{ "image/*": [] }}
            onFileChange={handleFileChange}
          />
          {uploadError && (
            <div className="helper-text error">
              {t("Something went wrong.")}
            </div>
          )}
        </div>

        <div className={styles.text_input_wrapper}>
          <label htmlFor="name">{t("Group Name")}</label>
          <input
            type="text"
            name="name"
            id="name"
            defaultValue={teamData && teamData.name ? teamData.name : null}
            placeholder={t("Name of a Group, organization, etc.")}
            onChange={(event) => onChangeHandler("name", event.target.value)}
          />
        </div>

        <div className={styles.text_input_wrapper}>
          <label htmlFor="name">{t("What is This For?")}</label>
          {!teamData || sports.pending ? (
            <Loading isLoading loadType="spinner" />
          ) : (
            <CustomSelect
              placeholder={t("Select Sport / Subject")}
              isCratable
              options={sports && sports.data}
              value={
                teamData && teamData.sport
                  ? {
                      label: teamData.sport,
                      value: teamData.sport,
                    }
                  : null
              }
              isClearable
              isSearchable
              onChange={(data: { value: string; label: string }) => {
                onChangeHandler(
                  "sport",
                  data && data.value ? data.value : null,
                );
              }}
            />
          )}
        </div>

        {canManageGroupControls && (
          <ul className={cls(styles.allow_list_container)}>
            {AllowList.map((setting) => (
              <li key={setting.value} className={styles.allow_list}>
                <div className="checkbox">
                  <input
                    id={setting.value}
                    name={setting.value}
                    checked={setting.isActive}
                    onChange={(event) =>
                      onChangeHandler(setting.value, event.target.checked)
                    }
                    type="checkbox"
                  />
                  <label
                    data-feature="space-settings.reminders"
                    htmlFor={setting.value}
                  ></label>
                </div>
                <label htmlFor={setting.value}>{setting.label}</label>
              </li>
            ))}
          </ul>
        )}

        <div className={styles.options_section}>
          <label>
            <b>{t("Options")}</b>
          </label>
          <Button
            title={t("Delete Group")}
            buttonType="secondary-outline"
            buttonStyles={cls("text-danger bold", styles.option_button)}
            onClickFunc={onDeleteGroup}
          />
          <Button
            title={t("Transfer Group Ownership")}
            buttonType="secondary-outline"
            buttonStyles={cls("text-danger bold", styles.option_button)}
            onClickFunc={transferGroupModalHandler}
          />
        </div>

        <div className={styles.footer_button_wrapper}>
          <Button
            buttonType="secondary-outline"
            title={t("Cancel")}
            onClickFunc={modalHandler}
          />
          <Button
            buttonType="primary"
            title={t("Save")}
            onClickFunc={onUpdateHandler}
            disabled={!teamData || groupPending || isLoading}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default GroupSettingsModal;
