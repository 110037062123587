import styles from "./styles.module.scss";
import UserSelector from "../UserSelector";
import Label from "../Label";
import React, { useEffect, useState } from "react";
import UserItems from "../UserItems";
import {
  schedulingFetchGet,
  schedulingFetchPatch,
} from "app/utils/request/scheduling/fetch";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import ConfirmationPrompt from "app/routes/Calendar/ConfirmationPrompt";

const Registrants = ({
  onClose,
  userType,
  programId,
  programStatus,
  isViewOnly,
  facilityId,
  facilityIdOfCoach,
}) => {
  const { t } = useTranslation();
  const [registrants, setRegistrants] = useState([]);
  const [waitlisted, setWaitListed] = useState([]);
  const [newParticipants, setNewParticipants] = useState([]);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [type, setType] = useState("");
  const [studentsData, setStudentsData] = useState([]);
  const isCoach = userType === 'coach';

  useEffect(() => {
    schedulingFetchGet(`/facilities/${facilityId}/student_profiles`).then(
      (response) => setStudentsData(response)
    );
    schedulingFetchGet(`/programs/${programId}/registrations`).then(
      (response) => {
        const registrantsData = response?.data?.completed?.map((item) => item);
        const waitlistData = response?.data?.onWaitlist?.map((item) => item);
        setRegistrants(registrantsData);
        setWaitListed(waitlistData);
      }
    );
  }, [programId]);

  const handleParticipants = () => {
    const newParticipantsIds = newParticipants?.map((item) => item.id);
    const data = {
      students_ids_to_register: newParticipantsIds,
    };
    if (isEmpty(newParticipantsIds)) {
      return;
    }
    schedulingFetchPatch(`/programs/${programId}/registrations`, data).then(
      (response) => {
        if (data.students_ids_to_register.length === 0) {
          onClose({ message: "Users deleted successfully." });
        } else {
          onClose(response);
        }
      }
    );
  };

  const removeNewParticipant = (item) => {
    setNewParticipants((old) =>
      old.filter((participant) => participant.id !== item.id)
    );
  };

  const handleRemovedPressed = (item, type) => {
    setShowRemovePopup(true);
    setSelectedItem(item);
    setType(type);
  };

  const handleRemoved = () => {
    type === "registrant"
      ? setRegistrants((old) =>
          old.filter((participant) => participant.id !== selectedItem.id)
        )
      : setWaitListed((old) =>
          old.filter((participant) => participant.id !== selectedItem.id)
        );
     const data = {
       students_ids_to_unregister: [selectedItem.id],
     };
    setShowRemovePopup(false);
    schedulingFetchPatch(`/programs/${programId}/registrations`, data).then(
      () => {
          onClose({ message: "Users deleted successfully." }, false);
      });
  };

  const confirmButton = (text, onClick) => (
    <button
      data-testid="save-edit"
      form="form-details"
      className={styles.confirm_button}
      type="submit"
      onClick={onClick}
    >
      {text}
    </button>
  );

  return (
    <div className={styles.container}>
      {isCoach && programStatus !== "completed" && !isViewOnly && (
        <>
          <Label
            text={t("Register Athletes")}
            item={(
              <div className={styles.line_space_between}>
                <div className={styles.picker}>
                  <UserSelector
                    placeholder={t("Select Athletes")}
                    setValues={setNewParticipants}
                    values={studentsData}
                    selectedUsers={newParticipants}
                  />
                </div>
                {newParticipants?.length > 0 && confirmButton(t("Save changes"), handleParticipants)}
              </div>
            )}
          />

          {newParticipants?.length > 0 && (
            <div className={styles.square_container}>
              <UserItems
                showEmail={isCoach}
                userList={newParticipants}
                onClose={removeNewParticipant}
              />
            </div>
          )}
        </>
      )}
      {((registrants?.length > 0) || (waitlisted?.length > 0)) && (
        <div className={styles.line_container}>
          <div className={styles.square_container}>
            <Label
              text={
                programStatus === "completed"
                  ? `${t("Registered")} • ${registrants?.length}`
                  : `${t("Already Registered")} • ${registrants?.length || 0}`
              }
              item={(
                <div>
                  <div className={styles.line_separator} />
                  <UserItems
                    showEmail={isCoach}
                    cantClose={userType === "athlete" || facilityIdOfCoach !== facilityId || programStatus === 'completed'}
                    userList={registrants}
                    onClose={(item) => handleRemovedPressed(item, "registrant")}
                  />
                </div>
              )}
            />
          </div>
          <div className={styles.square_container}>
            <Label
              text={`${t("Waitlisted")} • ${waitlisted?.length || 0}`}
              item={(
                <div>
                  <div className={styles.line_separator} />
                  <UserItems
                    showEmail={isCoach}
                    userList={waitlisted}
                    onClose={(item) => handleRemovedPressed(item, "waitlist")}
                    cantClose={userType === "athlete" || facilityIdOfCoach !== facilityId || programStatus === 'completed'}
                  />
                </div>
              )}
            />
          </div>
        </div>
      )}
      {showRemovePopup && (
        <ConfirmationPrompt
          actions={{
            onCancel: () => setShowRemovePopup(false),
            onConfirm: () => handleRemoved(),
          }}
          data={{
            operation: "delete",
            title: t("Cancel Registration"),
            content: `${t("Are you sure you want to cancel")} ${
              selectedItem.firstName
            } ${selectedItem.lastName}
           ${t("registration")}?`,
          }}
        />
      )}
    </div>
  );
};

export default Registrants;
