import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import PagePanel from "app/components/PagePanel";
import SelectSpaceSport from "./StepsComponents/SelectSpaceSport";
import InvitationToSpace from "./StepsComponents/InvitationToSpace";
import SelectSpaceList from "./StepsComponents/SelectSpaceList";
import { useSelector } from "app/utils/hooks";
import {
  clearAppliedTemplates,
  newSpaceValues,
} from "app/actions/spaceCreation";
// import { fetchSelf } from "app/actions/user";
import { RootState } from "typedefs";
import GettingStartedProgress from "app/components/GettingStarted/GettingStartedProgress";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch } from "react-redux";
import { clearList } from "app/actions/list";

const CreateNewSpace = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  const { step, spaces_import } = useSelector(
    (state: RootState) => state.createSpace,
    shallowEqual,
  );

  useEffect(() => {
    if (spaces_import.create_for_self) {
      dispatch(
        newSpaceValues({
          ...spaces_import,
          sport: self?.sport,
          sport_other: self?.sport,
          create_for_self: true,
        }),
      );
    }

    return () => {
      dispatch(clearAppliedTemplates());
      dispatch(clearList());
    };
  }, [dispatch, self]);

  const renderContent = () => {
    if (step === 2) {
      return <InvitationToSpace />;
    }

    if (step === 3) {
      return <SelectSpaceList />;
    }

    return <SelectSpaceSport />;
  };

  return (
    <>
      <GettingStartedProgress />
      <div className={styles.container}>
        <PagePanel
          title={t("Create New Space")}
          titleStyles={styles.header_title}
        />
        <div className={styles.panel}>{renderContent()}</div>
      </div>
    </>
  );
};

export default CreateNewSpace;
