import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import ChangeImportedColumnModal from "../ChangeImportedColumnModal";
import { useTranslation } from "react-i18next";
import UserModel from "app/models/UserModel";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onNext?: (columnIndexes: {
    email: number;
    name: number;
    position: number;
    jersey: number;
  }) => void;
}

const ImportedUsersModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onNext,
}: Props) => {
  const { t } = useTranslation();
  const { membersListToInvite, rearangedInviteData } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual,
  );
  const [emails, setEmails] = useState<string[]>([]);
  const [names, setNames] = useState<string[]>([]);
  const [positions, setPositions] = useState<string[]>([]);
  const [jerseys, setJerseys] = useState<string[]>([]);

  const [emailColumnIndex, setEmailColumnIndex] = useState<number>(0);
  const [nameColumnIndex, setNameColumnIndex] = useState<number>(1);
  const [positionColumnIndex, setPositionColumnIndex] = useState<number>(2);
  const [jerseyColumnIndex, setJerseyColumnIndex] = useState<number>(3);

  const [columnChangeModalOpen, setColumnChangeModalOpen] = useState(false);
  const [columnToChange, setColumnToChange] = useState("");
  const [columnChangedData] = useState(
    rearangedInviteData ? rearangedInviteData : membersListToInvite,
  );

  useEffect(() => {
    if (columnChangedData && columnChangedData.length !== 0) {
      const allEmails = columnChangedData
        .map((member: UserModel) => member.email)
        .filter((email: { email?: string }) => email);
      const allNames = columnChangedData
        .map((member: UserModel) => member.name)
        .filter((name: { name?: string }) => name);
      const allPositions = columnChangedData
        .map((member: UserModel) => member.position)
        .filter((position: { position?: string }) => position);
      const allJerseys = columnChangedData
        .map((member: UserModel) => member.jersey)
        .filter((jersey?: string) => jersey);

      setEmails(allEmails);
      setNames(allNames);
      setPositions(allPositions);
      setJerseys(allJerseys);
    }
  }, [columnChangedData]);

  const handleColumnChange = (colName: string, columnIndex?: number) => {
    setColumnToChange(colName);

    if (colName === "emails") {
      setEmailColumnIndex(columnIndex);
    }
    if (colName === "names") {
      setNameColumnIndex(columnIndex);
    }
    if (colName === "positions") {
      setPositionColumnIndex(columnIndex);
    }
    if (colName === "jerseys") {
      setJerseyColumnIndex(columnIndex);
    }

    setColumnChangeModalOpen(true);
  };

  const onColumnChangeConfirm = (
    value: string,
    data: string[],
    columnIndex: number,
  ) => {
    if (value === "emails") {
      setEmails(data);
      setEmailColumnIndex(columnIndex);
    } else if (value === "names") {
      setNames(data);
      setNameColumnIndex(columnIndex);
    } else if (value === "positions") {
      setPositions(data);
      setPositionColumnIndex(columnIndex);
    } else if (value === "jerseys") {
      setJerseys(data);
      setJerseyColumnIndex(columnIndex);
    }
    setColumnChangeModalOpen(false);
  };

  if (
    columnChangeModalOpen &&
    columnChangedData &&
    columnChangedData.length !== 0
  ) {
    return (
      <ChangeImportedColumnModal
        isOpen={columnChangeModalOpen}
        onBack={() => setColumnChangeModalOpen(false)}
        onConfirm={onColumnChangeConfirm}
        changeFor={columnToChange}
      />
    );
  }

  const dataList = [
    {
      value: emails,
      for: "emails",
      label: t("Email (Required)"),
      columnIndex: emailColumnIndex,
      htmlFor: "email_list",
      onDelete: () => setEmails([]),
    },
    {
      value: names,
      for: "names",
      label: t("Name"),
      columnIndex: nameColumnIndex,
      htmlFor: "name_list",
      onDelete: () => setNames([]),
    },
    {
      value: positions,
      for: "positions",
      label: t("Position"),
      columnIndex: positionColumnIndex,
      htmlFor: "position_list",
      onDelete: () => setPositions([]),
    },
    {
      value: jerseys,
      for: "jerseys",
      label: t("Jersey #"),
      columnIndex: jerseyColumnIndex,
      htmlFor: "jersey_number_list",
      onDelete: () => setJerseys([]),
    },
  ];

  return (
    <ModalLayoutWithTitle
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t("Assign Attributes")}
    >
      <div className={styles.header_text_wrapper}>
        <p>
          <b>{t("Assign Attributes to Columns")}</b>
        </p>
        <p>
          {t("Select columns from your file to be imported as the following")}
        </p>
        <p>
          {t(
            "An invitation will be sent to each email address and its associated row of attributes.",
          )}
        </p>
      </div>
      <div className={styles.list_wrapper}>
        <div className={styles.inputs_container}>
          {dataList.map((data, index) => (
            <div key={index}>
              <label htmlFor={data.htmlFor}>{data.label}</label>
              <div className={styles.input_wrapper}>
                <span className={styles.input_list_wrapper}>
                  <input
                    value={
                      data.value && data.value.length !== 0
                        ? data.value.join(", ")
                        : ""
                    }
                    readOnly={data.value && data.value.length !== 0}
                    type="text"
                    onClick={() => handleColumnChange(data.for, index)}
                    className={styles.input}
                  />
                  <Button
                    buttonType="secondary-outline-danger"
                    icon={<i className="ico ico-x" />}
                    title={""}
                    onClickFunc={data.onDelete}
                    buttonStyles={styles.delete_btn}
                  />
                </span>
                <Button
                  title={t("Change Column")}
                  buttonType="secondary-outline"
                  buttonStyles={styles.change_col_btn}
                  onClickFunc={() => handleColumnChange(data?.for, index)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.cancel_btn}>
        <Button
          buttonType="secondary-outline"
          title={t("Cancel")}
          onClickFunc={onClose}
        />
        <Button
          buttonType="primary"
          title={t("Next")}
          onClickFunc={() =>
            onNext({
              email: emailColumnIndex ?? -1,
              name: nameColumnIndex ?? -1,
              position: positionColumnIndex ?? -1,
              jersey: jerseyColumnIndex ?? -1,
            })
          }
        />
      </div>
    </ModalLayoutWithTitle>
  );
};

export default ImportedUsersModal;
