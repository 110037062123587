import React, { memo, useEffect } from "react";
import styles from "./styles.module.scss";
import ActivityLayout from "app/components/Layouts/ActivityPageLayout";
import NotificationsList from "app/components/NotificationsList";
import { useNotificationsModel } from "app/components/NotificationsDropdown/Notifications/indexModel";
import { useTranslation } from "react-i18next";
import { useActions } from "app/utils/hooks";
import { markAllSeen, getTotalUnseen } from "app/actions/Notification";

const ActivityNotifications = () => {
  const { t } = useTranslation();
  const {
    markAllAsRead,
    notificationsContainer,
    groupedNotifications,
    loading,
    isReading,
    totalUnseen,
  } = useNotificationsModel();

  const notificationAction = useActions({ markAllSeen, getTotalUnseen });

  useEffect(() => {
    if (totalUnseen && totalUnseen?.notifications > 0) {
      notificationAction.markAllSeen();
      notificationAction.getTotalUnseen();
    }
  }, [totalUnseen?.notifications]);

  return (
    <div className="notifications-container">
      <ActivityLayout>
        <div className={styles.panel}>
          <div className={styles.panel_title_contaniner}>
            <h2>{t("Notifications")}</h2>
          </div>
          <span className={styles.icon_btn}>
            <i
              className="btn ico ico-read btn-outline-primary"
              onClick={!isReading && markAllAsRead}
            />
          </span>
        </div>
        <NotificationsList
          containerClassName={styles.notifications_list}
          data={groupedNotifications}
          notificationsContainer={notificationsContainer}
          loading={loading || isReading}
        />
      </ActivityLayout>
    </div>
  );
};

export default memo(ActivityNotifications);
