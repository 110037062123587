import React from "react";

import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";

import { useTranslation } from "react-i18next";

type PropsDef = {
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  onDismiss?: () => void;
  onConfirm?: () => void;
};

export default function ConflictModal(props: PropsDef) {
  const { t } = useTranslation();

  const { isOpen, title, children, onDismiss, onConfirm } = props;

  const icon = `ico ico-dual-toggle ${styles.error}`;

  return (
    <ModalLayout
      isOpen={isOpen}
      classNames={`${styles.modal} info-modal-container`}
    >
      <div className={`${styles.modal_header} ${styles.w_100} info-modal-header`}>
        <div className={styles.w_100}>
          <div className={styles.close_button} onClick={onDismiss}>
            <i className="ico ico-x" />
          </div>
          <i className={`${icon} ${styles.modal_status_icon}`} />
        </div>
        <h2>{title}</h2>
      </div>
      <div className={`${styles.modal_content} info-modal-content`}>
        {children}
      </div>

      <div className={styles.confirm_conflict_actions}>
        <button
          onClick={onConfirm}
          className={`${styles.confirm_button} btn btn-primary`}
        >
          {t("Ok")}
        </button>
      </div>
    </ModalLayout>
  );
}
