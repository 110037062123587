import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import UserModel from "app/models/UserModel";
import { useActions } from "app/utils/hooks";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { tabType } from ".";
import {
  clearGroupMembers,
  fetchGroupMembers,
  deleteGroupMember,
  updateGroupMember,
  membersPage as memberPage,
} from "app/actions/groups";
import useDebounce from "app/utils/hooks/useDebounce";
import { inviteModalIsOpen } from "app/actions/team-group-member-import";
import { useHistory } from "react-router-dom";

type FilterName = "coach" | "player" | "viewer";

export const useGroupMembersModel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const memberActions = useActions({
    fetchGroupMembers,
    clearGroupMembers,
    deleteGroupMember,
    updateGroupMember,
  });
  const { selectedGroup, membersData, membersPage, hasMoreMembers } =
    useSelector((state: RootState) => state.groups, shallowEqual);
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { modalInviteIsOpen } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual,
  );
  const [selectedTab, setSelectedTab] = useState<tabType>({
    label: t("Active"),
    value: "accepted",
  });
  const [canManageMembers, setCanManageMembers] = useState(false);
  const [members, setMembers] = useState<UserModel[]>(null);
  const [memberFilterParams, setMemberFilter] = useState({
    coach: true,
    player: true,
    viewer: true,
  });
  const [searchTerm, setSearchTerm] = useState(null);
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const membersFilterHandler = (e: { [key in FilterName]: boolean }) => {
    setMemberFilter(e);
  };

  const abortController = useRef<AbortController | null>(null);

  const onTabSelect = useCallback((tab: tabType) => {
    setSelectedTab(tab);
  }, []);

  const memberSearchHandler = (val: string) => {
    setSearchTerm(val);
  };

  const inviteMembersModalOpen = () => {
    dispatch(inviteModalIsOpen(true));
  };

  const tabs = [
    {
      label: t("Active"),
      value: "accepted",
    },
    {
      label: t("Invited"),
      value: "pending",
    },
    {
      label: t("Incomplete"),
      value: "incomplete",
    },
  ];

  const updatedTypeParams = Object.entries(memberFilterParams)
    .filter(([key, value]) => value === true && key !== "type")
    .map(([key]) => key);

  const debouncedSearch = useDebounce(searchTerm, 300);

  const memberSearchParams = {
    per_page: 20,
    type: updatedTypeParams,
    status: selectedTab.value,
    userName: debouncedSearch,
  };

  useEffect(() => {
    dispatch(memberPage(1));
    if (selectedGroup) {
      memberActions
        .fetchGroupMembers(selectedGroup.id, {
          page: 1,
          per_page: 20,
          ...memberSearchParams,
        })
        .then((res: UserModel[]) => {
          setMembers(null);
          setMembers(res);
        });

      if (
        selectedGroup.permissions.includes("manage_members") &&
        selectedGroup.planPermissions.includes("manage_members")
      ) {
        setCanManageMembers(true);
      } else {
        setCanManageMembers(false);
      }
    }

    return () => {
      setCanManageMembers(false);
      memberActions.clearGroupMembers();
    };
  }, [selectedGroup, selectedTab, memberFilterParams, debouncedSearch]);

  const fetchPaginatedGroupMembers = async (
    abort?: AbortController,
  ): Promise<UserModel[]> => {
    try {
      const res: [] = await memberActions.fetchGroupMembers(
        selectedGroup.id,
        {
          page: membersPage,
          per_page: 20,
          ...memberSearchParams,
        },
        abort ? abort.signal : undefined,
      );

      if (res && Array.isArray(res)) {
        return res;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      if (hasMoreMembers && selectedGroup && members) {
        const entry = entries[0];
        if (entry.isIntersecting) {
          fetchPaginatedGroupMembers(abortController.current).then(
            (res: []) => {
              setMembers((prev: []) => [...prev, ...res]);
            },
          );
        }
      }
    };

    if (containerRef.current) {
      observer = new IntersectionObserver(handleObserver, {
        threshold: 0.1,
        rootMargin: "100px",
      });
      observer.observe(containerRef.current);
    }

    return () => {
      if (observer && containerRef.current) {
        observer.unobserve(containerRef.current);
        observer.disconnect();
      }
    };
  }, [selectedGroup, containerRef, membersPage, hasMoreMembers, membersData]);

  const onDeleteMember = (memberId: string, userName: string) => {
    const confirmation = window.confirm(
      t("Are you sure you want to remove {{userName}} from this Group?", {
        userName,
      }),
    );
    if (confirmation) {
      memberActions
        .deleteGroupMember(selectedGroup.id, memberId)
        .then(() => {
          if (memberId === self.id) {
            route("/groups");
          } else {
            setMembers((prevPosts) =>
              prevPosts.filter((member) => member?.id !== memberId),
            );
          }
        })
        .catch((error) => {
          console.error("Error deleting post:", error);
        });
    }
  };

  const onMemberUpdate = (updatedMember: UserModel) => {
    memberActions
      .updateGroupMember(selectedGroup.id, updatedMember)
      .then(() => {
        setMembers((prevMembers) => {
          // Find the index of the updated member in the array
          const updatedIndex = prevMembers.findIndex(
            (member) => member.id === updatedMember.id,
          );

          // If the member is found, update it
          if (updatedIndex !== -1) {
            const updatedMembers = [...prevMembers];
            updatedMembers[updatedIndex] = updatedMember;
            return updatedMembers;
          }

          // If the member is not found, return the previous array as is
          return prevMembers;
        });
      });
  };

  return {
    selectedGroup,
    canManageMembers,
    tabs,
    selectedTab,
    onTabSelect,
    membersFilterHandler,
    members,
    containerRef,
    onDeleteMember,
    onMemberUpdate,
    memberFilterParams,
    memberSearchHandler,
    inviteMembersModalOpen,
    modalInviteIsOpen,
  };
};
