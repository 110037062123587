/** @module components/modals */
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useActions } from "app/utils/hooks";
import { fetchTeamGroups } from "app/actions/team-group";
import { RootState } from "app/reducers";
import { UserModel, TeamModel } from "app/models";
import { SelectList } from "app/components/Layouts";
import { Dots } from "app/components/Loading";
import { addItem, removeItem } from "app/utils/array";
import Styles from "./styles.module.scss";
import group_success_icon_png from "app/images/group-success-icon.png";
import ModalLayoutWithTitle from "app/components/Layouts/ModalLayoutWithTitle";
import Button from "app/components/Button/ButtonVariant";
import { selectTeams } from "app/actions/team-group-member-import";
import { useTranslation } from "react-i18next";

type ResultType = {
  members: UserModel[];
  teamGroups: TeamModel[];
};

type PropsDef = {
  show?: boolean;
  onClose?: (obj: { type: "cancel" | "close" }) => void; // 'cancel' on button click, 'close' on model close event
  onBack?: () => void;
  closeText?: string;
  onDone?: (results: ResultType) => void;
  doneText?: string;
  team?: TeamModel;
};

/**
 * @class AddMembersToChannels
 *
 */
export default function AddMembersToChannels(props: PropsDef) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const actions = useActions({ fetchTeamGroups });
  const teamGroup = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual,
  );
  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );
  const { selectedTeams, membersListToInvite } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual,
  );

  const [teams, setTeams] = useState([]);
  const [teamData, setTeamData] = useState(null);

  useEffect(() => {
    dispatch(selectTeams([]));
  }, []);

  useEffect(() => {
    actions
      .fetchTeamGroups(selectedGroup.id)
      .then((res: any) => setTeamData(res.payload));
  }, [selectedGroup]);

  useEffect(() => {
    if (!teamGroup.pending && teamGroup.data && teamGroup.data.length) {
      const general = {
        name: t("General"),
        membersCount: teamData && teamData.membersCount,
        locked: true,
        selected: true,
      };
      setTeams([general, ...teamGroup.data]);
    }
  }, [teamGroup]);

  const closeHandler = (isButton: boolean) => {
    if (props.onClose) {
      if (isButton) {
        props.onClose({ type: "cancel" });
      } else {
        props.onClose({ type: "close" });
      }
    }
  };

  const ListTemplate = ({ model }: { model: any }) => {
    return (
      <div className={Styles.listItem}>
        <h3>{model.name}</h3>
        <h6>
          {t("count_member", {
            count: model.membersCount,
          })}
        </h6>
      </div>
    );
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      dispatch(selectTeams(teamData));
    } else {
      dispatch(selectTeams([]));
    }
  };

  const handleItemChange = (item: any, checked: boolean) => {
    if (checked) {
      dispatch(selectTeams(addItem(selectedTeams, item) as any));
    } else {
      dispatch(selectTeams(removeItem(selectedTeams, item) as any));
    }
  };

  const handleSubmit = () => {
    if (props.onDone) {
      props.onDone({ members: membersListToInvite, teamGroups: selectedTeams });
    }
  };

  let content = <Dots active={true} />;
  if (!teamGroup.pending && teamGroup.data != null) {
    if (teams && teams.length) {
      content = (
        <SelectList
          data={teams}
          template={ListTemplate}
          selected={selectedTeams}
          onSelectAll={handleSelectAll}
          onItemChanged={handleItemChange}
        />
      );
    } else {
      content = <div>{t("No Results")}</div>;
    }
  }
  return (
    <ModalLayoutWithTitle
      isOpen={props.show}
      onClose={() => closeHandler(false)}
      headerTitle={t("Add Members To Channels")}
    >
      <div className={Styles.content_container}>
        <div className={Styles.addMembersToChannels}>
          <div className={Styles.container}>
            <div className={Styles.header}>
              <img src={group_success_icon_png} alt="Add Members" />
              <h3>
                {membersListToInvite?.length ?? 0}&nbsp;
                {membersListToInvite?.length === 0 ||
                membersListToInvite?.length > 1
                  ? t("Members")
                  : t("Member")}
                &nbsp;
                {t("Selected")}
              </h3>
              <h6>{t("Select which Channels to add them to.")}</h6>
            </div>
            <div className={Styles.content}>{content}</div>
          </div>
        </div>
        <div className={Styles.btn_wrapper}>
          <Button
            title={props.closeText || t("Cancel")}
            onClickFunc={props.onBack}
            buttonType="secondary-outline"
          />
          <Button
            title={props.doneText || t("Add To Selected")}
            buttonType="primary"
            onClickFunc={handleSubmit}
          />
        </div>
      </div>
    </ModalLayoutWithTitle>
  );
}
