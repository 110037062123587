import { ActionType, action } from "typesafe-actions";
import {
  GeneralSettings,
  DetailsSettings,
  InitialProgramAttributes,
} from "../../interfaces/ProgramTypes";

export const setPage = (page: number) => action("@program.setPage", page);
export const setGeneralSettings = (generalSettings: GeneralSettings) =>
  action("@program.setGeneralSettings", generalSettings);
export const setDetailsSettings = (detailsSettigs: DetailsSettings) =>
  action("@program.setDetailsSettings", detailsSettigs);
export const resetProgramSettings = () =>
  action("@program.resetProgramSettings");
export const startBuild = (startBuildProp: InitialProgramAttributes) =>
  action("@program.startBuild", startBuildProp);
export const setId = (id: number) => action("@program.setId", id);
export const setDefaultImages = (defaultimages: string[]) => action("@program.setDefaultImages", defaultimages);

export const setCloneData = (cloneProps) =>
  action("@program.setCloneData", cloneProps);

const thisActions = {
  setPage,
  setGeneralSettings,
  setDetailsSettings,
  resetProgramSettings,
  startBuild,
  setCloneData,
  setId,
  setDefaultImages,
};

export type ProgramAction = ActionType<typeof thisActions>;
