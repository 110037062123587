import AudioPlayer from "app/components/AudioPlayer";
import Datafile from "app/components/Datafile";
import VideoPlayer from "app/components/VideoPlayer";
import { cls } from "app/utils";
import React from "react";
import styles from "./styles.module.scss";

interface Props {
  mediaData: any;
}

const Media = ({ mediaData }: Props) => {
  if ((mediaData && mediaData.type === "image") || mediaData.type === "video") {
    return (
      <div
        className={cls(styles.preview_container, {
          [styles.video_content_width]: mediaData?.type === "video",
        })}
      >
        {mediaData.type === "image" && (
          <a
            href={mediaData.originalUrl ? mediaData.originalUrl : ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={mediaData.originalUrl} alt="media" />
          </a>
        )}
        {mediaData.type === "video" && <VideoPlayer src={mediaData} />}
      </div>
    );
  } else if (mediaData.type === "datafile") {
    return (
      <a
        className={cls(styles.datafile_container)}
        href={mediaData.url ? mediaData.url : ""}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Datafile title={mediaData.filename} type={mediaData.extension} />
      </a>
    );
  } else if (mediaData.type === "audio") {
    return <AudioPlayer data={mediaData} />;
  } else {
    return null;
  }
};

export default Media;
