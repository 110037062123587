import React from "react";
import styles from "./styles.module.scss";
import UserAvatar from "app/components/UserAvatar";
import DropdownButton from "app/components/DropdownButton";
import MemberRoleBadge from "app/components/MemberRoleBadge";
import SpaceMemberModel from "app/models/SpaceMemberModel";
import {
  useMemberPermissions,
  usePermissions,
} from "../../routes/Spaces/spacePermissions";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useTranslation } from "react-i18next";

interface Props {
  member: SpaceMemberModel;
  hasMenu?: boolean;
  onMakeAthlete?: (memberId: string, member: SpaceMemberModel) => void;
  onRemoveUser?: (memberId: string) => void;
  onEdit?: (memberId: string) => void;
  onResend?: (member: SpaceMemberModel) => void;
  onCopyLink?: (member: SpaceMemberModel) => void;
}

const SpaceMemberCard: React.FC<Props> = ({
  member,
  hasMenu = true,
  onMakeAthlete,
  onRemoveUser,
  onEdit,
  onResend,
  onCopyLink,
}: Props) => {
  const { t } = useTranslation();
  const { self } = useSelector((state: RootState) => state.user, shallowEqual);
  const { canBecomeAthlete, canBeDeleted, canBeManaged } = useMemberPermissions(
    member.permissions,
  );
  const { canEdit, canDelete, canResend } = usePermissions(member.permissions);

  const canCopy = () => member && member.shortInvitationUrl;

  const menuList = [
    {
      label: t("Edit"),
      onClick: () => onEdit(member?.id),
      hasPermission: canEdit(),
    },
    {
      label: t("Re-send"),
      onClick: () => onResend(member),
      hasPermission: member && member.email && canResend(),
    },
    {
      label: t("Delete"),
      onClick: () => onRemoveUser(member?.id),
      hasPermission: canDelete(),
    },
    {
      label: t("Copy Link"),
      onClick: () => onCopyLink(member),
      hasPermission: canCopy(),
    },
    {
      label: t("Delete"),
      onClick: () => onRemoveUser(member?.id),
      hasPermission: member?.status === "declined",
    },
    {
      label: t("Make Athlete"),
      onClick: () => onMakeAthlete(member?.id, member),
      hasPermission: canBecomeAthlete(),
    },
    {
      label: self && self.id === member?.id ? t("Leave") : t("Remove"),
      onClick: () => onRemoveUser(member?.id),
      hasPermission: canBeDeleted(),
    },
  ];

  const renderMenu = () => {
    return (
      <ul className={styles.menu}>
        {menuList.map(
          (item) =>
            item.hasPermission && (
              <li
                key={item.label}
                className={styles.menu_item}
                onClick={item.onClick}
              >
                {item.label}
              </li>
            ),
        )}
      </ul>
    );
  };

  const userName = member.displayName || member.name || member.nameShort;

  return (
    <div className={styles.container}>
      <div className={styles.left_content}>
        <UserAvatar
          altName={"user"}
          sizeHeight={56}
          sizeWidth={56}
          srcName={member.avatar}
          userName={userName}
          className={styles.avatar}
        />
        <div className={styles.user_info_container}>
          <div className={styles.username_wrapper}>
            {userName && <h3 className={styles.username}>{userName}</h3>}
            <MemberRoleBadge member={member} />
          </div>
          <p className={styles.email}>
            {member.email
              ? member.email
              : member.phoneNormalized
                ? member.phoneNormalized
                : null}
          </p>
        </div>
      </div>
      {hasMenu &&
        (canBeManaged() || canDelete() || canResend() || canEdit()) && (
          <DropdownButton
            btnClassName={styles.dropdown_btn}
            dropdownWrapperClass={styles.dropdown_wrapper}
            btnContent={<i className="bullets" />}
            closeOnClick
          >
            {renderMenu()}
          </DropdownButton>
        )}
    </div>
  );
};

export default SpaceMemberCard;
