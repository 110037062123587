import stylesOBG from "../styles.module.scss";
import React from "react";
import RoundButton from "../RoundButton";
import ToolTip from "app/routes/Scheduling/ToolTip";

const ButtonsContainer = ({
  items,
  openCalendarSelected,
  setOpenCalendarSelected,
  customStyle = "",
  containerStyle = "",
}) => {
  return (
    <div className={containerStyle || stylesOBG.option_button_group}>
      {items.map((item) => (
        <RoundButton
          key={item.value}
          onClick={() => setOpenCalendarSelected(item)}
          customStyle={`${
            openCalendarSelected?.value === item.value
              ? stylesOBG.selected_btn
              : stylesOBG.default_btn
          } ${customStyle}`}
        >
          {item.label}
          {item?.tooltip && (
            <ToolTip
              text={item.tooltip}
              selected={true}
              iconColor={
                openCalendarSelected?.value === item.value ? "white" : null
              }
            />
          )}
        </RoundButton>
      ))}
    </div>
  );
};

export default ButtonsContainer;
