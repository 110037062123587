/**
 * @module Utils
 *
 */
import { Promise, resolve } from "rsvp";
import { Observable, Subscriber, Subscription } from "rxjs";
import { S3, AWSError } from "aws-sdk";
import { isNone } from "app/utils";
import { fetchPost } from "app/utils/request";

interface FileData {
  [key: string]: any;
  url?: string;
}

interface ProgressData extends S3.ManagedUpload.Progress {
  data?: FileData;
}

export interface SubscriberPromise {
  isAborted: boolean;
  abort: () => void;
  subscribe: (
    progress: (d: ProgressData) => void,
    error: (e: AWSError) => void,
    complete: () => void,
  ) => Subscription;
  promise: Promise<any>;
  then: (value: (v: FileData) => FileData) => Promise<FileData>;
  catch: (
    value: (v: AWSError) => Promise<AWSError>,
  ) => Promise<FileData | AWSError>;
  finally: (value: () => void) => void;
}

export interface UploaderObject {
  s3Uploader: S3.ManagedUpload;
  config: FileData;
}

/**
 *
 * @method upload
 * @param blob {Blod|File}
 * @param name {string}
 * @param type {string}
 * @param resourceType {string}
 * @return {Observer}
 */
export default function upload(
  blob: File | Blob,
  name: string,
  type: string,
  resourceType: string,
): SubscriberPromise {
  // create response with temp abort
  const response: SubscriberPromise = {
    isAborted: false,
    abort() {
      this.isAborted = true;
    },
    subscribe: null,
    promise: resolve(),
    then: (fn: (v: FileData) => FileData) => resolve().then(fn),
    catch: (fn: (v: AWSError) => Promise<AWSError>) =>
      resolve().catch((error) => {
        // Check if the error is a request abortion error
        console.log("error", error);
        if (error.code === "RequestAbortedError") {
          // Ignore the abort error, no need to log or handle it
          return resolve();
        }
        // Otherwise, call the user-defined error handler
        return fn(error);
      }),
    finally: (fn: () => void) => resolve().finally(fn),
  };

  let promiseObject: Promise<FileData> = resolve();
  const subscriber = new Observable((scribe: Subscriber<ProgressData>) => {
    promiseObject = new Promise<FileData>((resolve, reject) => {
      createS3Upload(blob, name, type, resourceType).then((result) => {
        const s3Uploader = result.s3Uploader;
        const config = result.config;

        if (!response.isAborted) {
          // setup on progress listeners
          onProgress(scribe, s3Uploader);
          response.abort = onAbort(scribe, s3Uploader);

          const errorFunc = (err: AWSError) => {
            scribe.error(err);
            reject(err);
          };

          const successFunc = (data: S3.ManagedUpload.SendData) => {
            const dout: FileData = Object.assign({}, config, {
              url: data.Location,
            });
            scribe.next({
              total: (s3Uploader as any).totalBytes,
              loaded: (s3Uploader as any).totalBytes,
              data: dout,
            });
            scribe.complete();
            resolve(dout);
          };

          s3Uploader.send(
            (error: AWSError, data: S3.ManagedUpload.SendData) => {
              if (!isNone(error)) {
                errorFunc(error);
              } else {
                successFunc(data);
              }
            },
          );
        } else {
          resolve(null);
        }
      });
    });
  });

  response.subscribe = (
    progress: (d: ProgressData) => void,
    error: (e: AWSError) => void,
    complete: () => void,
  ): Subscription => subscriber.subscribe(progress, error, complete);

  subscriber.subscribe();

  response.promise = promiseObject;

  response.then = (d) => {
    return promiseObject.then(d);
  };

  response.catch = (d) => {
    return promiseObject.catch(d);
  };

  response.finally = (d) => {
    promiseObject.finally(d);
  };

  return response;
}

function onProgress(
  subscriber: Subscriber<ProgressData>,
  s3Uploader: S3.ManagedUpload,
): void {
  subscriber.next({ total: (s3Uploader as any).totalBytes, loaded: 0 });

  s3Uploader.on("httpUploadProgress", (e: S3.ManagedUpload.Progress) => {
    subscriber.next({ total: e.total, loaded: e.loaded });
  });
}

function onAbort(
  subscriber: Subscriber<ProgressData>,
  s3Uploader: S3.ManagedUpload,
): () => void {
  return function abort(): void {
    if (s3Uploader && s3Uploader.abort) {
      s3Uploader.abort();
    }
    subscriber.unsubscribe();
  };
}

/**
 * get S3 config from server
 *
 * @method getConfig
 * @param files {object[]}
 * @return {Promise}
 */
function getConfig(files: FileData[]): Promise<FileData> {
  return fetchPost("uploads", { files }, { version: 1 });
}

/**
 * Create an instance of the s3 client to send files to
 *
 * @method createS3Upload
 * @param blob {Blob|File}
 * @param name {string}
 * @param type {string}
 * @param resourceType {string}
 */
function createS3Upload(
  blob: File | Blob,
  name: string,
  type: string,
  resourceType: string = "resource",
): Promise<UploaderObject> {
  const configParams = {
    filename: name,
    type: resourceType,
  };

  return getConfig([configParams]).then((conf) => {
    const fileConf = conf.files[0];

    // create s3 client instance
    const client = new S3({
      region: conf.region,
      useAccelerateEndpoint: true,
      credentials: {
        accessKeyId: conf.accessKey,
        secretAccessKey: conf.secretKey,
        sessionToken: conf.sessionToken,
      },
    });

    // create upload request
    const request = client.upload({
      Bucket: fileConf.bucket,
      Key: fileConf.key,
      Body: blob,
      ACL: "public-read",
      ContentType: type,
    });

    return { s3Uploader: request, config: fileConf };
  });
}
