import React from "react";
import styles from "./styles.module.scss";
import ButtonViewProgram from "../ButtonViewProgram";
import { useTranslation } from "react-i18next";

const CoachViewButtons = ({ noRegisteredParticipants, onMessageClick }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.buttonsContainer}>
      {noRegisteredParticipants > 0 && (
        <ButtonViewProgram
          handleClick={onMessageClick}
          colorStyle={styles.save_btn}
          text={t("Send Message")}
        />
      )}
    </div>
  );
};
export default CoachViewButtons;
