/**
 * @module Actions.Event
 *
 */
import { Dispatch } from "react";
import { ActionType, action } from "typesafe-actions";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import {
  CoachProfileModel,
  CoachProfileResponse,
} from "app/models/scheduling/CoachProfileModel";

const fetchPending = () => action("@coachProfile.fetch.pending");

const fetchError = (err: string) => action("@coachProfile.fetch.error", err);

const clearCoachProfile = () => action("@coachProfile.clear");
const fetchSuccess = (coachProfile: CoachProfileModel) =>
  action("@coachProfile.fetch.success", coachProfile);

const thisActions = {
  fetchPending,
  fetchError,
  fetchSuccess,
  clearCoachProfile,
};

export type CoachProfileAction = ActionType<typeof thisActions>;

export function fetchCoachProfile(coachProfileId: string) {
  return (dispatch: Dispatch<CoachProfileAction>) => {
    dispatch(fetchPending());
    return schedulingFetchGet<CoachProfileResponse>(
      `coach_profiles/${coachProfileId}`
    )
      .then((coachProfile: CoachProfileResponse) => {
        const model = new CoachProfileModel(coachProfile);
        dispatch(fetchSuccess(model));
      })
      .catch((err) => {
        console.log("fetchErr", err);
        dispatch(fetchError(err));
      });
  };
}
