import { useEffect, useState } from "react";
import { isNil } from "lodash";
import {
  CoachProfileModel,
  CoachProfileResponse,
} from "app/models/scheduling/CoachProfileModel";
import {
  FacilityModel,
  FacilityResponse,
} from "app/models/scheduling/FacilityModel";
import {
  LessonTypeModel,
  LessonTypeResponse,
} from "app/models/scheduling/LessonTypeModel";
import { LessonRateModel } from "app/models/scheduling/LessonRateModel";

interface IndexedCoaches {
  [id: string]: CoachProfileModel[];
}

interface IndexedPricingValues {
  [id: string]: LessonRateModel[];
}

interface IndexedRates {
  [id: string]: LessonRateModel[];
}

interface AggregatedRates {
  [id: string]: {
    [id: number]: {
      coachIds: string[];
      value: string;
      label: string;
      duration: string;
      durationInMinutes: number;
      rate: number;
    };
  };
}

const generateDefaultDurations = (coachId: string): LessonRateModel[] => {
  return [
    // @ts-ignore:next-line
    new LessonRateModel({
      coachProfileId: coachId,
      duration: "30 mins",
      durationInMinutes: 30,
      rate: 0,
      defaultRate: false,
    }),
    // @ts-ignore:next-line
    new LessonRateModel({
      coachProfileId: coachId,
      duration: "60 mins",
      durationInMinutes: 60,
      rate: 0,
      defaultRate: true,
    }),
  ];
};

const parseFacilities = (facilities: FacilityResponse[]) => {
  if (isNil(facilities)) {
    return [];
  }

  return facilities.map((facility) => new FacilityModel(facility));
};

const parseCoaches = (coaches: CoachProfileResponse[]): IndexedCoaches => {
  if (isNil(coaches)) {
    return {};
  }
  const indexedCoaches: IndexedCoaches = {};
  coaches
    .sort((a, b) => a.orderNumber - b.orderNumber)
    .forEach((coach) => {
      if (!indexedCoaches[coach.facilityId]) {
        indexedCoaches[coach.facilityId] = [];
      }
      indexedCoaches[coach.facilityId].push(new CoachProfileModel(coach));
    });

  return indexedCoaches;
};

const parseLessonTypes = (
  lessonTypes: LessonTypeResponse[]
): LessonTypeModel[] => {
  if (isNil(lessonTypes)) {
    return [];
  }

  return lessonTypes.map((lessonType) => new LessonTypeModel(lessonType));
};

const parsePricingValues = (
  coaches: CoachProfileModel[]
): IndexedPricingValues => {
  if (isNil(coaches)) {
    return {};
  }

  const pricingValues: IndexedPricingValues = {};

  coaches.forEach((coach) => {
    pricingValues[coach.id] = coach.lessonRates.map(
      (lr) => new LessonRateModel(lr)
    );
  });

  return pricingValues;
};

const parseRates = (
  coaches: CoachProfileModel[]
): [IndexedRates, AggregatedRates] => {
  if (isNil(coaches)) {
    return [{}, {}];
  }

  const rates: IndexedRates = {};
  const aggregatedRates: AggregatedRates = {};

  coaches.forEach((coach) => {
    if (coach.lessonRates.length === 0) {
      rates[coach.id] = generateDefaultDurations(coach.id);
    } else {
      rates[coach.id] = coach.lessonRates;
    }

    if (!aggregatedRates[coach.facilityId]) {
      aggregatedRates[coach.facilityId] = {};
    }

    rates[coach.id].forEach((rate) => {
      if (!aggregatedRates[coach.facilityId][rate.durationInMinutes]) {
        aggregatedRates[coach.facilityId][rate.durationInMinutes] = {
          coachIds: [],
          value: `default-${rate.durationInMinutes}`,
          label: rate.duration,
          duration: rate.duration,
          durationInMinutes: rate.durationInMinutes,
          rate: 0,
        };
      }

      aggregatedRates[coach.facilityId][rate.durationInMinutes].coachIds.push(
        rate.coachProfileId
      );
    });
  });

  return [rates, aggregatedRates];
};

export default function useBookSessionData(fetcher, isPublicPage = false) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [bookLessonData, setBookLessonData] = useState({
    facilities: [],
    coaches: {},
    lessonTypes: [],
    rates: {},
    aggregatedRates: {},
    pricingValues: {},
    public: false,
  });

  useEffect(() => {
    setLoading(true);
    fetcher()
      .then(
        (
          data: [
            FacilityResponse[],
            CoachProfileResponse[],
            LessonTypeResponse[]
          ]
        ) => {
          if (!data) {
            return;
          }
          const [facilities, coaches, lessonTypes] = data;

          const parsedFacilities = parseFacilities(facilities);

          const parsedCoaches = parseCoaches(coaches);
          // @ts-ignore:next-line
          const coachModels = Object.values(parsedCoaches).flat();

          const parsedLessonTypes = parseLessonTypes(lessonTypes);
          const parsedPricingValues = parsePricingValues(coachModels);
          const [parsedRates, aggregatedRates] = parseRates(coachModels);

          setBookLessonData({
            facilities: parsedFacilities,
            coaches: parsedCoaches,
            lessonTypes: parsedLessonTypes,
            pricingValues: parsedPricingValues,
            rates: parsedRates,
            public: isPublicPage,
            aggregatedRates,
          });

          setLoading(false);
        }
      )
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [fetcher, isPublicPage]);

  return [loading, bookLessonData, error];
}
