import React from "react";
import styles from "./styles.module.scss";
import { cls } from "app/utils";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  labelClassName?: string;
  icon?: React.ReactNode;
  isActive?: boolean;
  onClick?: () => void;
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
  badge?: React.ReactNode;
}

const TabButton = ({
  label,
  labelClassName,
  icon,
  className,
  isActive,
  onClick,
  ref,
  badge,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      ref={ref}
      className={cls(styles.tab_button, className, {
        [styles.active]: isActive,
      })}
      onClick={onClick}
    >
      {icon && icon}
      <p className={labelClassName}>{t(label)}</p>
      {badge && badge}
    </div>
  );
};

export default TabButton;
