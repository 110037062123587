import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import Button from "app/components/Button/ButtonVariant";
import ModalLayout from "app/components/Layouts/ModalLayout/ModalLayout";
import closeIcon from "../../../../../public/images/close-icon.svg";

interface Props {
  isModalOpen: boolean;
  modalHandler: () => void;
  reportHandler: (message: string) => void;
}

const PostReportModal: React.FC<Props> = ({
  isModalOpen,
  modalHandler,
  reportHandler,
}: Props) => {
  const { t } = useTranslation();
  const [reportMessage, setReportMessage] = useState<string>("");

  const reportMessageHandler = (e?: string) => {
    setReportMessage(e);
  };

  const postReportClick = () => {
    reportHandler(reportMessage);
    modalHandler();
    // todo: add alert
    // dispatch(
    //   addAlert("This post has been reported and removed from your feed.")
    // );
  };

  return (
    <ModalLayout isOpen={isModalOpen} onClose={modalHandler}>
      <div className={styles.content_wrapper}>
        <div className={styles.modal_header}>
          <h1>{t("Report Post")}</h1>
          <img
            src={closeIcon}
            alt="close"
            className={styles.close_icon}
            onClick={modalHandler}
          />
        </div>
        <div className={styles.content_information}>
          {t(
            "Explain why this post is inappropriate or offensive, and should be removed.",
          )}
        </div>
        <textarea
          className={styles.text_input}
          placeholder={t("Enter Message")}
          rows={10}
          onChange={(e) => reportMessageHandler(e.target.value)}
        />

        <div className={styles.button_wrapper}>
          <Button
            buttonType="secondary-outline"
            title={t("Cancel")}
            onClickFunc={modalHandler}
          />
          <Button
            buttonType="secondary-outline-danger"
            title={t("Report")}
            onClickFunc={postReportClick}
          />
        </div>
      </div>
    </ModalLayout>
  );
};

export default memo(PostReportModal);
