import { clearPost, deletePost, editPost } from "app/actions/postingForm";
import { fetchPosts, reportPost } from "app/actions/posts";
import { fetchScheduledPosts } from "app/actions/schedule";
import { FilterModel } from "app/models/FilterModel";
import PostModel from "app/models/PostModel";
import { useActions } from "app/utils/hooks";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "typedefs";

interface QueryParams {
  page?: number;
  team_group_id?: string;
  per_page?: number;
  reply_limit?: number;
}

export const TeamsTimelineModel = () => {
  const postsActions = useActions({
    fetchPosts,
    fetchScheduledPosts,
    editPost,
    clearPost,
    reportPost,
    deletePost,
  });
  const { selectedTeam } = useSelector(
    (state: RootState) => state.teamGroup,
    shallowEqual,
  );

  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );

  const { self } = useSelector((state: RootState) => state.user, shallowEqual);

  const { modalInviteIsOpen } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual,
  );

  const { pending, hasMore } = useSelector(
    (state: RootState) => state.posts,
    shallowEqual,
  );

  const { scheduledPostsSuccess: scheduledPostsData } = useSelector(
    (state: RootState) => state.schedulePost,
    shallowEqual,
  );

  const [reportModal, setReportModal] = useState({ isOpen: false, post: null });
  const [data, setData] = useState<PostModel[]>([]);
  const urlParams = useParams<{ teamId: string }>();

  const onReport = (post: PostModel, message?: string) => {
    postsActions
      .reportPost(post.id, message)
      .then(() => {
        setData(
          data.filter((filteredPost: PostModel) => filteredPost.id !== post.id),
        );
      })
      .catch((error) => {
        console.error("Error deleting post:", error);
      });
  };

  const onPostReportClick = (post: PostModel) => {
    setReportModal({ isOpen: true, post });
  };

  const reportModalClose = () => {
    setReportModal({ isOpen: false, post: null });
  };

  const onReportHandler = (reason: string) => {
    onReport(reportModal?.post, reason);
    reportModalClose();
  };

  const postsParams = {
    team_group_id: urlParams?.teamId,
    "options[include]": ["replies"],
    page: 1,
    perPage: 20,
    replyLimit: 3,
  };

  const [scheduledPostsParams] = useState({
    team_group_id: urlParams?.teamId,
    "options[include]": ["posts"],
    orderBy: "scheduled_at",
    order: "asc",
    timezoneOffset: moment().utcOffset().toString(),
  });

  const [query, setQuery] = useState<QueryParams>(postsParams);
  const abortControllerRef = useRef<AbortController | null>(null);

  const fetchPage = async (queryVal: QueryParams) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    try {
      const res = await postsActions.fetchPosts(
        queryVal,
        abortControllerRef.current.signal,
      );
      if (!abortControllerRef.current.signal.aborted) {
        if (res) {
          if (Array.isArray(res)) {
            setData((prevData: any) => [...prevData, ...res]);
          } else {
            setData(res);
          }
          setQuery(queryVal);
        } else {
          console.error("API response is null or undefined");
        }
      }
    } catch (error) {
      if (!abortControllerRef.current.signal.aborted) {
        console.error("Error fetching posts:", error);
      }
    }
  };

  const initialLoadPosts = (queryVal: QueryParams) => {
    setData([]);
    fetchPage(queryVal);
  };

  const loadMore = () => {
    if (!pending && hasMore) {
      const newQuery = { ...query, page: (query.page || 1) + 1 };
      fetchPage(newQuery);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: pending,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    postsActions.fetchScheduledPosts(scheduledPostsParams);
    initialLoadPosts(postsParams);
    return () => {
      // dispatch(scheduledPosts({ posts: null, error: null, pending: false }));
      postsActions.clearPost();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      setData([]);
      setQuery(postsParams);
    };
  }, [postsActions, urlParams]);

  const onPostDelete = (post: PostModel) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this post?",
    );
    if (confirmation) {
      postsActions
        .deletePost(post.id)
        .then(() => {
          setData(
            data.filter(
              (filteredPost: PostModel) => filteredPost.id !== post.id,
            ),
          );
        })
        .catch((error) => {
          console.error("Error deleting post:", error);
        });
    }
  };

  const onPostUpdate = (post: PostModel) => {
    if (post) {
      postsActions.editPost(post);
    }
  };

  const onPostPin = () => {
    setQuery(postsParams);
    initialLoadPosts(postsParams);
  };

  function filterHandler(filters: FilterModel) {
    const filteredQuery = { ...postsParams, ...filters };
    postsActions.clearPost();
    initialLoadPosts(filteredQuery);
  }

  function onClearFilters() {
    postsActions.clearPost();
    initialLoadPosts(postsParams);
  }

  const onPost = async (post: PostModel) => {
    if (!post) {
      return;
    }

    if (!post?.scheduledAt) {
      if (!data?.[0]?.pinnedByName) {
        setData([{ ...post, userAvatar: self.avatar }, ...data]);
      } else if (data?.[0]?.pinnedByName) {
        // if the first post is pinned, insert the new post at the second index
        const newData = data?.length > 0 ? [...data] : [];
        newData?.splice(1, 0, {
          ...post,
          userAvatar: self.avatar,
        });
        setData(newData);
      }
    } else {
      postsActions.fetchScheduledPosts(scheduledPostsParams);
    }
  };

  const canSeePostviews =
    (self?.id === selectedGroup?.ownerId ||
      selectedGroup?.membersCanSeePostData) ??
    false;

  const canSeeWhoLiked =
    (self?.id === selectedGroup?.ownerId ||
      selectedGroup?.membersCanSeePostData) ??
    false;

  const canPost =
    (self?.id === selectedGroup?.ownerId || selectedGroup?.membersCanPost) ??
    false;

  return {
    selectedTeam,
    data,
    scheduledPostsData,
    onPost,
    pending,
    reportModal,
    reportModalClose,
    onReportHandler,
    onPostReportClick,
    onPostDelete,
    onPostUpdate,
    onPostPin,
    sentryRef,
    filterHandler,
    onClearFilters,
    modalInviteIsOpen,
    canSeePostviews,
    canSeeWhoLiked,
    canPost,
  };
};
