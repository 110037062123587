import { fetchTeamGroups } from "app/actions/team-group";
import {
  fetchJoinableTeam,
  getUploadInvitationFile,
  membersToInvite,
  rearangedInviteData as setRearangedInviteData,
  uploadInvitationFile,
} from "app/actions/team-group-member-import";
import { useActions } from "app/utils/hooks";
import upload from "app/utils/uploader";
import { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { RootState } from "typedefs";
import { useCsvUploadParser } from "app/utils/hooks/useCsvUploadParser"; // Import the CSV parser hook

export const useInviteWithEmailModel = () => {
  const dispatch = useDispatch();

  const importActions = useActions({
    fetchJoinableTeam,
    fetchTeamGroups,
    upload,
    uploadInvitationFile,
    getUploadInvitationFile,
  });

  const {
    membersListToInvite,
    rearangedInviteData,
    selectedTeams,
    selectedMembersRole,
  } = useSelector(
    (state: RootState) => state.teamGroupMemberImport,
    shallowEqual,
  );

  const { selectedGroup } = useSelector(
    (state: RootState) => state.groups,
    shallowEqual,
  );
  const [importPending, setImportPending] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const [csvModalOpen, setCsvModalOpen] = useState(true);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [selectRoleModalOpen, setSelectRoleModalOpen] = useState(false);
  const [importedUsersModalOpen, setImportedUsersModalOpen] = useState(false);
  const [confirmImportsModalOpen, setConfirmImportsModalOpen] = useState(false);
  const [addMembersToChannelIsOpen, setAddMembersToChannelIsOpen] =
    useState(false);
  const [columnIndexes, setColumnIndexes] = useState({
    email: -1,
    name: -1,
    position: -1,
    jersey: -1,
  });
  const [uploadResults, setUploadResults] = useState<any>(null);

  const { data, error } = useCsvUploadParser({ file: uploadedFile });

  useEffect(() => {
    if (data.length > 0) {
      dispatch(membersToInvite(data));
      dispatch(setRearangedInviteData(data as []));
      setCsvModalOpen(false);
      setSelectRoleModalOpen(true);
    }

    if (error) {
      console.error("Error while parsing the file: ", error);
    }
  }, [data, error, dispatch]);

  const handleGetFile = (file: File) => {
    setUploadedFile(file);
  };

  const onNextSelectRoleModal = () => {
    setSelectRoleModalOpen(false);
    setImportedUsersModalOpen(true);
  };

  const onNextImportedUsersModal = (columnIndexes: {
    email: number;
    name: number;
    position: number;
    jersey: number;
  }) => {
    setImportedUsersModalOpen(false);
    setAddMembersToChannelIsOpen(true);
    setColumnIndexes(columnIndexes);
  };

  const onBackAddMembersToChannelIsOpen = () => {
    setAddMembersToChannelIsOpen(false);
    setImportedUsersModalOpen(true);
  };

  const onNextAddMembersToChannelIsOpen = () => {
    setAddMembersToChannelIsOpen(false);
    setConfirmImportsModalOpen(true);
  };

  const onBackConfirmImportsModal = () => {
    setConfirmImportsModalOpen(false);
    setSelectRoleModalOpen(true);
  };

  const importMembersHandler = async () => {
    try {
      setImportPending(true);
      const abortableUpload = upload(
        uploadedFile,
        uploadedFile.name,
        undefined,
        "file",
      );
      const response = await abortableUpload.promise;
      const res = await importActions.uploadInvitationFile({
        teamId: selectedGroup.id,
        file: {
          meta: {
            columns: {
              email: columnIndexes.email ?? -1,
              name: columnIndexes.name ?? -1,
              position: columnIndexes.position ?? -1,
              jersey: columnIndexes.jersey ?? -1,
            },
            sheets: [0],
            type: selectedMembersRole,
          },
          url: response?.url,
        },
      });

      if (res?.status === "in_progress") {
        const pollRequest = async () => {
          const pollRes = await importActions.getUploadInvitationFile(
            selectedGroup.id,
            res.id,
          );

          if (pollRes.status === "success") {
            await Promise.all([
              importActions.fetchTeamGroups(selectedGroup?.id),
              importActions.fetchJoinableTeam(selectedGroup?.id),
            ]);
            setImportPending(false);
            setImportSuccess(true);
            setUploadResults(pollRes);
          } else {
            setTimeout(pollRequest, 4000);
          }
        };
        pollRequest();
      }
      setConfirmImportsModalOpen(false);
      setImportPending(true);
    } catch (error) {
      console.error("Error during file upload:", error);
      setImportPending(false);
    }
  };

  return {
    membersListToInvite,
    selectedTeams,
    importedUsersModalOpen,
    csvModalOpen,
    handleGetFile,
    selectRoleModalOpen,
    onNextSelectRoleModal,
    onNextImportedUsersModal,
    confirmImportsModalOpen,
    onBackConfirmImportsModal,
    onNextAddMembersToChannelIsOpen,
    onBackAddMembersToChannelIsOpen,
    addMembersToChannelIsOpen,
    importPending,
    rearangedInviteData,
    importSuccess,
    importMembersHandler,
    uploadResults,
  };
};
