import React from "react";
import styles from "./styles.module.scss";
import { useDispatch } from "react-redux";
import { deselectEvent } from "app/actions/events";


const ScheduleBlocksInfo = ({event, onEdit, onDelete}) => {

  const dispatch = useDispatch();
  return (
    <>
      <div className={styles.header}>
        <div className={styles.event_title}>
          <p>{event.title}</p>
          <div className={styles.repeat}>{event.repeatLabel}</div>
        </div>
        <div className={styles.header_actions}>
          {!event.externalCalendarId && (
            <a onClick={onEdit}>
              <i className="ico ico-pencil-o" />
            </a>
          )}
          {!event.externalCalendarId && (
            <a onClick={onDelete}>
              <i className="ico ico-trash" />
            </a>
          )}
          <a onClick={() => dispatch(deselectEvent())}>
            <i className="ico ico-x" />
          </a>
        </div>
      </div>
      <div className={styles.event_subtitle}>
        <i className="ico ico-calendar"></i>
        <span>{event.dateString}</span>
      </div>
      <div className={styles.event_subtitle}>
        <i className="ico ico-clock"></i>
        <span>{event.intervalTimeString}</span>
      </div>
      <div className={styles.event_notes}>{event.notes}</div>
    </>
  );
};

export default ScheduleBlocksInfo;
