import UserAvatar from "../UserAvatar";
import styles from "./styles.module.scss";
import React from "react";

const StudentItem = ({
  firstName,
  lastName,
  profile_url,
  height,
  width,
  email,
  showEmail,
}) => {
  const user_avatar = (
    <UserAvatar
      src={profile_url}
      height={height || 24}
      width={width || 24}
      first_name={firstName}
      last_name={lastName}
    />
  );

  return (
    <div className={styles.student_container}>
      {user_avatar}
      <div className={styles.text_container}>
        <div className={styles.student_name}>{`${firstName} ${lastName}`}</div>
        {showEmail && <div className={styles.email}>{email}</div>}
      </div>
    </div>
  );
};

export default StudentItem;
