import React from "react";
import ManageCoachPrograms from "./ManageCoachPrograms";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import { UserState } from "app/reducers/user";
import StudentPrograms from "./ManageStudentPrograms";

const ManagePrograms = () => {
  const userState = useSelector((state: RootState): UserState => state.user);

  return userState.data.kind === "athlete" ? (
    <StudentPrograms />
  ) : (
    <ManageCoachPrograms />
  );
};

export default ManagePrograms;
