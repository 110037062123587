import { NotificationsModel } from "app/models";
import { useHistory } from "react-router-dom";

export const useNotificationsListModel = () => {
  const history = useHistory();

  const route = (path: string) => {
    history.push(path);
  };

  const onNotificationClick = (notification: NotificationsModel) => {
    if (notification?.targetUrl) {
      route(notification.targetUrl);
    }
  };

  return {
    onNotificationClick,
  };
};
