import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import useDatafileIcons from "app/components/inputs/SelectBox/helpers/DatafileIcons";
import { MediaTypes } from "./index.types";
import playIcon from "app/images/play_btn.svg";
import duration from "app/components/inputs/SelectBox/helpers/DurationConverter";
import { cls } from "app/utils";
import checkmark from "../../../../public/images/checkmark.svg";
import { Spinner } from "app/components/Loading";
import { useTranslation } from "react-i18next";
import { useActions } from "app/utils/hooks";
import {
  fetchMediaById,
  // selectedMedia as setSelectMedia,
} from "app/actions/media-library-data";
import i18n from "app/i18n";
// import { useDispatch } from "react-redux";

interface Props {
  showTags?: boolean;
  showPlayIcon?: boolean;
  thumbnailClassname?: string;
  hasGradientOverlay?: boolean;
  data: MediaTypes;
  onSelectMedia?: (data: MediaTypes) => void;
}

const ResourceCard = ({
  data,
  showTags = true,
  showPlayIcon = true,
  thumbnailClassname,
  hasGradientOverlay = true,
  onSelectMedia,
}: Props) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const mediaActions = useActions({ fetchMediaById });
  const [selectedMedia, setSelectedMedia] = useState<MediaTypes>(null);

  const mediaSelectHandler = useCallback((data: MediaTypes) => {
    // if (data) {
    //   dispatch(setSelectMedia(data));
    // }
    if (data && onSelectMedia) {
      setSelectedMedia(data);
      onSelectMedia(data);
    }
  }, []);

  useEffect(() => {
    const fetchMedia = () => {
      if (selectedMedia?.id && !selectedMedia?.processed) {
        mediaActions.fetchMediaById(selectedMedia?.id).then((res) => {
          if (res?.processed) {
            setSelectedMedia(res);
          }
        });
      }
    };

    let pollingInterval: any;

    pollingInterval = setInterval(() => {
      fetchMedia();

      if (selectedMedia?.processed) {
        clearInterval(pollingInterval);
      }
    }, 5000);

    return () => {
      clearInterval(pollingInterval);
    };
  }, [selectedMedia]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };

    const formattedDate = date.toLocaleDateString(i18n.language, options);

    return formattedDate;
  };
  const { dataFileIconHandler } = useDatafileIcons();

  const displayThumbnail = useCallback((data: MediaTypes) => {
    if (!data || !data.object) {
      return null;
    }

    if (data.object === "video") {
      return data.processed ? (
        data.thumbnails && data.thumbnails.default && (
          <>
            {data && data.duration > 0 && showPlayIcon && (
              <div className={styles.play_wrapper}>
                <img src={playIcon} alt="play" />
                <span>{duration(data.duration)}</span>
              </div>
            )}
            <img src={data.thumbnails.default} alt="media thumbnail" />
          </>
        )
      ) : (
        <span className={styles.loading_container}>
          <Spinner />
        </span>
      );
    } else if (data.object === "image") {
      return data.mediumUrl ? (
        <img src={data.mediumUrl} alt="media thumbnail" />
      ) : (
        data.originalUrl && <img src={data.originalUrl} alt="media thumbnail" />
      );
    } else if (data.object === "datafile") {
      return <img src={dataFileIconHandler(data.extension)} alt="datafile" />;
    } else if (data.object === "audio") {
      return <img src={dataFileIconHandler(data.object)} alt="datafile" />;
    }

    return null;
  }, []);

  return (
    <div key={data.id} onClick={() => mediaSelectHandler(data)}>
      <div>
        <div
          className={cls(styles.thumbnail_wrapper, thumbnailClassname, {
            [styles.selectedMediaData]: selectedMedia?.id === data?.id,
          })}
        >
          {selectedMedia?.id === data?.id && (
            <span className={styles.checkmark_container}>
              <img
                src={checkmark}
                alt="checkmark"
                className={styles.checkmark}
              />
            </span>
          )}
          {displayThumbnail(data)}
          <div
            className={cls({ [styles.thumbnail_overlay]: hasGradientOverlay })}
          />
          {showTags && (
            <div className={styles.tags_overlay}>
              {data.tags && data.tags.length ? (
                <>
                  #
                  {data.tags.map((tag) => {
                    return (
                      <span key={tag} className={styles.tag}>
                        {tag}
                        {data.tags.length > 1 ? "," : ""}
                      </span>
                    );
                  })}
                </>
              ) : (
                t("No Tags")
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.media_info}>
        <p className={styles.post_count}>
          {t("count_post", {
            count: data.postsCount,
          })}
        </p>
        <p className={styles.posted_at}>{formatDate(data.createdAt)}</p>
      </div>
    </div>
  );
};

export default ResourceCard;
