import { t } from "app/i18n";
import moment from "moment";

export const postDate = (val: Date) => {
  if (val) {
    const now = moment();
    const postDate = moment(val);
    const diffMinutes = now.diff(postDate, "minutes");
    const diffHours = now.diff(postDate, "hours");
    const diffDays = now.diff(postDate, "days");
    const diffWeeks = now.diff(postDate, "weeks");
    const isDifferentYear = now.year() !== postDate.year();
    if (diffMinutes < 1) {
      return t("Just now");
    } else if (diffWeeks > 4) {
      return postDate.format(isDifferentYear ? "MMM DD YYYY" : "MMM DD");
    } else if (diffWeeks >= 1) {
      return `${diffWeeks} ${t("weeks")[0]}`;
    } else if (diffDays >= 1) {
      return `${diffDays} ${t("days")[0]}`;
    } else if (diffHours >= 1) {
      return `${diffHours} ${t("hours")[0]}`;
    } else {
      return `${diffMinutes} ${t("minutes")[0]}`;
    }
  } else {
    return null;
  }
};
