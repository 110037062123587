/**
 * @module Provider
 *
 */
import { PersistGate } from 'redux-persist/integration/react';
import React, { PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";
import { ErrorBoundary } from "app/library/bugsnag";
// @ts-ignore
import { store, persistor } from "app/Store";
import { translations } from "app/translations";

// setup locale and preloaded translations
const { locale, messages } = translations();

type PropsDef = PropsWithChildren<{}>;

/**
 * @class ProvideWrapper
 *
 */
const ProvideWrapper = (props: PropsDef) => {
  return (
    // @ts-ignore
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <IntlProvider locale={locale} messages={messages}>
            {props.children}
          </IntlProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

export default ProvideWrapper;
