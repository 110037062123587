import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { useActions, useSelector } from "app/utils/hooks";
import ContentCard from "../../../../components/ContentCard";
import { RootState } from "typedefs";
import SpaceModel from "app/models/SpaceModel";
import DropdownButton from "../../../../components/DropdownButton";
import { editSegment } from "app/actions/segments";
import { cls } from "app/utils";
import Button from "app/components/Button/ButtonVariant";
import { Link } from "react-router-dom";
import { shallowEqual } from "react-redux";
import { Segments, UpgradeToPro } from "app/components/paywalls";

const SpacesList = () => {
  const { t } = useTranslation();
  const segmentActions = useActions({ editSegment });
  const spaces = useSelector((state: RootState) => state.space, shallowEqual);
  const segment = useSelector(
    (state: RootState) => state.segments.segmentsData,
    shallowEqual,
  );

  const [spacesData, setSpacesData] = useState<SpaceModel[] | []>(null);

  const [showProPaywall, setShowProPaywall] = useState(false);
  const [showSegmentPaywall, setShowSegmentPaywall] = useState(false);

  const onProUpgrade = () => {
    setShowProPaywall(true);
  };

  const closeProPaywall = useCallback(() => {
    setShowProPaywall(false);
  }, []);

  const onSegmentUpgrade = () => {
    setShowSegmentPaywall(true);
  };

  const closeSegmentUpgrade = useCallback(() => {
    setShowSegmentPaywall(false);
  }, []);

  const canManageSpaces = useMemo(() => {
    if (segment) {
      return segment.planPermissions.includes("manage_spaces");
    }
  }, [segment]);

  useEffect(() => {
    if (spaces && spaces.data) {
      setSpacesData(spaces.data);
    }
  }, [spaces]);

  const onRemoveSpace = (id: string) => {
    if (canManageSpaces) {
      const confirmed = window.confirm(
        t("Are you sure you want to remove this space from the list?"),
      );

      if (confirmed) {
        const filteredSpacesData = spacesData.filter(
          (space: { id: string }) => space.id !== id,
        );
        setSpacesData(filteredSpacesData);

        const spaceIds = filteredSpacesData.map((space) => space.id);

        segmentActions
          .editSegment(segment.id, {
            ...segment,
            spaceIds: spaceIds.length > 0 ? spaceIds : [],
          })
          .catch((error) => {
            console.error("Failed to update segment:", error);
          });
      }
    } else {
      onProUpgrade();
    }
  };

  if (spaces && spaces.pending) {
    return <h3>{t("Loading...")}</h3>;
  }

  return (
    <>
      <div className={styles.container}>
        {spacesData &&
          spacesData.map((item: SpaceModel) => {
            return (
              <div key={item.id} className={styles.list_item_wrapper}>
                <Link to={`/spaces/${item.id}`}>
                  <ContentCard
                    img={item.athleteAvatar}
                    title={item.athleteName}
                    subtitle={item.sport}
                  />
                </Link>
                <DropdownButton
                  btnContent={<i className="ico ico-options" />}
                  dropdownClassname={styles.menu_container}
                  dropdownWrapperClass={styles.menu_btn}
                  closeOnClick
                >
                  <button
                    className={cls(styles.remove_btn, "btn")}
                    onClick={() => onRemoveSpace(item.id)}
                  >
                    {t("Remove")}
                  </button>
                </DropdownButton>
              </div>
            );
          })}
      </div>
      {((!spaces.pending && !spacesData) ||
        (spacesData && !spacesData.length && spaces)) &&
        segment && (
          <div className={styles.no_space}>
            <h3>{t("There are no Spaces in this List")}</h3>
            {canManageSpaces ? (
              <Link to={`/lists/${segment.id}/spaces/edit`}>
                <Button
                  buttonType="primary"
                  icon={<i className="icon-plus icon-white" />}
                  title={t("Add Spaces to this List")}
                  buttonStyles={styles.add_space_btn}
                />
              </Link>
            ) : (
              <Button
                buttonType="upgrade"
                icon={<i className="icon-plus icon-white" />}
                title={t("Add Spaces to this List")}
                buttonStyles={styles.add_space_btn}
                onClickFunc={() => onSegmentUpgrade()}
              />
            )}
          </div>
        )}
      <UpgradeToPro show={showProPaywall} onClose={closeProPaywall} />
      <Segments show={showSegmentPaywall} onClose={closeSegmentUpgrade} />
    </>
  );
};

export default SpacesList;
